import React, {useEffect} from 'react';

import {Box} from '@mui/material';

import {fabric} from 'fabric';
import {useTimer,useTranslation} from 'hooks';
import {useBannersStore} from 'module/banner';
import {PlayerDialog, usePlayerStore} from 'module/player';
import {useSheetStore} from 'module/sheet/zustand';
import {TextDialog, useTextStore} from 'module/text';
import {usePrefsStore, useSnackbarStore} from 'store';

import {clearEvents, clearObjectEvents, registerGlobalEvents, registerObjectEvents} from './canvas-handlers';
import {
    canvasToJson,
    DEFAULT_COLOR, drawBorder, DRAWING_WIDTH,
    isCustom, limitReached, SELECTION_MODE,
    setLastObjectActive,
    setMode, useCalculateDimensions,
} from './canvas-helper';
import {setScaling} from './object-scale';
import useCustomControls, {customControlVisibility, setGroupControlVisibility} from './useCustomControls';
import useFabric from './useFabric';
import usePrefs from './usePrefs';
import {useCanvasStore} from './zustand';

const styles = {
    canvas: {
        width: '100%'
    }
};

const Canvas = ({isMobile}) => {
    const translate = useTranslation();

    const showInfo = useSnackbarStore(state => state.show);

    const setPlayer = usePlayerStore(state => state.set);
    const setText = useTextStore(state => state.set);

    const canvas = useCanvasStore(state => state.canvas);
    const setCanvas = useCanvasStore(state => state.set);
    const setDirty = useCanvasStore(state => state.setDirty);
    const pushCanvas = useCanvasStore(state => state.pushCanvas);
    const getCanvasStates = useCanvasStore(state => state.getCanvasStates);

    const editable = useSheetStore(state => state.frameIndex) === 0;

    const setTool = usePrefsStore(state => state.setTool);

    const bannersShow = useBannersStore(store => store.show);

    const {mode, tool, background} = usePrefs();

    // custom controls
    const {updateControls} = useCustomControls(canvas);

    if (canvas) {
        //console.log('canvas w/h', canvas.width, canvas.height);
    }

    const dimensions = useCalculateDimensions(canvas, background);

    const fabricRef = useFabric(fabricCanvas => {
        fabricCanvas.freeDrawingBrush = new fabric['PencilBrush'](fabricCanvas);
        fabricCanvas.freeDrawingBrush.color = DEFAULT_COLOR;
        fabricCanvas.freeDrawingBrush.width = DRAWING_WIDTH;
        setCanvas(fabricCanvas);
    }, {
        // isDrawingMode: mode === 'draw',
        isDrawingMode: true,
        width: dimensions.width,
        height: dimensions.height
    });

    const [resetBannerTimer] = useTimer(() => {
        !isMobile && bannersShow(); // tmp disable banner timeout for demo
    }, 600 * 1000); // 600 seconds

    const [resetControlHideTimer] = useTimer(() => {
        // remove selection
        canvas.discardActiveObject();
        canvas.requestRenderAll();
    }, 5 * 1000); // 5 seconds

    // reset inactivity timer on every render
    useEffect(() => {
        resetControlHideTimer();
        resetBannerTimer();
    });

    useEffect(() => {
        // during initialization canvas is null
        if (canvas === null) {
            return;
        }

        // we need to call this after every call of clearObjectEvents
        const activateMode = () => {
            setMode(canvas, editable ? mode : SELECTION_MODE, tool, isMobile);
        };

        const onChange = (_e) => {
            if (canvas.isLoading) {
                return;
            }

            setDirty(true);
            // FIXME - this is a HACK to fix fabricjs object modification bug
            // fabricjs release for object fires later than this onModified so sometimes pushCanvas is done while still
            // in drag mode.
            // please try fabricjs 6.0 (object:modified got a workaround, maybe also fix)
            setTimeout(() => pushCanvas(canvasToJson(canvas)), 20);
            resetControlHideTimer();
        };

        const onAdded = (evt) => {
            if (canvas.isLoading) {
                return;
            }
           
            setScaling(evt.target, isMobile);

            if (evt.target?.custom?.type === 'text') {
                setTool('select'); // #10
                setText(evt.target);
            }

            if (evt.target?.custom?.type === 'clipart') {
                if (evt.target.custom?.clipart === 'futbol') {
                    setTool('select'); // #6
                }
            }

            if (limitReached(canvas)) {
                showInfo(translate('board.layout.validation.max_items'), {severity: 'warning'});
            }

            customControlVisibility(evt.target);
            setDirty(true);
            pushCanvas(canvasToJson(canvas));
            resetControlHideTimer();
        };

        clearEvents(canvas);
        registerGlobalEvents(canvas, onChange, onAdded, onChange);
        canvas.on({
            'selection:created': (e) => {
                const selected = e.selected?.[0];

                if(Array.isArray(e.selected) && e.selected.length > 0) {
                    e.selected.forEach(item => {
                        drawBorder(item);
                    });

                    // its group
                    if (e.selected.length > 1) {
                        setGroupControlVisibility(canvas.getActiveObject());
                    }
                }

                if (selected) {
                    registerObjectEvents(canvas, selected);
                    activateMode();
                }

                resetControlHideTimer();
            },
            'selection:updated': (e) => {
                const selected = e.selected?.[0];

                if(Array.isArray(e.selected) && e.selected.length > 0) {
                    e.selected.forEach(item => {
                        drawBorder(item);
                    });

                    // its group
                    if (e.selected.length > 1) {
                        setGroupControlVisibility(canvas.getActiveObject());
                    }
                }

                if (selected) {
                    clearObjectEvents(canvas);
                    registerObjectEvents(canvas, selected);
                    registerGlobalEvents(canvas, onChange, onAdded, onChange);
                    activateMode();
                }

                resetControlHideTimer();
            },
            'selection:cleared': (e) => {
                e.deselected && e.deselected.forEach(o => {
                    if (!isCustom(o)) {
                        o.sendBackwards();
                    }
                });
                clearObjectEvents(canvas);
                registerGlobalEvents(canvas, onChange, onAdded, onChange);
                activateMode();
                setPlayer(null);
                setText(null);

                if (e.deselected && e.deselected[0] && e.deselected[0].text === '') {
                    canvas.remove(e.deselected[0]);
                }

                resetControlHideTimer();
            },
        });

        activateMode();

        if (mode === 'select') {
            setLastObjectActive(canvas);
        }

        // update event handler if needed
        updateControls();
        resetControlHideTimer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas, editable, tool, mode, setPlayer, setText, setDirty, setTool, showInfo, isMobile, getCanvasStates, pushCanvas]);

    return <Box sx={styles.canvas}>
        <PlayerDialog/>
        <TextDialog/>
        <canvas ref={fabricRef} id="canvas"/>
    </Box>;
};

export default Canvas;
