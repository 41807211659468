export const animFrame = (canvas) => {
    if (!canvas) {
        return [];
    }

    const objects = canvas.getObjects().filter(e => e.custom && e.custom.id);
    return objects.map(o => ({
        id: o.custom.id,
        left: o.left,
        top: o.top
    }));
};

const animAnimate = (canvas, objects, frame, duration = 500) => {
    for (const o of objects) {
        const objPos = frame.find(f => f.id === o.custom.id);

        if (objPos !== undefined) {
            o.animate({left: objPos.left, top: objPos.top}, {
                duration,
                onChange: canvas.renderAll.bind(canvas),
            });
        }
    }
};

export const animPlayFrame = (canvas, frames, frameIndex, duration = 500) => {
    const objects = canvas.getObjects().filter(e => e.custom?.id);

    const frame = frames?.[frameIndex];

    if (!Array.isArray(frame?.frame)) {
        return;
    }

    console.log('frame', frame, objects);
    animAnimate(canvas, objects, frame.frame, duration);
};
