import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Close as CloseIcon} from '@mui/icons-material';
import {Box, Button as NormalButton,ClickAwayListener, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';

import icons from 'assets/icons';
import {guestLineup, homeLineup} from 'assets/lineups';
import {useAction} from 'canvas/canvas-actions';
import {teams} from 'canvas/Drawer/Player';
import {Text} from 'components';
import {useAnalytics, useTranslation} from 'hooks';
import {useBannersStore} from 'module/banner';
import {useSheetStore} from 'module/sheet/zustand';
import {useCampaignStore} from 'store';
import {usePrefsStore} from 'store/prefs';

import {default as MenuHeader} from '../../../components/MenuHeader';

import {default as Button} from './components/Button';
import {tricotColors} from './components/TricotPicker';
import {ColorOptions,MultiTool, Tool, TricotPicker} from './components';

const styles = {
    toolbarContainer: {
        position: 'relative',
        backgroundColor: 'grey.darkest',
        justifyContent: 'space-between',
        '& > *': {
            height: {
                mobile: 50,
                desktop: 70,
            },
        },
    },
    newContainer: {
        flexWrap: 'nowrap' // currently not needed with only 5 icons in the toolbar
    },
    box: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'black.main',
        padding: '0 20px',
        width:  '100%',
        height: 'auto',
        overflowY: 'auto',
        overflowX: 'none',
    },
    innerBox: {
        marginBottom: 1.25,
        borderTop: '1px solid',
        borderTopColor: 'grey.darker',
        paddingTop: '20px',
    },
    innerBoxOw1: {
        marginBottom: 1.25,
        borderTop: '1px solid',
        borderTopColor: 'grey.darker',
        paddingTop: 0,
    },
    toolBox: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginBottom: '30px',
    },
    toolBoxOw1: {
        borderLeft: 'none',
        paddingLeft: 0,
    },
    optionsSubtitles: {
        color: 'white.main',
        margin: '0 0 6px 0',
        fontSize: 16,
        width: '100%',
    },
    closeFlyout: {
        position: 'fixed',
        right: '0',
        top: '0',
        color: 'black.main',
        arrow: 'pointer',
        fontSize: '40px',
        zIndex: 999,
        backgroundColor: 'white.main',
    },
    overlay: {
        position: 'absolute',
        backgroundColor: 'black.main',
        height: '100vh',
        width: '100vw',
        top: '100%',
        zIndex: 1,
        opacity: '.8',
    },
    mainButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        WebkitUserSelect: 'none !important',
        borderRadius: '5px',
        width: {
            mobile: 70,
            desktop: 84,
        },
        height: {
            mobile: 70,
            desktop: 84,
        },
        opacity: 0.95,
        position: 'relative',
        transition: 'all 0.25s ease-in-out',
        backgroundColor: 'grey.darkest',
        border: '2px solid',
        borderColor: 'grey.lightest',
    },
    mainButtonActive: {
        border: '2px solid',
        borderColor: 'white.main',
        overflow: 'hidden',
    },
    mainButtonLineUp: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        WebkitUserSelect: 'none !important',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        width: '90px',
        height: 'auto',
        opacity: 0.5,
        position: 'relative',
        transition: 'all 0.25s ease-in-out',
        backgroundColor: 'grey.darkest',
        
    },
    mainButtonActiveLineUp: {
        opacity: 1,
    },
    lineUpTitle: {
        fontSize: '12px',
        color: '#fff',
        textAlign: 'center',
        padding: '4px 0 2px 0',
        overflow: 'hidden',
        borderRadius: '5px',
        opacity: 0.5,
        margin: '0 0 5px 0',
    },
    lineUpTitleActive: {
        opacity: 1,
    },
    takeOver: {
        marginTop: 0,
        borderTop: '1px solid',
        borderTopColor: 'grey.darker',
        paddingTop: '30px',
    },
    takeOverButton: {
        color: 'white.main',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 20px',
        margin: 0,
        width: '100%',
        backgroundColor: 'green.main',
        '&:hover': {
            backgroundColor: 'green.main',
        }
    },

};

const svgIcons = {
    'select': icons.Pointer,
    'draw': icons.Pencil,
    'arrow': icons.Arrow,
    'triangle': icons.Triangle,
    'arrowDashed': icons.ArrowDashed,
    'circle': icons.Circle,
    'rect': icons.Rectangle,
    'futbol': icons.SoccerBall,
    'cone': icons.ConeLarge,
    'coneSmall': icons.ConeSmall,
    'pole': icons.Pole,
    'ladder': icons.Ladder,
    'text': icons.Text,
    'down': icons.ArrowDownward,
    'up': icons.ArrowUpward,
};

const defaultIconColor = 'black.main';

const defaultToolOptions = {
    color: 'black.main'
};

const Toolbar = () => {
    const translate = useTranslation();
    const editable = useSheetStore(state => state.frameIndex) === 0;

    const [showOption, setShowOption] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    const getColor = usePrefsStore((state) => state.getColor);
    const setColor = usePrefsStore((state) => state.setColor);
    const tool = usePrefsStore((state) => state.tool);
    const options = usePrefsStore((state) => state.tools);
    const setTool = usePrefsStore((state) => state.setTool);
    const homeTeam = usePrefsStore((state) => state.homeTeam);
    const setHomeTeam = usePrefsStore((state) => state.setHomeTeam);
    const lineupIndex = usePrefsStore((state) => state.lineupIndex);
    const setLineupIndex = usePrefsStore((state) => state.setLineupIndex);

    const bannersShow = useBannersStore((store) => store.show);
    const banners = useCampaignStore((state) => state.banners);
    const {dispatch: onAction} = useAction();

    const sendAnalytics = useAnalytics();

    const toolSelected = (t, defaults) => {
        if (tool !== t) {
            const opts = options[t] || {};

            sendAnalytics('tool', t, opts);

            for (const def in defaults) {
                if (Object.isExtensible(opts) && opts[def] === undefined) {
                    opts[def] = defaults[def];
                }
            }

            setTool(t, opts);
            setTimeout(() => setShowOption(null), 240);
        } else {
            if(tool === 'select') return;
            setShowOption(tool);
        }

        if (showOption) setShowOption(null);
    };

    const optionSelected = (t, property, value, close = false) => {
        if (property) {
            setTool(t, {[property]: value});
        } else {
            setTool(t);
        }

        if (close) {
            setTimeout(() => setShowOption(null), 240);
        }
    };

    const colorSelected = (t, property, value, close = true) => {
        setColor(tool, value);

        if (close) {
            setTimeout(() => setShowOption(null), 240);
        }
    };

    const actionClicked = (action, ...args) => {
        onAction(action, ...args);
        setShowOption(null);
    };

    const showBanners = () => {
        if(banners && banners.length > 0) {
            bannersShow();
        }
    };

    const arrowClick = (newTool) => {
        if (!showOption) {
            setShowOption(newTool);
        } else {
            setShowOption(null);
        }
    };

    return (
        <ClickAwayListener onClickAway={() => setShowOption(null)}>

            <Grid
                container
                direction="row"
                justifyContent={'space-between'}
                sx={styles.toolbarContainer}
            >
                <Grid item xs>
                    <Grid container direction={'row'} sx={styles.newContainer}>
                        
                        {/* 1. Auswahl-Tool */}
                        <Tool
                            name="select"
                            svg={icons.Pointer}
                            selectedTool={tool}
                            onClick={toolSelected}
                            noArrow={true}
                        />

                        {/* 2. Zeichnen-Tool */}
                        <MultiTool
                            name="draw-arrow-triangle-arrowDashed-circle-rect"
                            tools={'draw-arrow-triangle-arrowDashed-circle-rect'.split('-').map((compName) => ({
                                name: compName,
                                svg: svgIcons[compName],
                                selectedColor: options[compName] ? options[compName].color : defaultIconColor,
                            }))}
                            onArrowClick={arrowClick}
                            onClick={(newTool) => toolSelected(newTool, defaultToolOptions)}
                            options={options}
                            selectedTool={tool}
                            selectedOption={showOption}
                            disabled={!editable}
                        >
                            {/* FlyOut */}
                            <Box sx={styles.box}>

                                {/* Menü-Header */}
                                <MenuHeader text={translate('board.toolbar.draw')} handleBackButton={null} handleForwardButton={() => setShowOption(null)} />

                                {/* Icons */}
                                <Box sx={styles.innerBox}>
                                    <Typography
                                        sx={styles.optionsSubtitles}><Text>board.toolbar.icon</Text>:</Typography>
                                    <Box sx={styles.toolBox}>
                                        {'draw-arrow-triangle-arrowDashed-circle-rect'.split('-').map((compName, index) =>
                                            <Box
                                                key={index} 
                                                sx={[
                                                    styles.mainButton,
                                                    (tool === compName) && styles.mainButtonActive
                                                ]}
                                            >
                                                <Button
                                                    key={index}
                                                    svg={svgIcons[compName]}
                                                    iconColor={theme.palette.white.main}
                                                    name={compName}
                                                    onClick={t => optionSelected(t, 'color', getColor(t))}
                                                    selectedEffect={false}
                                                />
                                            </Box>)}
                                    </Box>
                                </Box>
                                
                                {/* Farben */}
                                <Typography sx={styles.optionsSubtitles}><Text>global.color</Text>:</Typography>
                                <Box sx={styles.toolBox}>
                                    <ColorOptions
                                        name="color"
                                        onClick={colorSelected}
                                        colorBg={true}
                                        selectedColor={getColor(tool) ? getColor(tool) : 'black.main'}
                                        boxed={true}
                                    />
                                </Box>

                                <Box sx={styles.takeOver}>
                                    <NormalButton
                                        onClick={() => setShowOption(null)}
                                        sx={styles.takeOverButton}
                                    >Übernehmen</NormalButton>
                                </Box>
                            </Box>
                        </MultiTool>

                        {/* 3. Trainings-Utensilien-Tool */}
                        <MultiTool
                            name="futbol-cone-coneSmall-pole-ladder"
                            tools={'futbol-cone-coneSmall-pole-ladder'.split('-').map((compName) => ({
                                name: compName,
                                svg: svgIcons[compName],
                                selectedColor: options[compName] ? options[compName].color : defaultIconColor,
                            }))}
                            onArrowClick={arrowClick}
                            onClick={(newTool) => toolSelected(newTool, defaultToolOptions)}
                            options={options}
                            selectedTool={tool}
                            selectedOption={showOption}
                            disabled={!editable}
                        >
                            {/* FlyOut */}
                            <Box sx={styles.box}>

                                {/* Menü-Header */}
                                <MenuHeader text={translate('board.toolbar.training_tools')} handleBackButton={null} handleForwardButton={() => setShowOption(null)} />

                                {/* Icons */}
                                <Box sx={styles.innerBox}>
                                    <Typography
                                        sx={styles.optionsSubtitles}><Text>board.toolbar.icon</Text>:</Typography>
                                    <Box sx={styles.toolBox}>
                                        {'futbol-cone-coneSmall-pole-ladder'.split('-').map((compName, index) => 
                                            <Box
                                                key={index} 
                                                sx={[
                                                    styles.mainButton,
                                                    (tool === compName) && styles.mainButtonActive
                                                ]}
                                            >
                                                <Button
                                                    key={index}
                                                    svg={svgIcons[compName]}
                                                    iconColor={theme.palette.white.main}
                                                    name={compName}
                                                    onClick={t => optionSelected(t, 'color', getColor(t))}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                                {/* Farben */}
                                <Typography sx={styles.optionsSubtitles}><Text>global.color</Text>:</Typography>
                                <Box sx={styles.toolBox}>
                                    <ColorOptions
                                        name="color"
                                        onClick={colorSelected}
                                        colorBg={true}
                                        selectedColor={getColor(tool) ? getColor(tool) : 'black.main'}
                                        boxed={true}
                                    />
                                </Box>

                                <Box sx={styles.takeOver}>
                                    <NormalButton
                                        onClick={() => setShowOption(null)}
                                        sx={styles.takeOverButton}
                                    >Übernehmen</NormalButton>
                                </Box>

                            </Box>
                        </MultiTool>

                        {/* 4. Text-Tool */}
                        <Tool
                            name="text"
                            svg={icons.Text}
                            selectedColor={options['text'] ? options['text'].color : defaultIconColor}
                            onArrowClick={arrowClick}
                            onClick={(newTool) => toolSelected(newTool, defaultToolOptions)}
                            selectedTool={tool}
                            selectedOption={showOption}
                            disabled={!editable}
                        >
                            {/* FlyOut */}
                            <Box sx={styles.box}>

                                {/* Menü-Header */}
                                <MenuHeader text={translate('board.toolbar.text_tools')} handleBackButton={null} handleForwardButton={() => setShowOption(null)} />

                                {/* Farben */}                                  
                                <Box sx={styles.innerBox}>
                                    <Typography sx={styles.optionsSubtitles}><Text>global.color</Text>:</Typography>
                                    <Box sx={styles.toolBox}>
                                        <ColorOptions
                                            name="color"
                                            onClick={(t, property, value) => colorSelected(t, property, value, true)}
                                            colorBg={true}
                                            selectedColor={getColor(tool) ? getColor(tool) : 'black.main'}
                                            boxed={true}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Tool>

                        {/* Spieler-Tool (Trikots, Magnete, Spielrichtung, Formation) */}
                        <MultiTool
                            name="player-playerCircle"
                            tools={[
                                {
                                    svg: icons.Tshirt,
                                    name: 'player',
                                    selectedColor: options.player ? options.player.color?.value1 : 'black.main',
                                    iconStyle: {
                                        width: isMobile ? 20 : 35,
                                        height: isMobile ? 20 : 35,
                                    }
                                },
                                {
                                    name: 'playerCircle',
                                    svg: icons.CircleFull,
                                    selectedColor: options.playerCircle ? options.playerCircle.color : 'black.main',
                                },
                            ]}
                            sx={{
                                '.fa-secondary': {fill: theme.palette.white.main, opacity: 1},
                                '.fa-primary': {fill: theme.palette.black.main, opacity: 1}
                            }}
                            onArrowClick={arrowClick}
                            onClick={(newTool) => {
                                toolSelected(newTool, {
                                    color: tricotColors[0]
                                });
                            }}
                            selectedTool={tool}
                            selectedOption={showOption}
                            disabled={!editable}
                        >
                            {/* Flyout */}
                            <Box sx={[styles.box, {
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                    height: '100%',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'grey.light',
                                    borderRadius: 2
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'grey.lightest',
                                    borderRadius: 2
                                }
                            }]}>

                                {/* Menü-Header */}
                                <MenuHeader text={translate('board.toolbar.player')} handleBackButton={null} handleForwardButton={() => setShowOption(null)} />
                                
                                <Box sx={[styles.innerBox, styles.innerBoxOw1]}>

                                    {/* Trikots/Magnete/Größe */}
                                    <Box sx={[styles.toolBox, styles.toolBoxOw1]}>
                                        <TricotPicker
                                            name1="player"
                                            name2="playerCircle"
                                            onClick={(t, property, value) => optionSelected(t, property, value, false)}
                                        />
                                    </Box>

                                    {/* Heim/Gast */}
                                    <Box sx={styles.toolBox}>

                                        <Typography
                                            sx={styles.optionsSubtitles}><Text>board.toolbar.playing_direction</Text>:</Typography>

                                        <Box sx={[
                                            styles.mainButton,
                                            (homeTeam === true) && styles.mainButtonActive
                                        ]}>
                                            <Button
                                                name="home"
                                                svg={svgIcons.up}
                                                selected={homeTeam}
                                                iconColor={theme.palette.white.main}
                                                onClick={() => setHomeTeam(true)}
                                                selectedEffect={false}
                                            >
                                                <CloseIcon sx={styles.closeFlyout} onClick={() => setShowOption(null)}></CloseIcon>
                                            </Button>
                                        </Box>
                                        <Box sx={[
                                            styles.mainButton,
                                            (homeTeam === false) && styles.mainButtonActive
                                        ]}>
                                            <Button
                                                name="guest"
                                                svg={svgIcons.down}
                                                selected={!homeTeam}
                                                iconColor={theme.palette.white.main}
                                                onClick={() => setHomeTeam(false)}
                                                selectedEffect={false}
                                            >
                                                <CloseIcon sx={styles.closeFlyout} onClick={() => setShowOption(null)}></CloseIcon>
                                            </Button>
                                        </Box>
                                    </Box>

                                    {/* Aufstellung */}
                                    <Box sx={styles.toolBox}>

                                        <Typography sx={styles.optionsSubtitles}><Text>board.toolbar.lineup</Text>:</Typography>

                                        {homeLineup.map((l, index) => {
                                            return (
                                                <Box key={l.name}>
                                                    <Typography sx={[
                                                        styles.lineUpTitle,
                                                        (index === lineupIndex) && styles.lineUpTitleActive
                                                    ]}>{l.name}</Typography>
                                                    <Box 
                                                        sx={[
                                                            styles.mainButtonLineUp,
                                                            (index === lineupIndex) && styles.mainButtonActiveLineUp
                                                        ]}
                                                    >
                                                        <Button
                                                            iconColor={theme.palette.white.main}
                                                            key={l.name}
                                                            name={l.name}
                                                            selected={index === lineupIndex}
                                                            sx={{height: 'auto'}}
                                                            label={<Box width="90px"><l.Icon style={{display: 'block', opacity: 1}} /></Box>}
                                                            onClick={() => {
                                                                setLineupIndex(index);
                                                                actionClicked('lineup', homeTeam ? teams.HOME : teams.GUEST, homeTeam ? homeLineup[index] : guestLineup[index]);
                                                            }}
                                                            selectedEffect={false}
                                                            labelStyle={{fontSize: isMobile ? 13 : 17}}
                                                        />
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </Box>

                                    <Box sx={styles.takeOver}>
                                        <NormalButton
                                            onClick={() => setShowOption(null)}
                                            sx={styles.takeOverButton}
                                        >Übernehmen</NormalButton>
                                    </Box>

                                </Box>
                            </Box>
                        </MultiTool>

                        {/* overlay background which will stop clicking on the map while the menu is open*/}

                        {/* if clicked on the overlay the menu will close first then the map will be interactive*/}

                        {showOption && <Box sx={styles.overlay} onClick={() => setShowOption(null)}></Box>}

                    </Grid>

                </Grid>

                {!isMobile && (
                    <Grid item>
                        <Tool
                            name="campaign"
                            svg={icons.GeomixX}
                            onClick={showBanners}
                        />
                    </Grid>
                )}

            </Grid>
        </ClickAwayListener>
    );
};

Toolbar.propTypes = {
    onAction: PropTypes.func,
};

export default Toolbar;
