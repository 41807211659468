import React from 'react';

import ColorPicker from './ColorPicker';

const ColorOptions = ({
    name,
    onClick,
    colors,
    svg,
    colorBg,
    selectedColor,
    boxed
}) => {
    return <ColorPicker
        colors={colors}
        colorBg={colorBg}
        svg={svg}
        selectedColor={selectedColor}
        onSelect={(property, color) => onClick(name, property, color.value)}
        name="color"
        boxed={boxed}
    />;
};

export default ColorOptions;
