import React from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import {Button,ListItem, ListItemButton, ListItemText} from '@mui/material';

const styles = {
    menuHeader: {
        color: 'white.main',
        height: '70px',
        padding: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    menuHeaderButton: {
        color: 'white.main',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: 0,
        margin: 0,
        width: '70px',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    menuHeaderButtonRight: {
        justifyContent: 'flex-end',
    },
    menuHeaderText: {
        color: 'white.main',
        fontSize: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '70px',
    },
};

const MenuHeader = ({text, handleBackButton, handleForwardButton}) => {

    return(
        <ListItem sx={styles.menuHeader}>

            {handleBackButton && 
                <ListItemButton sx={styles.menuHeaderButton} onClick={handleBackButton}>
                    <ArrowBackIosNewIcon />
                </ListItemButton>
            }
            
            <ListItemText sx={styles.menuHeaderText} primary={text}></ListItemText>
            
            <Button sx={[styles.menuHeaderButton, styles.menuHeaderButtonRight]} onClick={handleForwardButton}>
                <CloseIcon />
            </Button>

        </ListItem>
    );
};

export default MenuHeader;