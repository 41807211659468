import React from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Box, useMediaQuery, useTheme} from '@mui/material';

import Button from './Button';

const styles = {
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: {
            mobile: 50,
            desktop: 70,
        },
        position: 'relative',
        backgroundColor: 'grey.darkest',
        transition: 'background-color 0.1s ease-out',
    },
    rootSelected: {
        backgroundColor: 'grey.darker',
    },
    button: {
        height: {
            mobile: 50,
            desktop: 70,
        },
        width: {
            mobile: 50,
            desktop: 70,
        },
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '4px solid',
        borderBottomColor: 'grey.darkest',
    },
    options: {
        border: 'cyan 10px solid',
        zIndex: 1000,
        position: 'absolute',
        top: {
            mobile: 50,
            desktop: 70,
        },
        left: {
            mobile: 5,
            desktop: 0,
        },
    },
    arrowBox: {
        height: '100%',
        borderBottom: '4px solid',
        borderBottomColor: 'grey.darker',
        zIndex: 10,
    },
    arrow: {
        color: 'white.main',
        transition: '0.6s ease',
        height: '100%',
    },
    arrowSelected: {
        transform: 'rotate(180deg)',
        transformOrigin: 'center',
    },
    disableToolbar: {
        height: 70,
        width: '100%',
        backgroundColor: 'black.main',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 11,
        opacity: 0.8,
    },
};

const Tool = ({
    name,
    svg,
    selectedTool,
    selectedColor,
    selectedOption,
    onClick,
    onArrowClick,
    label,
    children,
    disabled,
    noArrow,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    return <Box sx={{...styles.root, ...(name === selectedTool ? styles.rootSelected : {})}}>
        <Button
            sx={[
                styles.button,
                selectedColor && {borderBottomColor: selectedColor}
            ]}
            name={name}
            label={label}
            labelStyle={{fontSize: isMobile ? 18 : 32}}
            svg={svg}
            onClick={() => onClick && onClick(name)}
            selected={name === selectedTool}
            disabled={disabled}
        />
        {disabled && <Box style={styles.disableToolbar}></Box>}
        {name === selectedTool && !noArrow ? (
            <Box
                sx={[
                    styles.arrowBox,
                    selectedOption === name && styles.arrowSelectedBox,
                    selectedColor && {borderBottomColor: selectedColor}
                ]}
            >
                {!disabled && <ArrowDropDownIcon
                    sx={[
                        styles.arrow,
                        selectedOption === name && styles.arrowSelected
                    ]}
                    onClick={() => onArrowClick && onArrowClick(name)}
                ></ArrowDropDownIcon>}
            </Box>
        ) : null}
        <Box sx={[
            styles.options,
            {
                opacity: !disabled && selectedOption === name ? 1 : 0,
                transition: 'all 0.3s',
                visibility: !disabled && selectedOption === name ? 'visible' : 'hidden',
            }    
        ]}>{!disabled && selectedOption === name && children}</Box>
    </Box>;
};

Tool.propTypes = {
    name: PropTypes.string.isRequired,
    svg: PropTypes.any,
    selectedTool: PropTypes.string,
    selectedColor: PropTypes.string,
    selectedOption: PropTypes.string,
    onClick: PropTypes.func,
    onArrowClick: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Tool;
