import React from 'react';

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

import {formatPrice} from 'lib/utils';

import QRCode from './QRCode';

const PREFIX = 'ArticleBanner';

const classes = {
    bannerBox: `${PREFIX}-bannerBox`,
    bannerImageHolder: `${PREFIX}-bannerImageHolder`,
    bannerImage: `${PREFIX}-bannerImage`,
    bannerImageQrcode: `${PREFIX}-bannerImageQrcode`,
    bannerContent: `${PREFIX}-bannerContent`,
    articleTitle: `${PREFIX}-articleTitle`,
    priceBox: `${PREFIX}-priceBox`,
    savedPrice: `${PREFIX}-savedPrice`,
    price: `${PREFIX}-price`,
    strikedPrice: `${PREFIX}-strikedPrice`
};

const StyledBox = styled(Box)(({theme: _theme}) => ({
    [`&.${classes.bannerBox}`]: {
        backgroundColor: _theme.palette.white.main,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    [`& .${classes.bannerImageHolder}`]: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        position: 'relative',
    },

    [`& .${classes.bannerImage}`]: {
        display: 'block',
        width: 400,
        height: 400,
    },

    [`& .${classes.bannerImageQrcode}`]: {
        position: 'absolute',
        top: 10,
        left: 10,
    },

    [`& .${classes.bannerContent}`]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 25,
        paddingBottom: 25,
        paddingLeft: 10,
        paddingRight: 10,
        boxSizing: 'border-box',
        backgroundColor: _theme.palette.white.main,
    },

    [`& .${classes.articleTitle}`]: {
        fontWeight: '400',
        textAlign: 'center',
        fontSize: 21,
    },

    [`& .${classes.priceBox}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
    },

    [`& .${classes.savedPrice}`]: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: _theme.palette.black.main,
        color: _theme.palette.white.main,
        fontSize: 19,
    },

    [`& .${classes.price}`]: {
        fontWeight: '600',
        fontSize: 27,
        marginLeft: 10,
        marginRight: 10,
    },

    [`& .${classes.strikedPrice}`]: {
        textDecoration: 'line-through',
        fontSize: 27,
    }
}));

export const calculateSaving = (variant) => {
    return variant.priceInstead / 100 * variant.saving;
};

const ArticleBanner = ({banner}) => {

    const article = banner.article;
    const variant = article?.articleVariations?.[0];

    if (!article || !variant) {
        return null;
    }

    // title={banner.title}
    // subheader={article.subTitle}
    return (
        <StyledBox className={classes.bannerBox}>
            <Box className={classes.bannerImageHolder}>
                <img className={classes.bannerImage} src={article.imageIds?.[0]} alt={article.title}/>
                <QRCode className={classes.bannerImageQrcode} articleId={article.id}/>
            </Box>
            <Box className={classes.bannerContent}>
                <Box className={classes.articleTitle}>{article.subTitle}</Box>
                <Box className={classes.priceBox}>
                    {variant.saving > 0 && <Box className={classes.savedPrice}>- {variant.saving}%</Box>}
                    <Box className={classes.price}>{variant.currency} {formatPrice(variant.price)}</Box>
                    {variant.saving > 0 && <Box className={classes.strikedPrice}>{variant.currency} {formatPrice(variant.priceInstead)}</Box>}
                </Box>
            </Box>
        </StyledBox>
    );
};

export default ArticleBanner;
