import React, {useState} from 'react';

import {
    Close as CloseIcon,
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import {
    Button,
    Grid, Stack,
    SwipeableDrawer as MuiDrawer,
    Typography
} from '@mui/material';

import {MenuHeader} from 'components';
import {accordionStyles,CustomAccordion, CustomAccordionDetails, CustomAccordionSummary, Text} from 'components';
import {Group, GroupListItem} from 'module/group';
import {useGroupsStore} from 'module/group/zustand';
import {useSheetStore} from 'module/sheet/zustand';
import {usePrefsStore} from 'store';

import {useTranslation} from '../../hooks';

import SheetMenu from './SheetMenu';

const styles = {
    drawer: {
        width: '100%',
        backgroundColor: 'black.main',
    },
    overlay: {
        padding: '0 20px 20px 20px',
        width: '100%',
        zIndex: '999',
        animationName: 'slideToLeft',
        animationDuration: '0.1s',
        animationFillMode: 'forwards',
    },
    cutSheetCloseButton: {
        textAlign: 'right',
        marginLeft: 'auto',
        justifyContent: 'flex-end',
        border: 'none',
        color: 'white.main',
        fontSize: '16px',
        cursor: 'pointer',
        fontWeight: '400',
    },
    listCutSheet: {
        fontSize: 22,
        color: 'white.main',
    },
    formBlock: {
        width: '100%',
        display: 'flex',
        backgroundColor: 'grey.darker',
        border: '1px solid',
        borderColor: 'grey.darker',
        padding: '20px',
        marginBottom: '20px',
        boxSizing: 'border-box',
    },
    listTitle: {
        color: 'white.main',
        fontSize: '15px',
        margin: '0',
        padding: '0 0 10px 0',
        display: 'flex',
    }
};

const GroupMenu = () => {
    const translate = useTranslation();
    const [expanded, setExpanded] = useState(false);

    const groups = useGroupsStore((store) => store.groups);

    const setSelectedGroup = useGroupsStore((store) => store.setSelected);

    const showMenu = usePrefsStore((state) => state.showMenu);
    const setShowMenu = usePrefsStore((state) => state.setShowMenu);
    const setShowDrawer = usePrefsStore((state) => state.setShowDrawer);

    const updateSheet = useSheetStore((store) => store.upd);
    const selectedSheet = useSheetStore((store) => store.selected);

    const [editGroup, setEditGroup] = useState(null);
    const [cutSheet, setCutSheet] = useState(null);

    const scrollToTop = React.useCallback(() => {
        window.scrollTo({top: 0, behavior: 'auto'});
    }, []);

    const handleChange = (groupId, panel, isExpanded) => {
        if (editGroup) {
            if (isExpanded) setEditGroup(null);
            else return;
        }

        setExpanded(isExpanded ? panel : false);
    };

    const onSave = (_group) => {
        setEditGroup(null);
        setExpanded('panel0');
        // on creation of a new group, it should not be selected -> can lead to inconsistency display of selectedSheet/selectedGroup
        // setSelectedGroup(group.id);
    };

    const closeMenu = () => {
        setShowDrawer(true);
        setShowMenu(false);
    };

    const closeAll = () => {
        setShowDrawer(false);
        setShowMenu(false);
    };

    const onCutSheet = (sheet) => {
        setExpanded(false);
        setCutSheet(sheet);
        scrollToTop();
    };

    const moveSheet = (group) => {
        console.log('move sheet to group: ', group.name);
        updateSheet({...cutSheet, group: group.id});

        if (cutSheet.id === selectedSheet) {
            setSelectedGroup(group.id);
        }
       
        setCutSheet(false);
    };

    const moveSheetName = translate('board.sheet.action.move_to', cutSheet?.name);

    return <MuiDrawer
        disableSwipeToOpen
        PaperProps={{sx: styles.drawer}}
        anchor={'left'}
        open={showMenu}
        onOpen={() => null}
        onClose={closeAll}
    >
        <Grid container sx={styles.overlay}>

            {/* Menü-Header */}
            <MenuHeader text={<Text>board.menu.my_groups_sheets</Text>} handleBackButton={closeMenu} handleForwardButton={closeAll} />

            {/* newTheme */}
            {cutSheet ?
                <Grid item xs={12}>
                    <Grid sx={styles.formBlock} container>
                        <Grid item xs={12}>
                            <Stack direction="row" sx={styles.listCutSheet}>
                                <Typography variant="text">{moveSheetName}</Typography>:
                                <Button
                                    sx={styles.cutSheetCloseButton}
                                    onClick={() => setCutSheet(null)}
                                >
                                    <CloseIcon></CloseIcon>
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>

                </Grid> :

                <Grid item xs={12}>
                    <Grid sx={styles.formBlock} container>
                        <Grid item xs={12}>
                            <Typography sx={styles.listTitle} variant="text">
                                <Text>board.group.action.create</Text>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Group group={null} onSave={onSave}/>
                        </Grid>
                    </Grid>
                </Grid>}

            {/* allThemes */}
            {groups.slice().sort((a, b) => b.sort - a.sort).map((group, i) => (
                <Grid key={`panelgroup-${i}`} item xs={12}>
                    <CustomAccordion
                        expandedStyle={expanded === `panel${i}`}
                        expanded={cutSheet ? false : expanded === `panel${i}`}
                        onChange={(_, isExpanded) => handleChange(group.id, `panel${i}`, isExpanded)}
                    >
                        <CustomAccordionSummary
                            expandIcon={cutSheet ? null : <ExpandMoreIcon sx={accordionStyles.accordionExpandButton}/>}
                            aria-controls={`panel3bh-content${i}`}
                            id="panel3bh-header"
                            onClick={cutSheet ? () => moveSheet(group) : null}
                        >
                            <GroupListItem group={group} isCutMode={!!cutSheet} onEdit={setEditGroup} isEditing={editGroup?.id === group.id} isFirst={i === 0} isLast={groups.length === i+1}/>
                        </CustomAccordionSummary>

                        {/* sheets */}
                        <CustomAccordionDetails>
                            <SheetMenu groupId={group.id} onCutSheet={onCutSheet}></SheetMenu>
                        </CustomAccordionDetails>
                    </CustomAccordion>
                </Grid>
            ))}
        </Grid>
    </MuiDrawer>;
};

export default GroupMenu;
