import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Box,useMediaQuery, useTheme} from '@mui/material';

import Button from './Button';

const styles = {
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: {
            mobile: 50, 
            desktop: 70,
        },
        position: 'relative',
        backgroundColor: 'grey.darkest',
        transition: 'background-color 0.1s ease-out',
    },
    rootSelected: {
        backgroundColor: 'grey.darker',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: {
            mobile: 50,
            desktop: 70,
        },
        width: {
            mobile: 50,
            desktop: 70,
        },
        borderBottom: '4px solid',
        borderBottomColor: 'grey.darkest',
    },
    options: {
        zIndex: 1000,
        position: 'absolute',
        top: {
            mobile: 50,
            desktop: 70,
        },
        left: {
            mobile: 5,
            desktop: 0,
        },
    },
    arrowBox: {
        height: {
            mobile: 50,
            desktop: 70,
        },
        borderBottom: '4px solid',
        borderBottomColor: 'grey.darker',
        zIndex: 10,
    },
    arrow: {
        color: 'white.main',
        transition: '0.6s ease',
        height: '100%',
    },
    arrowSelected: {
        transform: 'rotate(180deg)',
        transformOrigin: 'center',
    },
    disableToolbar: {
        height: 70,
        width: '100%',
        backgroundColor: 'black.main',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 11,
        opacity: 0.8,
    },
};

const MultiTool = ({
    name,
    tools,
    selectedTool,
    selectedOption,
    onClick,
    onArrowClick,
    label,
    children,
    disabled,
    sx
}) => {
    const theme = useTheme();

    let tool = tools.find((t) => t.name === selectedTool);

    const [selected, setSelected] = useState(tool ? tool : tools[0]);
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    useEffect(() => {
        if (tool) {
            setSelected(tool);
        }
    }, [tool]);

    const showOptions = !disabled && name.split('-').indexOf(selectedOption) !== -1;

    return <Box sx={[styles.root, tool && styles.rootSelected]}>
        <Button
            sx={[
                styles.button,
                selected.selectedColor && {borderBottomColor: selected.selectedColor},
                sx
            ]}
            name={name}
            label={label}
            labelStyle={{fontSize: isMobile ? 18 : 32}}
            iconStyle={selected.iconStyle}
            onClick={() => {
                onClick && onClick(selected.name);
            }}
            svg={selected.svg}
            selected={selected.name === selectedTool}
            disabled={disabled}
        />
        {disabled && <div style={styles.disableToolbar}></div>}
        {selected?.name === tool?.name ? (
            <Box
                sx={[
                    styles.arrowBox,
                    showOptions && styles.arrowSelectedBox,
                    selected.selectedColor && {borderBottomColor: selected.selectedColor}
                ]}
            >
                {!disabled && <ArrowDropDownIcon
                    sx={[
                        styles.arrow,
                        showOptions && styles.arrowSelected
                    ]}
                    onClick={() => onArrowClick && onArrowClick(selected.name)}
                />}
            </Box>
        ) : null}
        <Box sx={[
            styles.options,
            {
                opacity: !showOptions ? 0 : 1,
                transition: 'all 0.3s',
                visibility: !showOptions ? 'hidden' : 'visible',
            }    
        ]}>{showOptions && children}</Box>
    </Box>;
};

MultiTool.propTypes = {
    name: PropTypes.string.isRequired,
    tools: PropTypes.array.isRequired,
    selectedTool: PropTypes.string,
    selectedOption: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    svg: PropTypes.object,
    disabled: PropTypes.bool,
    sx: PropTypes.object
};

export default MultiTool;
