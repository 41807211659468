import React, {useState} from 'react';

import {
    ArrowDownward as ArrowDownwardIcon,
    ArrowUpward as ArrowUpwardIcon,
    Delete as DeleteIcon,
    Edit as EditIcon
} from '@mui/icons-material';
import {Box,Grid, IconButton} from '@mui/material';
import Typography from '@mui/material/Typography';

import {useAction} from 'canvas/canvas-actions';
import {useCanvasStore} from 'canvas/zustand';
import {ConfirmDialog, Text, useConfirmDialog} from 'components';
import {useTranslation} from 'hooks';
import {useSnackbarStore} from 'store';

import {useSheetStore} from '../sheet/zustand';

import Group from './Group';
import {useGroupsStore} from './zustand';

const styles = {
    accordionHeaderContent: {
        color: 'white.main',
        display: 'flex',
        flexDirection: {mobile: 'column', desktop: 'row'},
        justifyContent: {mobile: 'flex-start', desktop: 'space-between'},
        alignItems: {mobile: 'flex-start', desktop: 'center'},
        flexWrap: 'nowrap',
        width: '100%',
    },
    accordionHeaderButtonGroup: {
        display: 'flex',
        color: 'white.main',
        flexWrap: 'nowrap',
        paddingTop: {mobile: '8px', desktop: 0},
        paddingRight: {mobile: 0, desktop: '5px'},
        width: {mobile: '100%', desktop: 'auto'},
    },
    buttonIcon: {
        width: '18px',
        height: 'auto',
    },
    accordionButton: {
        marginRight: '5px',
        padding: 0,
    },
    accordionDeleteButton: {
        backgroundColor: 'grey.dark',
        color: 'red.dark',
        width: '40px',
        height: '40px',
        margin: 0,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '10px',
    },
    accordionEditButton: {
        color: 'white.main',
        backgroundColor: 'grey.dark',
        width: '40px',
        height: '40px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '10px',
    },
    titleHighlight: {
        color: 'white.main',
        padding: '0px 5px',
        borderRadius: '5px',
        margin: '0',
        textTransform: 'uppercase',
        lineHeight: 1,
        overflow: 'hidden',
        width: '100%',

    },
    titleHighlightText: {
        fontWeight: '700',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
    },
    themeTitle: {
        color: 'grey.lightest',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        fontSize: '1rem',
        width: '100%',
    },
    active: {
        backgroundColor: 'grey.lighter',
        color: 'white.main',
        fontWeight: '400',
        textTransform: 'uppercase',
        padding: '4px 5px',
        lineHeight: 1,
        borderRadius: '5px',
        margin: '0 5px 0 0',
    },
    disabled: {
        opacity: 0.4,
        pointerEvents: 'none'
    }
};

// const InputWrapper = ({children}) => (
//     <div style={{width: '100%', display: 'flex'}} onClick={(e) => e.stopPropagation()}>
//         {children}
//     </div>
// );

const ListItemActions = ({isEditing, onEdit, onDelete, updateSort, isFirst, isLast}) => {
    return <Grid sx={styles.accordionHeaderButtonGroup} item>
        {!isEditing ?
            <>
                <IconButton
                    disableFocusRipple
                    disableRipple
                    sx={[styles.accordionButton, styles.accordionEditButton, isFirst && styles.disabled ]}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(isFirst) return;
                        updateSort('up');
                    }}
                >
                    <ArrowUpwardIcon sx={styles.buttonIcon}/>
                </IconButton>
                <IconButton
                    disableFocusRipple
                    disableRipple
                    sx={[styles.accordionButton, styles.accordionEditButton, isLast && styles.disabled]}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(isLast) return;
                        updateSort('down');
                    }}
                >
                    <ArrowDownwardIcon sx={styles.buttonIcon}/>
                </IconButton>
                <IconButton sx={[styles.accordionButton, styles.accordionEditButton]} onClick={onEdit}>
                    <EditIcon sx={styles.buttonIcon}/>
                </IconButton>
                <IconButton sx={[styles.accordionButton, styles.accordionDeleteButton]} onClick={onDelete}>
                    <DeleteIcon sx={styles.buttonIcon}/>
                </IconButton>
            </>
            : <></>}
    </Grid>;
};

const ListItem = ({group, isCutMode, isEditing, onEdit, isFirst, isLast}) => {
    const translate = useTranslation();

    const [delMessage, setDelMessage] = useState(translate(`board.group.action.confirm_delete`));

    const showInfo = useSnackbarStore((state) => state.show);

    const sheetsByGroup = useSheetStore((store) => store.getByGroup);

    const delGroup = useGroupsStore((store) => store.del);
    const sortGroup = useGroupsStore((store) => store.sort);
    const deleteSheetsByGroup = useSheetStore((store) => store.delByGroup);
    const selected = useGroupsStore((store) => store.selected);
    const selectedSheet = useSheetStore((store) => store.selected);

    const setDirty = useCanvasStore((state) => state.setDirty);

    const {dispatch} = useAction();

    const doEdit = (e) => {
        e.stopPropagation();
        onEdit(group);
    };

    const doCancelEdit = () => {
        onEdit(null);
    };

    const onUpdate = (updatedGroup) => {
        if (updatedGroup) {
            doCancelEdit();
        }
    };

    const doDelete = (e) => {
        e.stopPropagation();

        if (group.id !== selected && !sheetsByGroup(group.id).map((sheet) => sheet.id).includes(selectedSheet)) {
            setDelMessage(
                sheetsByGroup(group.id).length > 0
                    ? 'board.group.action.confirm_delete_all'
                    : 'board.group.action.confirm_delete'
            );
            deleteConfirm.show(group.id);
        } else {
            showInfo(translate('board.group.validation.deletion_impossible'), {severity: 'warning'});
        }
    };

    const deleteConfirm = useConfirmDialog(
        (id) => {
            deleteSheetsByGroup(id);
            delGroup(id);
            dispatch('clear');
            setDirty(false);
            showInfo(translate('board.group.action.delete_success'));
        },
        undefined,
        delMessage
    );

    return <Grid sx={styles.accordionHeaderContent} container>
        {isEditing ? (
            <Grid item xs={12}>
                <Group group={group} onCancel={doCancelEdit} onSave={onUpdate}/>
            </Grid>
        ) : (
            <Box sx={styles.themeTitle}>
                {!isCutMode && group.id === selected ? <Typography
                    sx={styles.active}><Text>board.group.selected</Text></Typography> : null}
                <Box sx={styles.titleHighlight}>
                    {/*translate('board.group.group')*/}
                    <Typography sx={[
                        styles.titleHighlightText, {
                            '&::': {
                                scrollbarWidth: 'auto',
                                scrollbarColor: 'grey.lighter grey.lighter',
                                width: '8px',
                            },

                            '&::-webkit-scrollbar': {
                                width: '100%',
                                height: '8px',
                            },

                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'grey.darker',
                                borderRadius: '4px',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'grey.lighter',
                                borderRadius: '4px',
                                border: 0,
                            },
                        }]}>{group.name}</Typography>
                </Box>
            </Box>
        )}
        {!isCutMode ?
            <ListItemActions isFirst={isFirst} isLast={isLast} isEditing={isEditing} onEdit={doEdit} onDelete={doDelete} updateSort={(direction) => sortGroup(group, direction)}/> : null}
        <ConfirmDialog {...deleteConfirm} />
    </Grid>;
};

export default ListItem;
