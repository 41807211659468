import React, {useState} from 'react';

import {Info as InfoIcon} from '@mui/icons-material';
import {Box, Dialog, Slide, Stack, Tab, Tabs, Typography} from '@mui/material';

import {MenuHeader} from 'components';
import {useSettingsStore} from 'module/settings/zustand';

import Players from './Players';
import TeamsDetails from './TeamsDetails';

const styles = {
    tabHeaderContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    errorMessage: {
        width: '100%',
        border: '1px solid',
        borderColor: 'red.main',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        marginBottom: 0.5,
        backgroundColor: 'black.main',
        flexWrap: 'wrap',
    },
    errorMessageIcon: {
        color: 'red.main',
        marginRight: '6px',
        borderBottom: '3px solid',
        borderBottomColor: 'black.main',
    },
    errorMessageText: {
        color: 'red.main',
        marginRight: '6px',
        borderBottom: '3px solid',
        borderBottomColor: 'black.main',
    },
    errorMessageTextHighlight: {
        color: 'red.main',
        fontWeight: 'bold',
        borderBottom: '3px solid',
        borderBottomColor: 'red.main',
        whiteSpace: 'nowrap',
    },
    teamOverlayInline: {
        padding: '0 20px 105px 20px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },

};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 0}}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const TeamDialog = ({team, onClose}) => {
    const players = useSettingsStore((state) => state.players);
    const numPlayers = players.length;

    const [value, setValue] = useState(0);

    const handleClose = () => {
        onClose();
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
            style: {
                backgroundColor: 'black.main',
            },
        }}>

        <Box sx={styles.teamOverlayInline}>

            <MenuHeader text={team.name} handleBackButton={handleClose} handleForwardButton={handleClose} />

            <Box sx={styles.tabHeaderContainer}>

                {team.prefs.player.count - numPlayers &&
                    <Stack sx={styles.errorMessage}>
                        <Stack direction="row">
                            <InfoIcon sx={styles.errorMessageIcon}/>
                            <Typography sx={styles.errorMessageText}>
                                Um das Team am Board zu nutzen fehlen noch: <Typography
                                    as="span"
                                    sx={styles.errorMessageTextHighlight}>{team.prefs.player.count - numPlayers} Spieler!
                                </Typography>
                            </Typography>
                        </Stack>
                    </Stack>
                }

                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                >
                    <Tab label="Editieren"/>
                    <Tab label="Spieler"/>
                </Tabs>

            </Box>

            <TabPanel value={value} index={0}>
                <TeamsDetails team={team}></TeamsDetails>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Players/>
            </TabPanel>

        </Box>
    </Dialog>;
};

export default TeamDialog;
