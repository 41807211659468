import {useEffect} from 'react';

import {useInterval} from 'hooks';

import demoCampaign from './assets/demodata/campaign.json';
import demoInfo from './assets/demodata/info.json';
import {getEnv} from './lib/env';
import {loadCampaign} from './store/campaign';
import {loadInfo} from './store/info';
import {useCampaignStore,useInfoStore} from './store';

const Periodic = () => {
    const setInfo = useInfoStore(state => state.setInfo);
    const setCampaign = useCampaignStore(state => state.setCampaign);

    const isDemo = getEnv('TACTIX_DEMO') === '1';

    const load = async () => {
        const info = await loadInfo();
        setInfo(isDemo ? demoInfo : info);

        const campaign = await loadCampaign();
        setCampaign(isDemo ? demoCampaign : campaign);
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line
    }, []);

    useInterval(async () => {
        load();
    }, 60 * 1000);

    return null;
};

export default Periodic;
