import React from 'react';

import {
    ArrowDownward as ArrowDownwardIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    ArrowUpward as ArrowUpwardIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
} from '@mui/icons-material';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import {Box,Chip, Grid, IconButton, Stack, Typography, useMediaQuery, useTheme} from '@mui/material';

import {useAction} from 'canvas/canvas-actions';
import {useCanvasStore} from 'canvas/zustand';
import {ConfirmDialog, Text, useConfirmDialog} from 'components';
import {useTranslation} from 'hooks';
import {useGroupsStore} from 'module/group/zustand';
import {usePrefsStore, useSnackbarStore} from 'store';

import Sheet from './Sheet';
import {useSheetStore} from './zustand';

const styles = {
    listItemButtonGroup: {
        display: 'flex',
        color: 'white.main',
        flexWrap: 'nowrap',
        paddingTop: {mobile: '10px', desktop: 0},
        paddingRight: {mobile: 0, desktop: '5px'},

    },
    titleContainer: {
        margin: 0,
        flexGrow: 1,
    },
    accordionButton: {
        marginRight: '5px',
        padding: 0,
    },
    accordionEditButton: {
        color: 'white.main',
        backgroundColor: 'grey.dark',
        width: '40px',
        height: '40px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '10px',
    },
    accordionDeleteButton: {
        backgroundColor: 'grey.dark',
        color: 'red.dark',
        marginRight: '5px',
        width: '40px',
        height: '40px',
        borderRadius: '10px',
        display: 'flex',
        
        fontSize: '10px',
    },
    accordionListContent: {
        color: 'grey.lightest',
        padding: '16px 0px',
        margin: '0',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        width: '100%',
        boxSizing: 'border-box',
        position: 'relative',
    },
    buttonIcon: {
        width: '18px',
        height: 'auto',
    },
    accordionListContentBadge: {
        position: 'absolute',
        right: '-20px',
        top: '-12px',
        backgroundColor: 'green.main',
        color: 'white.main',
        padding: '4px 5px 3px 5px',
        fontSize: '11px',
        textTransform: 'uppercase',
        borderRadius: '3px',
        height: 'auto',
        fontWeight: '400',
    },
    sheetRowContainer: {
        width: '100%',
        padding: '0',
        boxSizing: 'border-box',
        flexGrow: 1,
    },
    sheetTitle: {
        color: 'grey.lightest',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        overflow: 'auto',
        fontSize: '1rem',
        flexGrow: 1,
        
    },
    sheetTitleHighlight: {
        fontWeight: '700',
        color: 'white.main',
        padding: '0 0 0 3px',
    },
    activeSheet: {
        color: 'green.main',
        fontSize: '13px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    disabled: {
        opacity: 0.4,
        pointerEvents: 'none'
    },
    lastButton: {
        marginLeft: 'auto'
    }
};

{/* sheetAction */}

const ListItemActions = ({isEditing, onCut, onEdit, onSelect, onDelete, updateSort, isFirst, isLast}) => {

    return <Grid sx={styles.listItemButtonGroup} item>
        {!isEditing ?
            <>
                <IconButton 
                    disableFocusRipple
                    disableRipple
                    sx={[styles.accordionButton, styles.accordionEditButton, isFirst && styles.disabled ]} 
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(isFirst) return;
                        updateSort('up');
                    }}
                >
                    <ArrowUpwardIcon sx={styles.buttonIcon}/>
                </IconButton>
                <IconButton 
                    disableFocusRipple
                    disableRipple
                    sx={[styles.accordionButton, styles.accordionEditButton, isLast && styles.disabled]} 
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(isLast) return;
                        updateSort('down');
                    }}
                >
                    <ArrowDownwardIcon sx={styles.buttonIcon}/>
                </IconButton>
                <IconButton sx={[styles.accordionButton,styles.accordionEditButton]} onClick={onCut}>
                    <ContentCutIcon sx={styles.buttonIcon}/>
                </IconButton>
                <IconButton sx={[styles.accordionButton,styles.accordionEditButton]} onClick={onEdit}>
                    <EditIcon sx={styles.buttonIcon}/>
                </IconButton>
                <IconButton sx={[styles.accordionButton,styles.accordionDeleteButton]} onClick={onDelete}>
                    <DeleteIcon sx={styles.buttonIcon}/>
                </IconButton>
                <IconButton sx={[styles.accordionButton,styles.accordionEditButton, styles.lastButton]} onClick={onSelect}>
                    <ArrowForwardIosIcon sx={styles.buttonIcon}></ArrowForwardIosIcon>
                </IconButton>
            </>
            : <></>}
    </Grid>;
};

const ListItem = ({sheet, isEditing, onEdit, onCut, isFirst, isLast}) => {
    
    const translate = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const showInfo = useSnackbarStore((state) => state.show);

    const setShowDrawer = usePrefsStore((state) => state.setShowDrawer);
    const setShowMenu = usePrefsStore((state) => state.setShowMenu);
    const setShowTimeline = usePrefsStore((state) => state.setShowTimeline);

    const deleteSheet = useSheetStore((store) => store.del);
    const selected = useSheetStore((store) => store.selected);
    const sortSheet = useSheetStore((store) => store.sort);
    const setSelected = useSheetStore((store) => store.setSelected);

    const setSelectedGroup = useGroupsStore((store) => store.setSelected);

    const setDirty = useCanvasStore((state) => state.setDirty);

    const {dispatch} = useAction();

    const doEdit = () => {
        onEdit(sheet);
    };

    const doCut = () => {
        onCut(sheet);
    };

    const doCancelEdit = () => {
        onEdit(null);
    };

    const onUpdate = (updatedSheet) => {
        if (updatedSheet) {
            doCancelEdit();
        }
    };

    const doDelete = () => {
        if (sheet.id !== selected) {
            deleteConfirm.show();
        } else {
            showInfo(translate('board.sheet.validation.deletion_impossible'), {severity: 'warning'});
        }
    };

    const doSelect = () => {
        setShowTimeline(false);
        setShowDrawer(false);
        setShowMenu(false);
        setSelected(sheet.id);
        setSelectedGroup(sheet.group);
    };

    const deleteConfirm = useConfirmDialog(
        () => {
            deleteSheet(sheet.id);
            dispatch('clear');
            setDirty(false);
            showInfo(translate('board.sheet.action.delete_success'));
        },
        undefined,
        'board.sheet.action.confirm_delete'
    );

    {/* editSheet // sheetList */}

    return <Grid sx={styles.accordionListContent} container direction={ isMobile ? 'column': 'row'} >
        {isEditing ? (
            <Grid item xs={12} sx={styles.titleContainer}>
                <Sheet sheet={sheet} onSave={onUpdate} onCancel={doCancelEdit}/>
            </Grid>
        ) : (
            <Grid item sx={styles.titleContainer}>

                <Stack sx={styles.sheetRowContainer}>
                    {!sheet.type && <Chip
                        sx={styles.accordionListContentBadge}
                        variant="filled"
                        label={translate('field.status.new')}
                    />}

                    {/* onClick={doSelect} + setShowTimeline(true or false) */}
                    <Box
                        component="div"
                        sx={[styles.sheetTitle, {
                            '&::-webkit-scrollbar': {
                                width: '100%',
                                height: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'grey.light',
                                borderRadius: 2
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'grey.lightest',
                                borderRadius: 2
                            }
                        }]}
                        onClick={doSelect}
                    >
                        {sheet.id === selected ? <Typography sx={styles.activeSheet}><Text>board.group.selected</Text></Typography> : null}
                        <Box>
                            {/*translate('board.sheet.sheet')*/}
                            <Typography component="span" sx={styles.sheetTitleHighlight}>{sheet.name}</Typography>
                        </Box>
                    </Box>

                    {sheet.frames?.length > 0 && <Chip
                        sx={styles.accordionListContentBadge}
                        variant="filled"
                        label={translate('board.menu.move')}
                    />}
                </Stack>
            </Grid>
        )}
        <ListItemActions 
            isFirst={isFirst} 
            isLast={isLast} 
            isEditing={isEditing} 
            onCut={doCut} 
            onSelect={doSelect} 
            onEdit={doEdit} 
            onDelete={doDelete}
            updateSort={(direction) => sortSheet(sheet, direction)}
        />
        <ConfirmDialog {...deleteConfirm} />
    </Grid>;
};

export default ListItem;
