import React, {useEffect, useState} from 'react';

import {
    //Menu as MenuIcon,
    Sync as SyncIcon
} from '@mui/icons-material';
import {Box, Grid, useMediaQuery, useTheme} from '@mui/material';

import {ActionButton} from 'components';
import {useModal} from 'hooks';
import {getMode, Modes} from 'lib/tactix';
import {SyncPullDialog, SyncPushDialog} from 'module/sync';
//import {usePrefsStore} from 'store';

const Actions = () => {
    const [mode, setMode] = useState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    const pullDialog = useModal();
    const pushDialog = useModal();

    useEffect(() => {
        const readMode = async () => {
            setMode(await getMode());
        };

        readMode();
    }, []);

    //const setShowDrawer = usePrefsStore((state) => state.setShowDrawer);
    return (
        <Box>
            <Grid container direction={'row'}>
                {pullDialog.visible && (
                    <SyncPullDialog open={pullDialog.visible} handleClose={pullDialog.hide} />
                )}
                {pushDialog.visible && (
                    <SyncPushDialog open={pushDialog.visible} handleClose={pushDialog.hide} />
                )}
                <Grid item>
                    {/*isMobile && (
                        {<ActionButton onClick={() => setShowDrawer(true)} sx={styles.barIcon}>
                            <MenuIcon sx={{color: 'white.main'}} size={'lg'} />
                        </ActionButton>
                    )*/}
                    {mode !== Modes.MONITOR && (
                        <ActionButton onClick={pushDialog.show}>
                            <SyncIcon
                                sx={{color: 'white.main'}}
                                size={isMobile ? 'lg' : undefined}
                            />
                        </ActionButton>
                    )}
                    {mode === Modes.MONITOR && (
                        <ActionButton onClick={pullDialog.show}>
                            <SyncIcon sx={{color: 'white.main'}} />
                        </ActionButton>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Actions;
