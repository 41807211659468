import React, {useEffect, useState} from 'react';

import {
    Cancel as CancelIcon,
    Save as SaveIcon} from '@mui/icons-material';
import {IconButton, InputBase, Stack} from '@mui/material';

import {v4 as uuidv4} from 'uuid';

import {getKeyboardOptions} from 'components';
import {useTranslation} from 'hooks';
import {useKeyboardStore, useSnackbarStore} from 'store';

import {useGroupsStore} from './zustand';

const styles = {
    inputBase: {
        backgroundColor: 'white.main',
        flex: 1,
        fontSize: '16px',
        borderRadius: '0',
        padding: '0 0 0 10px',
        flexGrow: 1,
        height: '50px',
        boxSizing: 'border-box',
        color: 'grey.darkest',
        border: 'none',
        outline: 'none',
        borderBottomLeftRadius: '6px',
        borderTopLeftRadius: '6px',
    },
    button: {
        backgroundColor: 'green.main',
        width: '50px',
        fontSize: '20px',
        height: '50px',
        padding: '0 10px',
        borderRadius: 0,
        margin: 0,

        '&:hover': {
            backgroundColor: 'green.main',
        },

        '&.MuiIconButton-edgeEnd': {
            borderBottomRightRadius: '6px',
            borderTopRightRadius: '6px',
        }
    },
    buttonCancel: {
        backgroundColor: 'white.main',
        color: 'black.main',
        '&:hover': {
            color: 'grey.lightest',
            backgroundColor: 'white.main',
        },
    },
};

export const Group = ({group, onSave, onCancel}) => {
    const translate = useTranslation();

    const [name, setName] = useState(group?.name || '');
    const [showCancel, setShowCancel] = useState(false);

    const showInfo = useSnackbarStore((state) => state.show);

    const showKeyboard = useKeyboardStore((state) => state.show);
    const hideKeyboard = useKeyboardStore((state) => state.hide);

    const updateGroup = useGroupsStore((store) => store.upd);
    const addGroup = useGroupsStore((store) => store.add);

    const onInputFocus = (e, setVal) => {
        setShowCancel(true);
        showKeyboard(getKeyboardOptions(e, setVal));
    };

    const onInputBlur = () => {
        hideKeyboard();
    };

    const onInputChange = (e) => {
        setName(e.target.value);
        setShowCancel(true);
    };

    const doCancel = () => {
        setName(group?.name || '');
        setShowCancel(false);
        onCancel && onCancel();
    };

    const doSave = (ev) => {

        ev.stopPropagation();
        ev.preventDefault();

        if (!name || name === '') {
            showInfo(translate('board.group.validation.name_required'), {severity: 'warning'});
            return;
        }

        if (group === null) {
            const newGroup = {id: uuidv4(), name: name};
            addGroup(newGroup);
            onSave(newGroup);
            showInfo(translate('board.group.action.adding_success'));
        } else {
            const updatedGroup = {...group, name};
            updateGroup(updatedGroup);
            onSave(updatedGroup);
            showInfo(translate('board.group.action.save_success'));
        }

        setName('');
        setShowCancel(false);
    };

    useEffect(() => {
        return () => {
            hideKeyboard();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Stack direction="row">
        <InputBase
            sx={styles.inputBase}
            placeholder={translate('board.group.name')}
            label=""
            value={name}
            autoComplete="off"
            onFocus={(e) => onInputFocus(e, setName)}
            onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
            }}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                    doSave(ev);
                    ev.stopPropagation();
                    ev.preventDefault();
                }
            }}
            onBlur={onInputBlur}
            onChange={onInputChange}
        />
        {showCancel && <IconButton sx={[styles.button, styles.buttonCancel]} onClick={doCancel} color="secondary">
            <CancelIcon/>
        </IconButton>}
        <IconButton sx={styles.button} onClick={doSave} edge={'end'} color="secondary">
            <SaveIcon/>
        </IconButton>
    </Stack>;
};

export default Group;
