import React from 'react';

import {Grid, Stack} from '@mui/material';

const styles = {
    blockContainer: {
        padding: 0,
        margin: '0',
    },
    blockContainerInline: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignSelf: 'flex-start',
        zIndex: 997,
    },
    blockContainerRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '70px',
        marginBottom: '1px',
        fontSize: '16px',
        cursor: 'pointer',
        padding: '8px 0 8px 20px',
        backgroundColor: 'grey.darker',
        color: 'red.main',
    },
};

const NoData = ({text}) => {
    return (
        <Grid sx={styles.blockContainer} item xs={12}>
            <Grid item xs={12} sx={styles.blockContainerInline}>
                <Stack sx={styles.blockContainerRow}>
                    <Stack>{text}</Stack>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default NoData;