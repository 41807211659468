import React, {useState} from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText, DialogTitle,
} from '@mui/material';
import {styled} from '@mui/material/styles';

import Text from './Text';

const styles = {
    title: {
        color: 'white.main'
    },
    contentText: {
        mb: 2
    }
};

const PREFIX = 'MuiDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(({_theme}) => ({
    [`& .${classes.paper}`]: {
        position: 'fixed',
        top: 40
    },
}));

const ConfirmDialog = ({
    open,
    title = 'ui.confirm.title',
    message = 'ui.confirm.message',
    onConfirm,
    onCancel,
}) => {
    const handleKeyPress = (e) => {
        if (e.charCode === 32 || e.charCode === 13) {
            e.preventDefault();
            onConfirm();
        }
    };

    return <StyledDialog open={open} onClose={onCancel} maxWidth={'xs'} fullWidth>
        <DialogTitle sx={styles.title}><Text>{title}</Text></DialogTitle>
        <DialogContent dividers>
            <DialogContentText sx={styles.contentText}>
                <Text>{message}</Text>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={onCancel}
                color="secondary"
                variant="outlined"
                data-testid="cancel-btn"
            >
                <Text>ui.confirm.cancel</Text>
            </Button>
            <Button
                onClick={onConfirm}
                autoFocus
                color="warning"
                onKeyPress={handleKeyPress}
                variant={'contained'}
                data-testid="delete-btn"
                disableElevation
                // positive
                // tabIndex={0}
                // labelPosition='right'
                // icon='checkmark'
                // content='Ja'>
            >
                <Text>ui.confirm.delete</Text>
            </Button>
        </DialogActions>
    </StyledDialog>;
};

export default ConfirmDialog;

export const useConfirmDialog = (onConfirm, title = undefined, message = undefined) => {
    const [isOpen, setIsOpen] = useState(false);
    const [payload, setPayload] = useState(undefined);

    return {
        open: isOpen,
        show: (payload) => {
            setPayload(payload);
            setIsOpen(true);
        },
        hide: () => setIsOpen(false),
        onCancel: () => setIsOpen(false),
        onConfirm: () => {
            onConfirm(payload);
            setIsOpen(false);
        },
        title,
        message,
    };
};
