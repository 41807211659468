import {useCallback, useEffect} from 'react';

import {fabric} from 'fabric';
import {useWindowDimensions} from 'hooks';

import * as backgrounds from '../assets/backgrounds';
import {MAX_CANVAS_OBJECTS} from '../config';
import doLog from '../lib/log';

import {DEFAULT_SIZE} from './Drawer/player-utils';
import {Circle, Clipart, Line, Player, PlayerCircle, Rect, Text, Triangle} from './Drawer';
import {migrate4to5} from './migrations';
import {setScaling} from './object-scale';
import {customControlVisibility} from './useCustomControls';
import {useCanvasStore} from './zustand';

// 0,614379085
// 1,627659574
// export const CANVAS_WIDTH = 940;
// export const CANVAS_HEIGHT = 1530;
export const CANVAS_WIDTH = 1080;
export const CANVAS_HEIGHT = 1758;

export const CANVAS_RATIO = CANVAS_HEIGHT / CANVAS_WIDTH;
export const DRAWING_WIDTH = 5;
export const DEFAULT_COLOR = '#000000';
export const DRAWING_MODE = 'draw';
export const SELECTION_MODE = 'select';

export const setStrokeColor = (canvas, color) => {
    canvas.freeDrawingBrush.color = color;
};

export const setStrokeWidth = (canvas, width) => {
    canvas.freeDrawingBrush.width = width;
};

export const setFillColor = (_canvas, _color) => {
    // do not update selected objects, causes unwanted stroke color change of existing items
    // canvas.getActiveObjects().forEach((o) => {
    //     o.set('fill', color)
    // })
};

export const drawBorder = (obj) => {
    if (obj) {
        obj.hasBorders = true;
        obj.borderColor = '#000';
        obj.padding = 10;
    }
};

export const setLastObjectActive = (canvas) => {
    if (!canvas.isEmpty()) {
        canvas.setActiveObject(canvas.item(canvas.size() - 1));
    }
};

/**
 * set drawing mode and register event handlers for the different drawings
 * @param canvas
 * @param mode
 * @param tool
 * @returns {*}
 */
export const setMode = (canvas, mode, tool) => {
    const width = DRAWING_WIDTH;
    canvas.isDrawingMode = mode === DRAWING_MODE;
    canvas.selection = mode === 'select';
    
    if (mode === 'text') {
        Text({canvas, stroke: tool.color});
    }

    if (mode === 'arrow') {
        Line({canvas, stroke: tool.color, width});
    }

    if (mode === 'arrowDashed') {
        Line({canvas, stroke: tool.color, width, dashed: true});
    }

    if (mode === 'rect') {
        Rect({canvas, stroke: tool.color, width});
    }

    if (mode === 'circle') {
        Circle({canvas, stroke: tool.color, width});
    }

    if (mode === 'triangle') {
        Triangle({canvas, stroke: tool.color, width});
    }

    if (mode === 'clipart') {
        if (tool.name === 'player') {
            Player({canvas, colors: tool.color, size: tool.size || DEFAULT_SIZE, clipart: tool.name});
        } else if (tool.name === 'playerCircle') {
            PlayerCircle({canvas, fill: tool.color, size: tool.size || DEFAULT_SIZE});
        } else {
            Clipart({canvas, fill: tool.color, clipart: tool.name});
        }
    }

    setStrokeColor(canvas, tool?.color || '#000000');
    setFillColor(canvas, '');
    setStrokeWidth(canvas, width);
};

export const clear = (canvas, background) => {
    if (!canvas) {
        return;
    }

    canvas.clear();
    setBackground(canvas, background);
};

export const setBackground = (canvas, background, reset) => {
    const pushCanvas = useCanvasStore.getState().pushCanvas;
    const resetCanvasStates = useCanvasStore.getState().resetCanvasStates;

    if (backgrounds[background]) {
        fabric.Image.fromURL(backgrounds[background], (img) => {
            img.scaleToWidth(CANVAS_WIDTH, true);
            // img.scaleToHeight(canvas.height, true);

            canvas.setBackgroundColor('#191919');
            canvas.setBackgroundImage(img, () => {
                canvas.renderAll();
                if (reset)
                    resetCanvasStates(canvasToJson(canvas));
                else
                    pushCanvas(canvasToJson(canvas));
            });
        });
        return background;
    } else {
        canvas.setBackgroundColor('#ffffff');
        canvas.setBackgroundImage(null, () => {
            canvas.renderAll();
            if (reset)
                resetCanvasStates(canvasToJson(canvas));
            else
                pushCanvas(canvasToJson(canvas));
        });
        
        return null;
    }
};

export const isCustom = (o) => {
    return !!o.custom;
};

export const isCustomType = (o, type) => {
    return o.custom && o.custom.type === type;
};

export const useCalculateDimensions = (canvas, background) => {
    const {width: windowWidth, height: windowHeight} = useWindowDimensions();

    const calculateDimensions = useCallback(
        (wWidth, wHeight) => {
            let newWidth = wWidth;
            let newHeight = newWidth * CANVAS_RATIO;
            let ratio = newWidth / CANVAS_WIDTH;

            if (ratio > 1) {
                return {width: CANVAS_WIDTH, height: CANVAS_HEIGHT};
            }

            doLog(`nw:${newWidth} nh:${newHeight}`);
            // const hhHeight = isMobile ? 150 : 185; // header height + footer on desktop
            const hhHeight = 150;

            if (newHeight + hhHeight > wHeight) {
                const diffH = newHeight + hhHeight - wHeight;
                doLog(`to high: ${diffH}`);

                newHeight = newHeight - diffH;
                newWidth = newHeight / CANVAS_RATIO;
            }

            newHeight = Math.floor(newHeight);
            newWidth = Math.floor(newWidth);

            doLog(`ww:${wWidth} wh:${wHeight} nw:${newWidth} nh:${newHeight} ratio:${ratio}`);

            return {width: newWidth, height: newHeight};
        }, []);

    useEffect(() => {
        if (canvas) {
            try {
                canvas.setDimensions(calculateDimensions(windowWidth, windowHeight));
            } catch (e) {
                // suppressing error so app can reload without setDimensions error
                console.log('suppress reload getting error');
            }

            setBackground(canvas, background);

            const scaling = getZoom(canvas);
            canvas.setZoom(scaling.zoom);
        }
    }, [background, canvas, calculateDimensions, windowWidth, windowHeight]);

    return calculateDimensions(windowWidth, windowHeight);
};

export const getZoom = (canvas) => {
    return {
        zoom: canvas.width / CANVAS_WIDTH,
    };
};

export const limitReached = (canvas) => {
    if (!canvas) {
        return false;
    }

    return canvas.getObjects().length >= MAX_CANVAS_OBJECTS;
};

export const canvasToJson = (canvas) => {
    return canvas.toJSON(['hasControls', 'hasBorders', 'custom']);
};

export const canvasReviver = (json, element, isMobile) => {
    migrate4to5(json, element);
    customControlVisibility(element);
    setScaling(element, isMobile);
};
