import {usePrefsStore} from 'store/prefs';

import {DRAWING_MODE} from './canvas-helper';

const usePrefs = () => {
    const tool = usePrefsStore(state => state.tool);
    const tools = usePrefsStore(state => state.tools);
    const background = usePrefsStore(state => state.background);

    let mode = DRAWING_MODE;

    if (['player', 'playerCircle'].includes(tool)) {
        mode = 'clipart';
    }

    if (['futbol', 'cone', 'coneSmall', 'pole', 'ladder'].includes(tool)) {
        mode = 'clipart';
    }

    if (['draw', 'text', 'select', 'arrow', 'arrowDashed', 'circle', 'rect', 'triangle'].includes(tool)) {
        mode = tool;
    }

    return {mode, tool: tools[tool], background};
};

export default usePrefs;
