import {v4 as uuidv4} from 'uuid';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

export const useGroupsStore = create(persist(middleware((set, get) => ({
    migrationGroupName: 'nicht zugeordnet', // TODO needs translation
    migrationGroupId: 'id_migration_v0_v1_2022',
    selected: null,
    groups: [],
    setSelected: groupId => set(produce(draft => {
        draft.selected = groupId ? groupId : null;
    })),
    addMigrationGroup: group => set(produce(draft => {
        const grp = {id: draft.migrationGroupId, name: draft.migrationGroupName, ...group, lastUpdate: new Date().getTime(), sort: draft.groups.length + 1};
        draft.groups.push(grp);
    })),
    add: group => set(produce(draft => {
        const grp = {id: uuidv4(), ...group, lastUpdate: new Date().getTime(), sort: draft.groups.length + 1};
        draft.groups.push(grp);
        draft.selected = draft.selected ? draft.selected : grp.id;
    })),
    del: groupId => set(produce(draft => {
        const index = draft.groups.findIndex(grp => grp.id === groupId);

        if (index !== -1) {
            draft.groups.splice(index, 1);
        }

        if (draft.selected === groupId) {
            draft.selected = null;

            if (draft.groups.length > 0) {
                draft.selected = draft.groups[draft.groups.length - 1].id;
            }
        }

        // we need to update sort as well
        updateSortField(draft);
    })),
    setAll: groups => set(produce(draft => {
        draft.groups = groups;
        draft.selected = null;
    })),
    delAll: () => set(produce(draft => {
        draft.groups = [];
        draft.selected = null;
    })),
    upd: group => set(produce(draft => {
        const index = draft.groups.findIndex(grp => grp.id === group.id);
        draft.groups.forEach((group, index) => {
            group.sort = index+1;
        }); 
        
        if (index !== -1) {
            draft.groups[index] = {...group, lastUpdate: new Date().getTime()};
        }
    })),
    upsert: group => set(produce(draft => {
        const index = draft.groups.findIndex(grp => grp.id === group.id);

        if (index === -1) {
            draft.groups.push({...group, lastUpdate: new Date().getTime()});
        } else {
            draft.groups[index] = group;
        }
    })),
    sort: (group, direction) => set(produce(draft => {
        try {
            const currentIndex = draft.groups.findIndex(grp => grp.id === group.id);

            const offset = direction === 'up' ? 1 : -1;
            const newSort = group.sort + offset;
            const currentSort = group.sort;
            const nextElementIndex = draft.groups.findIndex(grp => grp.sort === newSort);

            draft.groups[currentIndex].sort = newSort;
            draft.groups[nextElementIndex].sort = currentSort;
        }
        catch(ex) {
            console.warn(`sort field not exist adding it`);
            // try to add sort field
            addSortField(draft);
        }
    })),
    get: id => {
        const index = get().groups.findIndex(grp => grp.id === id);

        if (index === -1) {
            return null;
        }

        return get().groups[index];
    },
    getByName: name => {
        const index = get().groups.findIndex(grp => grp.name === name);

        if (index === -1) {
            return null;
        }

        return get().groups[index];
    }
})), 
// ...
{
    name: 'groups', // unique name
    version: 1, /** NOTE: make sure migrations length should be version NO. + 1 */
    migrate: (persistedState, previousVersion) => {
        // Migrate store here...
        const migrations = [
            // version 0 
            // to make sure we align with logic just add this version as array filler
            (state) => state, // mostly will not use just for filler
            // version 1
            // add sorting field
            (state) => addSortField(state),
        ];

        let state = persistedState;

        for (let i = previousVersion + 1; i < migrations.length; i++) {
            state = migrations[i](state);
        }

        return state;
    }}
));

const addSortField = (state) => {
    state.groups.forEach((group, index) => {
        group.sort = index+1;
    }); 
    return state;
};

const updateSortField = (state) => {
    const sortedGroup = state.groups.slice().sort((a, b) => a.sort - b.sort);
    state.groups = sortedGroup;
    // // rearrange numbers : so all groups can be 1 2 3 ...
    addSortField(state);
    return state;
};
