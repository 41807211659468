import React from 'react';

import {IconButton, useMediaQuery, useTheme} from '@mui/material';

const ActionButton = ({children, backgroundColor = undefined, ...rest}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const size = isMobile ? 'small' : 'large';
    return (
        <IconButton
            data-testid="action-btn"
            size={size}
            sx={{backgroundColor, borderRadius: 2}}
            {...rest}
        >
            {children}
        </IconButton>
    );
};

export default ActionButton;
