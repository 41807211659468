import React, {useRef, useState} from 'react';

import {
    FormatBold as FormatBoldIcon,
    FormatClear as FormatClearIcon,
    FormatItalic as FormatItalicIcon,
    // TextDecrease as TextDecreaseIcon,
    // FormatStrikethrough as FormatStrikethroughIcon,
    // FormatAlignLeft as FormatAlignLeftIcon,
    // FormatAlignCenter as FormatAlignCenterIcon,
    // FormatAlignRight as FormatAlignRightIcon,
    // TextIncrease as TextIncreaseIcon,
} from '@mui/icons-material';
import {
    // ButtonGroup,
    // Divider,
    Grid,
    // IconButton,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography} from '@mui/material';

import {getFormats,getTextContent, setTextContent} from 'canvas/Drawer/text-utils';
import {getKeyboardOptions, Text} from 'components';
import {useKeyboardStore} from 'store';

const styles = {

    stackStyle: {
        backgroundColor: 'grey.dark',
        borderRadius: '6px',
        margin: '0 10px 10px 10px',
    },

    inputContainer: {
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '10px',
    },

    toggleButton: theme => ({
        border: 'none',
        padding: '12px',
        color: 'white.main',

        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },

        [theme.breakpoints.up('sm')]: {
            padding: '12px',
        },
    })
};

const Edit = ({text}) => {

    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const inputRef = useRef();
    const [content, setContent] = useState(getTextContent(text));
    const [formats] = useState(getFormats(text));
    // const [textAlign, setTextAlign] = useState(text.textAlign);

    const showKeyboard = useKeyboardStore(state => state.show);

    const updateText = (value) => {
        setContent(value);
        setTextContent(text, value);
    };

    const onInputFocus = (e, setVal) => {
        const rect = e.target.getBoundingClientRect();
        showKeyboard(getKeyboardOptions(e, setVal, {hideOnEnter: false, top: rect.top + rect.height + 20}));
    };

    const handleSelect = (_e) => {
        const target = _e.target;
        text.setSelectionStart(target.selectionStart);
        text.setSelectionEnd(target.selectionEnd);
        // text.canvas.renderAll();
    };

    const formatChanged = (_e, formats) => {
        if (formats.includes('clear')) {
            text.styles = {0: {}};
        }

        // if text selected
        if (text.selectionStart !== text.selectionEnd) {
            let lineStyleObj = Object.keys(text.styles).length ? text.styles : {0: {}};

            for (let i = text.selectionStart; i < text.selectionEnd; i++) {
                lineStyleObj[0][i] = {};
                lineStyleObj[0][i].fontWeight = formats.includes('bold') ? 'bold' : 'normal' ;
                lineStyleObj[0][i].fontStyle = formats.includes('italic') ? 'italic' : 'normal' ;
                lineStyleObj[0][i].linethrough = formats.includes('linethrough');
            }

            text.styles = lineStyleObj;
        }
        else {
            text.fontWeight = formats.includes('bold') ? 'bold' : 'normal';
            text.fontStyle = formats.includes('italic') ? 'italic' : 'normal';
            text.linethrough = formats.includes('linethrough');
        }

        text.canvas.renderAll();
        // setFormats(formats);
    };

    // const textAlignChanged = (_e, textAlign) => {
    //     text.textAlign = textAlign;
    //     text.canvas.renderAll();
    //     setTextAlign(textAlign);
    // };

    /*const changeFontSize = (delta) => {
        if (delta < 0 && text.fontSize <= 20) {
            return;
        }

        if (delta > 0 && text.fontSize > 60) {
            return;
        }

        // text.setSelectionStyles({
        //     fontSize: text.fontSize + delta,
        // });
        text.fontSize = text.fontSize + delta;
        text.canvas.renderAll();
    };*/

    return <Grid container direction={'column'} spacing={0}>
        <Grid item>

            <Stack
                direction="row"
                sx={[styles.stackStyle, {'& .Mui-selected': {backgroundColor: 'rgba(229, 229, 229, 1)'}}, {'& .MuiToggleButton-root:hover': {backgroundColor: 'rgba(229, 229, 229, 1)'}}, {'& .MuiGrid-root': {border: 'none'}}]}>

                <ToggleButtonGroup value={formats} onChange={formatChanged}>
                    <ToggleButton sx={styles.toggleButton} value="clear"><FormatClearIcon/></ToggleButton>
                    <ToggleButton sx={styles.toggleButton} value="bold"><FormatBoldIcon/></ToggleButton>
                    <ToggleButton sx={styles.toggleButton} value="italic"><FormatItalicIcon/></ToggleButton>
                    {/*<ToggleButton sx={styles.toggleButton} value="linethrough"><FormatStrikethroughIcon/></ToggleButton>*/}
                </ToggleButtonGroup>

                {/*
                <Divider flexItem orientation="vertical" sx={{mx: 1, my: 1, borderColor: ''}}/>

                <ToggleButtonGroup sx={styles.toggleButtonGroup} value={formats} onChange={textAlignChanged}>
                    <ToggleButton sx={styles.toggleButton} value="left"><FormatAlignLeftIcon/></ToggleButton>
                    <ToggleButton sx={styles.toggleButton} value="center"><FormatAlignCenterIcon/></ToggleButton>
                    <ToggleButton sx={styles.toggleButton} value="right"><FormatAlignRightIcon/></ToggleButton>
                </ToggleButtonGroup>

                <ButtonGroup sx={styles.toggleButtonGroup}>
                    <IconButton
                        sx={styles.toggleButton}
                        onClick={() => changeFontSize(+4)}><TextIncreaseIcon/></IconButton>
                    <IconButton
                        sx={styles.toggleButton}
                        onClick={() => changeFontSize(-4)}><TextDecreaseIcon/></IconButton>
                </ButtonGroup>
                */}
            </Stack>
        </Grid>

        <Grid item sx={styles.inputContainer}>

            <Typography paddingBottom={1} paddingLeft={'6px'} color="white.main" fontWeight={'400'} fontSize={13}>
                <Text>board.layout.your_text</Text>:</Typography>
            <TextField
                name="content"
                value={content}
                inputRef={inputRef}
                inputProps={{
                    sx: [
                        formats.includes('bold') && {fontWeight: 'bold'},
                        formats.includes('italic') && {fontStyle: 'italic'},
                    ]
                }}
                multiline
                rows={4}
                fullWidth
                autoComplete="off"
                autoFocus
                onChange={e => updateText(e.target.value)}
                onFocus={e => {
                    setTimeout(() => {
                        onInputFocus(e, updateText);
                    }, 100);
                }}
                onSelect={handleSelect}
            />
        </Grid>
    </Grid>;
};

export default Edit;
