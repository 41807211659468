// import {ReactComponent as ConeSmall} from '../cliparts/cone-small.svg';
// import {ReactComponent as Ladder} from '../cliparts/ladder.svg';
// import {ReactComponent as Pole} from '../cliparts/pole.svg';

// import {ReactComponent as Arrow} from './arrow.svg';
// import {ReactComponent as ArrowDashed} from './arrow-dashed.svg';
import {ArrowDownward, ArrowUpward,FileCopy, FirstPage, Image, Language, MoreHoriz as Menu, Redo, Timeline, Undo} from '@mui/icons-material';

import {ReactComponent as GeomixX} from '../icons/geomix-x.svg';
import {ReactComponent as Arrow} from '../icons/icon_arrow.svg';
import {ReactComponent as ArrowDashed} from '../icons/icon_arrow_dashed.svg';
import {ReactComponent as Circle} from '../icons/icon_circle.svg';
import {ReactComponent as CircleFull} from '../icons/icon_circle_full.svg';
import {ReactComponent as ClearField} from '../icons/icon_clear_field.svg';
import {ReactComponent as ConeLarge} from '../icons/icon_cone_large.svg';
import {ReactComponent as ConeSmall} from '../icons/icon_cone_small.svg';
import {ReactComponent as CopyPaste} from '../icons/icon_copy_paste.svg';
import {ReactComponent as Field} from '../icons/icon_field.svg';
import {ReactComponent as Background} from '../icons/icon_field_select.svg';
import {ReactComponent as Info} from '../icons/icon_info.svg';
import {ReactComponent as Ladder} from '../icons/icon_ladder.svg';
import {ReactComponent as OpenTheme} from '../icons/icon_open_theme.svg';
import {ReactComponent as Pencil} from '../icons/icon_pencil.svg';
import {ReactComponent as Pointer} from '../icons/icon_pointer.svg';
import {ReactComponent as Pole} from '../icons/icon_pole.svg';
import {ReactComponent as Rectangle} from '../icons/icon_rectangle.svg';
import {ReactComponent as Reload} from '../icons/icon_reload.svg';
import {ReactComponent as Save} from '../icons/icon_save.svg';
import {ReactComponent as SelfTest} from '../icons/icon_selftest.svg';
import {ReactComponent as Settings} from '../icons/icon_settings.svg';
import {ReactComponent as SoccerBall} from '../icons/icon_soccerball.svg';
import {ReactComponent as Sync} from '../icons/icon_sync.svg';
import {ReactComponent as Text} from '../icons/icon_text.svg';
import {ReactComponent as Tools} from '../icons/icon_tools.svg';
import {ReactComponent as Triangle} from '../icons/icon_triangle.svg';
import {ReactComponent as Tshirt} from '../icons/icon_tshirt.svg';

const Icons = {
    ArrowDashed,
    Arrow,
    Background,
    GeomixX,
    ConeSmall,
    ConeLarge,
    SoccerBall,
    OpenTheme,
    ClearField,
    CopyPaste,
    Pole,
    Ladder,
    CircleFull,
    Circle,
    Field,
    Info,
    Pencil,
    Pointer,
    Rectangle,
    Reload,
    Save,
    SelfTest,
    Settings,
    Sync,
    Text,
    Tools,
    Triangle,
    Tshirt,
    // MUI re-exports
    Menu,
    Timeline,
    Undo,
    Redo,
    FirstPage,
    FileCopy,
    Image,
    Language,
    ArrowDownward,
    ArrowUpward
};

export default Icons;
