import React from 'react';

import {Check as CloseIcon} from '@mui/icons-material';
import {DialogContent} from '@mui/material';

import {useCanvasStore} from 'canvas/zustand';
import {Dialog} from 'components';
import {useTranslation} from 'hooks';
import {useKeyboardStore} from 'store';

import Edit from './Edit';
import {useTextStore} from './zustand';

const styles = {
    container: {
        padding: 0,
        borderTop: 0,
        borderBottom: 0,
    },
};

const TextDialog = () => {
    const translate = useTranslation();

    const text = useTextStore(state => state.text);
    const unsetText = useTextStore(state => state.unset);

    const canvas = useCanvasStore(state => state.canvas);

    const hideKeyboard = useKeyboardStore(state => state.hide);

    const doClose = () => {
        hideKeyboard();

        if (text.text === '') {
            canvas && canvas.remove(text);
        }

        unsetText();
    };

    return <Dialog
        open={!!text} handleClose={doClose} title={translate('board.layout.text_editor')}
        Icon={<CloseIcon/>}>
        <DialogContent sx={styles.container} dividers>
            {text && <Edit text={text}/>}
        </DialogContent>
    </Dialog>;
};

export default TextDialog;
