import {useEffect} from 'react';

import {useMediaQuery, useTheme} from '@mui/material';

import {limitReached} from 'canvas/canvas-helper';
import {useCanvasStore} from 'canvas/zustand';
import {useTranslation} from 'hooks';
import {usePrefsStore, useSnackbarStore} from 'store';

import {useSheetStore} from './zustand';

const Switcher = () => {
    const translate = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const setBackground = usePrefsStore((state) => state.setBackground);
    const setShowTimeline = usePrefsStore((state) => state.setShowTimeline);

    const selected = useSheetStore((state) => state.selected);
    const getSheet = useSheetStore((state) => state.get);

    const loadCanvas = useCanvasStore((state) => state.load);

    const showInfo = useSnackbarStore(state => state.show);

    useEffect(() => {
        if (selected) {
            const sheet = getSheet(selected);

            if (!sheet) {
                return;
            }

            const data = loadCanvas(sheet.id, {isMobile});

            if (typeof data === 'object' && data !== null) {
                setBackground(data.background);

                if (limitReached(data.canvas)) {
                    showInfo(translate('board.layout.validation.max_items'), {severity: 'warning'});
                }
            }

            setShowTimeline(sheet?.frames?.length > 1);
        }
    }, [selected, setBackground, loadCanvas, isMobile, getSheet, setShowTimeline, showInfo, translate]);
};

export default Switcher;
