import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import SettingsIcon from '@mui/icons-material/Settings';
import {Avatar, Box, Slider,Stack, Tab, Tabs,Typography, useMediaQuery, useTheme} from '@mui/material';
import {Button as MuiButton} from '@mui/material';

import Icons from 'assets/icons';
import {DEFAULT_SIZE, sizeOptions} from 'canvas/Drawer/player-utils';
import {Text} from 'components';
import {useTranslation} from 'hooks';
import {useSettingsStore} from 'module/settings/zustand';
import {useInfoStore,usePrefsStore} from 'store';

import {Button} from './index';

const styles = {
    options: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    groupContainer: {
        marginBottom: '20px',
        position: 'relative',
        width: '100%',
    },
    boxContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 0,
        gap: '10px',
        paddingTop: '10px',
    },
    boxContainerLast: {
        marginBottom: 0,
    },
    boxContainerSize: {
        marginBottom: 0,
    },
    optionsSubtitles: {
        color: 'white.main',
        margin: '0 0 6px 0',
        fontSize: 16,
        width: '100%',
    },
    tricotBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        WebkitUserSelect: 'none !important',
        borderRadius: '5px',
        width: {
            mobile: 70,
            desktop: 84,
        },
        height: {
            mobile: 70,
            desktop: 84,
        },
        opacity: 0.95,
        margin: {
            //mobile: 0.25,
            //desktop: 0.25,
        },
        position: 'relative',
        //padding: 0.25,
        transition: 'all 0.25s ease-in-out',
        backgroundColor: 'grey.light',
        border: '2px solid',
        borderColor: 'grey.lightest',
    },
    tricotBoxActive: {
        border: '2px solid',
        borderColor: 'white.main',
        overflow: 'hidden',
    },
    sizeSelection: {
        display: 'flex',
        width: '100%',
        padding: '0 12px',
    },
    editButton: {
        display: 'inline-flex',
        textTransform: 'none',
        fontSize: '15px',
        color: '#f0f0f0',
        padding: '10px 14px',
        minWidth: 0,
        minHeight: 0,

        '&:hover': {
            background: '#0f0f0f',
        }
    },
    tab: {
        fontSize: '16px',
        fontWeight: '400',
        textTransform: 'none',
        flexDirection: 'row',
        padding: '10px 14px',
        minWidth: 0,
    },
};

const circleColors = [
    {
        name: 'black-white',
        value1: '#000000',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'red-white',
        value1: '#ff0000',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'yellow-black',
        value1: '#ffff00',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'lime-white',
        value1: '#00ff00',
        value2: '#ffffff',
        textColor: '#000000',
    },
    {
        name: 'green-white',
        value1: '#008000',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'aqua-white',
        value1: '#00ffff',
        value2: '#ffffff',
        textColor: '#000000',
    },
    {
        name: 'blue-white',
        value1: '#0000ff',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    // {
    //     name: 'darkorange-white',
    //     value1: '#ff8c00',
    //     value2: '#ffffff'
    // },
    {
        name: 'darkorange-black',
        value1: '#ff8c00',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'violet',
        value1: '#421477',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'white-black',
        value1: '#ffffff',
        value2: '#000000',
        textColor: '#000000',
    }
];

export const tricotColors = [
    {
        name: 'black-white',
        value1: '#000000',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'red-white',
        value1: '#ff0000',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'red-black',
        value1: '#ff0000',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'yellow-black',
        value1: '#ffff00',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'lime-white',
        value1: '#00ff00',
        value2: '#ffffff',
        textColor: '#000000',
    },
    {
        name: 'green-white',
        value1: '#008000',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'green-black',
        value1: '#008000',
        value2: '#000000',
        textColor: '#ffffff',
    },
    {
        name: 'aqua-white',
        value1: '#00ffff',
        value2: '#ffffff',
        textColor: '#000000',
    },
    {
        name: 'aqua-black',
        value1: '#00ffff',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'blue-white',
        value1: '#0000ff',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    // {
    //     name: 'darkorange-white',
    //     value1: '#ff8c00',
    //     value2: '#ffffff'
    // },
    {
        name: 'darkorange-black',
        value1: '#ff8c00',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'violet',
        value1: '#421477',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'white-black',
        value1: '#ffffff',
        value2: '#000000',
        textColor: '#000000',
    },
];

const TabPanel = (props) => {
    const {children, tabValue, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={tabValue !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {tabValue === index && (
                <Box sx={{p: 0}}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const TricotPicker = ({onClick, name1, name2}) => {
    // name1 = player
    // name2 = playerCircle
    const translate = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
    const tools = usePrefsStore((state) => state.tools);
    const selectedTool = usePrefsStore((state) => state.tool);
    const prefs = usePrefsStore(state => state.tools);

    const getTeamTricotColors = useSettingsStore((state) => state.getTeamTricotColors);
    const setSettingsActive = useSettingsStore(state => state.setActive);
    const setQuicklink = useSettingsStore(state => state.setQuicklink);
    const settingsTricotColors = getTeamTricotColors();

    const setShowSettings = usePrefsStore((state) => state.setShowSettings);

    // set defaults on load as it may be empty
    useEffect(() => {
        if(!(name1 in prefs) || !(name2 in prefs)) {
            onClick(name2, 'size', DEFAULT_SIZE);
            onClick(name1, 'size', DEFAULT_SIZE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const logo = useInfoStore((store) => store.logo);

    let tricotTabId = 1;
    let magnetTabId = 2;

    if(!settingsTricotColors?.value1 && !settingsTricotColors?.value2) {
        tricotTabId = 0;
        magnetTabId = 1;
    }

    return <Box sx={styles.options}>

        <Box sx={styles.groupContainer}>

            <Stack direction="row" justifyContent="space-between">
                
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="scrollable"
                    scrollButtons={false}
                >
                    {settingsTricotColors?.value1 && settingsTricotColors?.value2 && <Tab sx={styles.tab} icon={<Avatar src={logo} />} />}
                    <Tab sx={styles.tab} label={translate('board.toolbar.shirts')}/>
                    <Tab sx={styles.tab} label={translate('board.toolbar.magnets')}/>
                </Tabs>

                {/* Teameinstellungs-Button */}
                {settingsTricotColors?.value1 && settingsTricotColors?.value2 &&
                <MuiButton
                    sx={styles.editButton}
                    onClick={() => {
                        //console.log('go to settings', e);  
                        setShowSettings(true);
                        setQuicklink(true);
                    }}
                >
                    <SettingsIcon />
                </MuiButton>}
            </Stack>

            {settingsTricotColors?.value1 && settingsTricotColors?.value2 &&
                <TabPanel tabValue={tabValue} index={0}>
                    
                    <Box sx={[styles.boxContainer, {paddingTop: '15px'}]}>
                        <Box
                            sx={[
                                styles.tricotBox,
                                (selectedTool === name1 && tools[name1].color?.name === settingsTricotColors.name) && styles.tricotBoxActive,
                                {
                                    '.fa-secondary': {fill: settingsTricotColors.value1, opacity:1},
                                    '.fa-primary': {fill: settingsTricotColors.value2, opacity:1}
                                }
                            ]}
                        >
                            <Button
                                style={selectedTool === name1 && (tools[name1].color?.name === settingsTricotColors.name) ? {backgroundColor: 'grey.darker'} : {}}
                                svg={Icons.Tshirt}
                                name={settingsTricotColors.name}
                                iconStyle={{
                                    width: isMobile ? 20 : 32,
                                    height: isMobile ? 20 : 32,
                                }}
                                value="transparent"
                                onClick={(e) => {
                                    setSettingsActive(true);
                                    onClick(name1, 'color', settingsTricotColors, e);
                                    onClick(name1, 'size', selectedTool.size, e);
                                }}
                                onSelected={(e) => {
                                    onClick(name1, 'color', settingsTricotColors, e);
                                    onClick(name1, 'size', selectedTool.size, e);
                                }}
                            />
                        </Box>
                        <Box
                            sx={[
                                styles.tricotBox,
                                selectedTool === name2 && tools[name2].color === settingsTricotColors.value1 && styles.tricotBoxActive]}>
                            <Button
                                style={ (selectedTool === name2 && tools[name2].color === settingsTricotColors.value1) ? {backgroundColor: 'grey.darker'} : {}}
                                iconColor={settingsTricotColors.value1}
                                svg={Icons.CircleFull}
                                name={settingsTricotColors.name}
                                value="transparent"
                                onClick={(e) => {
                                    setSettingsActive(true);
                                    onClick(name2, 'color', settingsTricotColors.value1, e);
                                    onClick(name2, 'size', selectedTool.size, e);
                                }}
                            />
                        </Box>
                    </Box>

                </TabPanel>}

            {/* Trikots */}
            <TabPanel tabValue={tabValue} index={tricotTabId}>
                <Box sx={[styles.boxContainer, {paddingTop: '15px'}]}>
                    {tricotColors.map((color, idx) => (
                        <Box
                            key={color.name}
                            sx={[
                                styles.tricotBox,
                                (selectedTool === name1 && tools[name1].color?.name === color.name) && styles.tricotBoxActive,
                                {
                                    '.fa-secondary': {fill: color.value1, opacity:1},
                                    '.fa-primary': {fill: color.value2, opacity:1}
                                }
                            ]}
                        >
                            <Button
                                style={selectedTool === name1 && (tools[name1].color?.name === color.name) ? {backgroundColor: 'grey.darker'} : {}}
                                key={idx}
                                svg={Icons.Tshirt}
                                name={color.name}
                                iconStyle={{
                                    width: isMobile ? 20 : 32,
                                    height: isMobile ? 20 : 32,
                                }}
                                value="transparent"
                                onClick={(e) => {
                                    setSettingsActive(false);
                                    onClick(name1, 'color', color, e);
                                    onClick(name1, 'size', selectedTool.size, e);
                                }}
                                onSelected={(e) => {
                                    onClick(name1, 'color', color, e);
                                    onClick(name1, 'size', selectedTool.size, e);
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </TabPanel>

            {/* Magnete */}
            <TabPanel tabValue={tabValue} index={magnetTabId}>
                <Box sx={[styles.boxContainer, styles.boxContainerLast, {paddingTop: '15px'}]}>
                    {circleColors.map((obj) => ({...obj, name: obj.name + '_circle', value: obj.value1})).map((color, idx) => (
                        <Box
                            key={color.name} sx={[
                                styles.tricotBox,
                                selectedTool === name2 && tools[name2].color === color.value && styles.tricotBoxActive,
                                {
                                    '.st0': {fill: color.value1, stroke: '#797979', strokeWidth: 3, opacity:1},
                                }]}>
                            <Button
                                style={ (selectedTool === name2 && tools[name2].color?.name === color.name) ? {backgroundColor: 'grey.darker'} : {}}
                                iconColor={color.value}
                                key={idx + 'a'}
                                svg={Icons.CircleFull}
                                name={color.name}
                                value="transparent"
                                onClick={(e) => {
                                    setSettingsActive(false);
                                    onClick(name2, 'color', color.value, e);
                                    onClick(name2, 'size', selectedTool.size, e);
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </TabPanel>

        </Box>

        {/* Größe der Trikots/Masgnete */}
        <Box sx={[styles.boxContainer, styles.boxContainerSize]}>
            <Typography sx={styles.optionsSubtitles}>
                <Text>board.toolbar.size</Text>:
            </Typography>
            <Box sx={styles.sizeSelection}>
                <Slider
                    size="small"
                    color="secondary"
                    defaultValue={DEFAULT_SIZE}
                    value={tools[selectedTool].size || DEFAULT_SIZE}
                    step={1}
                    onChange={(e, v) => {
                        onClick(selectedTool, 'size', v, e);
                        onClick(selectedTool, 'size', v, e);
                    }}
                    valueLabelFormat={value => sizeOptions[value].label}
                    marks
                    min={1}
                    max={Object.values(sizeOptions).length}
                    valueLabelDisplay="on"
                />
            </Box>
        </Box>

    </Box>;
};

TricotPicker.propTypes = {
    onClick: PropTypes.func,
    name1: PropTypes.string,
    name2: PropTypes.string,
};

const tricotMainColors = () => {
    let ret = [];
    tricotColors.forEach((c) => {
        if (!ret.find((e) => e.value === c.value1)) {
            ret.push({name: c.name, value: c.value1, textColor: c.textColor});
        }
    });
    return ret;
};

export default TricotPicker;

export {tricotMainColors};
