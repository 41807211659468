import React from 'react';
import PropTypes from 'prop-types';

import {Box} from '@mui/material';

import Button from './Button';

const styles = {
    options: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
    },
    colorBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        WebkitUserSelect: 'none !important',
        width: {
            mobile: 60,
            desktop: 74,
        },
        height: {
            mobile: 60,
            desktop: 74,
        },
        opacity: 0.95,
        margin: {
            mobile: 0.25,
            desktop: 0.25,
        },
        position: 'relative',
        padding: 0,
        transition: 'all 0.25s ease-in-out',
        backgroundColor: 'black.main',
        borderRadius: {
            mobile: 30,
            desktop: 37,
        },
        border:'3px solid',
        borderColor: 'grey.dark'
    },
    colorBoxActive: {
        border: '3px solid',
        borderColor: 'white.main'
    },
    colorSquare: {
        width: {
            mobile: 54,
            desktop: 68,
        },
        height: {
            mobile: 54,
            desktop: 68,
        },
        overflow: 'hidden',
        borderRadius: {
            mobile: 27,
            desktop: 34,
        },

    },
};

export const defaultColors = [
    {
        name: 'white',
        value: '#ffffff',
    },
    {
        name: 'black',
        value: '#000000',
    },
    {
        name: 'red',
        value: '#cc0000',
    },
    {
        name: 'blue',
        value: '#0042bd',
    },
];

const ColorPicker = ({
    stroke,
    onSelect,
    colors,
    colorBg,
    svg,
    selectedColor,
    boxed,
}) => {

    if (!colors) {
        colors = defaultColors;
    }

    return (
        <Box sx={styles.options}>
            {colors.map((color, idx) => (
                <Color
                    key={`color-${idx}`}
                    name={color.name}
                    value={color.value}
                    colorBg={colorBg}
                    svg={svg}
                    onClick={() => onSelect('color', color)}
                    selected={stroke === color.value}
                    isSelected={selectedColor === color.value}
                    boxed={boxed}
                />
            ))}
        </Box>
    );
};

ColorPicker.propTypes = {
    stroke: PropTypes.string,
    colors: PropTypes.array,
    onSelect: PropTypes.func,
    tool: PropTypes.object,
};

const Color = ({
    name,
    svg,
    value,
    onClick,
    colorBg,
    isSelected,
    boxed,
}) => {
    const val = value || name;
    return <Box
        sx={[
            styles.colorBox,
            isSelected && styles.colorBoxActive
        ]}
        onClick={(e) => onClick(name, value, e)}
        className={isSelected && boxed ? 'outerSelectedSquare' : (isSelected ? 'outerSelectedCircle' : '')}
    >
        <Box
            sx={styles.colorSquare}
            className={isSelected && boxed ? 'selectedSquare' : (isSelected ? 'selectedCircle' : '')}
        >
            <Button
                svg={svg}
                iconColor={val}
                colorBg={colorBg}
                value={value}
                name={name}
                style={boxed ? {margin: '0'} : {}}
            />
        </Box>
    </Box>;
};

Color.propTypes = {
    name: PropTypes.string.isRequired,
    svg: PropTypes.any,
    value: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    colorBg: PropTypes.bool,
    isSelected: PropTypes.bool,
    boxed: PropTypes.bool
};

Color.defaultProps = {
    selected: false,
};

export default ColorPicker;
