import React, {useState} from 'react';

import {
    Button,
    MenuItem,
    Stack,
} from '@mui/material';

import {sizeOptions} from 'canvas/Drawer/player-utils';
import {Text} from 'components';
import {useUpdateRemoteTeam} from 'module/settings/useSettings';
import {useSettingsStore} from 'module/settings/zustand';

import {Form, FormField, SelectField, useForm} from 'form';
import {MuiColorInput} from 'mui-color-input';

const styles = {
    formContainer: {
        paddingBottom: 1,

    },
    field: {
        maxWidth: {
            desktop: '49%'
        },
        marginBottom: {
            desktop: 3,
            mobile: 2
        }
    },
    actions: {
        paddingBottom: 2,
    },
};

const Detail = ({team, onSelect /*,onPlayers */}) => {
    const [errors, setErrors] = useState({});
    const updateTeam = useSettingsStore((store) => store.updateTeam);

    const updateRemoteTeam = useUpdateRemoteTeam();

    const rhf = useForm(team, errors);

    const onSubmit = async (formData) => {
        formData._id = team._id;
        formData.name = team.name;
        const res = await updateRemoteTeam(formData);

        if (res.error) {
            setErrors(res.error.errors);
        } else {
            updateTeam(res.data);
            // save triggers team selection
            onSelect(formData);
        }
    };

    return <Form {...rhf} onSubmit={onSubmit}>
        <Stack
            sx={styles.formContainer}
            alignItems="center"
            flexWrap="wrap"
            justifyContent="space-between"
            direction={{mobile: 'column', desktop: 'row'}}
        >
            <SelectField label="team.prefs.direction" name="prefs.direction" sx={styles.field}>
                <MenuItem value={'home'}><Text>team.prefs.direction_home</Text></MenuItem>
                <MenuItem value={'guest'}><Text>team.prefs.direction_guest</Text></MenuItem>
            </SelectField>
            <SelectField label="team.prefs.player.display.display" name="prefs.player.type" sx={styles.field}>
                <MenuItem value={'player'}><Text>team.prefs.player.display.shirts</Text></MenuItem>
                <MenuItem value={'playerCircle'}><Text>team.prefs.player.display.magnets</Text></MenuItem>
            </SelectField>
            <FormField label="team.prefs.player.color1" as={MuiColorInput} format="hex" name="prefs.player.color1" sx={styles.field}/>
            <FormField label="team.prefs.player.color2" as={MuiColorInput} format="hex" name="prefs.player.color2" sx={styles.field}/>
            <SelectField label="team.prefs.player.display.below.display" name="prefs.player.display.name" sx={styles.field}>
                <MenuItem value={'number'}><Text>team.prefs.player.display.below.number</Text></MenuItem>
                <MenuItem value={'initials'}><Text>team.prefs.player.display.below.initials</Text></MenuItem>
                <MenuItem value={'name'}><Text>team.prefs.player.display.below.name</Text></MenuItem>
            </SelectField>
            <SelectField label="team.prefs.player.image" name="prefs.player.image" sx={styles.field}>
                <MenuItem value={true}><Text>global.yes</Text></MenuItem>
                <MenuItem value={false}><Text>global.no</Text></MenuItem>
            </SelectField>
            <SelectField label="team.prefs.player.count" name="prefs.player.count" sx={styles.field}>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
            </SelectField>
            <SelectField label="team.prefs.player.size" name="prefs.player.size" sx={styles.field}>
                {Object.keys(sizeOptions).map((key, index) =>
                    <MenuItem
                        key={key}
                        value={sizeOptions[index + 1].label}>{sizeOptions[index + 1].label}</MenuItem>
                )}
            </SelectField>
            <SelectField label="team.prefs.player.display.on.display" name="prefs.player.display.number" sx={styles.field}>
                <MenuItem value={'number'}><Text>team.prefs.player.display.on.number</Text></MenuItem>
                <MenuItem value={'initials'}><Text>team.prefs.player.display.on.initials</Text></MenuItem>
                <MenuItem value={'name'}><Text>team.prefs.player.display.on.name</Text></MenuItem>
            </SelectField>
        </Stack>

        {rhf.methods.formState.isDirty &&
            <Stack sx={styles.actions} alignItems="flex-start">
                <Button
                    variant="contained"
                    color="secondary"
                    type="submit">
                    <Text>ui.actions.save</Text>
                </Button>
            </Stack>}
    </Form>;
};

export default Detail;
