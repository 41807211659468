import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import {middleware} from 'store/zustand';

import {produce} from 'immer';
import _ from 'lodash';

export const usePrefsStore = create(
    persist(
        middleware((set, get) => ({
            selectedMenuItem: 'Themen',
            tool: 'draw',
            tools: {},
            color: '',
            lineupIndex: 0,
            homeTeam: true,
            background: 'soccer',
            showTimeline: false,
            showSettings: false,
            showDrawer: false,
            showHelp: false,
            showInformationDialog: false,
            showMenu: false,
            showTeams: false,
            showPlayers: false,
            showClients: false,
            showLanguage: false,
            setTool: (tool, options = null) => set(produce((draft) => {
                if (typeof options === 'object') {
                    draft.tools[tool] = _.merge(draft.tools[tool], {
                        ...options,
                        name: tool,
                    });
                }

                draft.tool = tool;
            })),
            setBackground: (background) => set(produce((draft) => {
                draft.background = background;
            })),
            getColor: (tool) => {
                return get().tools[tool]?.color ? get().tools[tool].color : '#000000';
            },
            setColor: (tool, color) =>
                set(
                    produce((draft) => {
                        draft.tools[tool] = _.merge(draft.tools[tool], {
                            color: color,
                            name: tool,
                        });

                        draft.tool = tool;
                    })
                ),
            setShowTimeline: (val) =>
                set(
                    produce((draft) => {
                        draft.showTimeline = val;
                    })
                ),
            setShowDrawer: (val) =>
                set(
                    produce((draft) => {
                        draft.showDrawer = val;
                    })
                ),
            setShowSettings: (val) =>
                set(
                    produce((draft) => {
                        draft.showSettings = val;
                    })
                ),
            setShowTeams: (val) =>
                set(
                    produce((draft) => {
                        draft.showTeams = val;
                    })
                ),
            setShowPlayers: (val) =>
                set(
                    produce((draft) => {
                        draft.showPlayers = val;
                    })
                ),
            setShowClients: (val) =>
                set(
                    produce((draft) => {
                        draft.showClients = val;
                    })
                ),
            setShowMenu: (val) =>
                set(
                    produce((draft) => {
                        draft.showMenu = val;
                    })
                ),
            setShowLanguage: (val) =>
                set(
                    produce((draft) => {
                        draft.showLanguage = val;
                    })
                ),
            setShowHelp: (val) =>
                set(
                    produce((draft) => {
                        draft.showHelp = val;
                    })
                ),
            setSelectedMenuItem: (val) =>
                set(
                    produce((draft) => {
                        draft.selectedMenuItem = val;
                    })
                ),
            setShowInformationDialog: (val) =>
                set(
                    produce((draft) => {
                        draft.showInformationDialog = val;
                    })
                ),
            setLineupIndex: (val) =>
                set(
                    produce((draft) => {
                        draft.lineupIndex = val;
                    })
                ),
            setHomeTeam: (val) =>
                set(
                    produce((draft) => {
                        draft.homeTeam = val;
                    })
                ),
        })),
        // ...
        {
            name: 'prefs', // unique name
            version: 1,
            migrate: (persistedState) => {
                return persistedState;
            }
        }
    ));
