import React from 'react';

import {IconButton, Typography, useMediaQuery, useTheme} from '@mui/material';

import Icons from 'assets/icons';

const styles = {
    button: {
        backgroundColor: 'black.main',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '8px 8px 11px 8px',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.dark',
        width: '100%',
    },

    label: {
        color: 'white.main',
        fontSize: '13px',
        fontWeight: '400',
        marginLeft: '12px',
    },

    labelDisabled: {
        color: 'grey.lightest',
    }
};

const Icon = ({icon, text, onClick, fill, disabled, ...rest}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    let UsedIcon = Icons[icon];

    return <IconButton
        sx={styles.button}
        disableFocusRipple={false}
        disableRipple={false}
        disableTouchRipple={false}
        onClick={onClick}
        disabled={disabled}
        {...rest}>
        <UsedIcon
            style={{
                fill: disabled ? theme.palette.grey.lightest : (fill ? fill : theme.palette.white.main),
                width: isMobile ? 'auto' : 30,
                height: isMobile ? 20 : 30,
            }}
        />{text && <Typography sx={[styles.label, disabled && styles.labelDisabled]}>{text}</Typography>}
    </IconButton>;
};

export default Icon;
