import {v4 as uuidv4} from 'uuid';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import storage from 'store/localStorage';
import {middleware} from 'store/zustand';

import {produce} from 'immer';

const deleteSheet = (draft, id) => {
    const index = draft.sheets.findIndex(sht => sht.id === id);
    let groupId = undefined;

    if (index !== -1) {
        groupId = draft.sheets[index].group;
        draft.sheets.splice(index, 1);
    }

    storage.removeItem(`sheet_${id}`);

    if (draft.selected === id) {
        draft.selected = null;

        if (draft.sheets.length > 0) {
            draft.selected = draft.sheets[draft.sheets.length - 1].id;
        }
    }

    // we need to update sort as well
    updateSortField(draft, groupId);
};

export const useSheetStore = create(persist(middleware((set, get) => ({
    sheets: [],
    selected: null,
    frameIndex: 0,
    setSelected: sheetId => set(produce(draft => {
        draft.selected = sheetId;
    })),
    add: (sheet = {}) => set(produce(draft => {
        const newSheet = {
            id: uuidv4(),
            name: '',
            group: null,
            frames: [],
            type: null,
            created: (new Date()).toISOString(),
            modified: null,
            sort: 1,
            ...sheet,
        };
        const groupSheets = get().sheets.filter(sht => sht.group === newSheet.group);
        newSheet.sort = groupSheets.length + 1;
        draft.sheets.push(newSheet);
        draft.selected = newSheet.id;
    })),
    del: id => set(produce(draft => {
        deleteSheet(draft, id);
    })),
    upd: sheet => set(produce(draft => {
        const index = draft.sheets.findIndex(sht => sht.id === sheet.id);

        if (index !== -1) {
            draft.sheets[index] = {
                ...draft.sheets[index],
                modified: new Date(),
                ...sheet
            };
        } else {
            storage.removeItem(`sheet_${sheet.id}`);
        }
    })),
    sort: (sheet, direction) => set(produce(draft => {
        try {
            const currentIndex = draft.sheets.findIndex(sh => sh.id === sheet.id);

            const offset = direction === 'up' ? 1 : -1;
            const newSort = sheet.sort + offset;
            const currentSort = sheet.sort;
            const nextElementIndex = draft.sheets.findIndex(sh => sh.sort === newSort && sh.group === sheet.group);

            draft.sheets[currentIndex].sort = newSort;
            draft.sheets[nextElementIndex].sort = currentSort;
        }
        catch(ex) {
            console.warn(`sort field not exist adding it`);
            // try to add sort field
            addSortField(draft);
        }
    })),
    get: id => {
        const index = get().sheets.findIndex(sht => sht.id === id);

        if (index === -1) {
            return null;
        }

        return get().sheets[index];
    },
    delAll: () => set(produce(draft => {
        for (const sheet of draft.sheets) {
            storage.removeItem(`sheet_${sheet.id}`);
        }

        draft.sheets = [];
        draft.selected = null;
    })),
    getByGroup: groupId => {
        return get().sheets.filter(sht => sht.group === groupId);
    },
    delByGroup: groupId => set(produce(draft => {
        for (const sheet of draft.sheets) {
            if (sheet.group === groupId) {
                deleteSheet(draft, sheet.id);
            }
        }
    })),
    updateGroups: (groupId, val) => set(produce(draft => {
        draft.sheets.forEach(s => {
            if (s.group === groupId) {
                s.group = val;
            }
        });
    })),
    // ANIMATION FRAMES
    addFrame: (sheetId, frame) => set(produce(draft => {
        const index = draft.sheets.findIndex(sheet => sheet.id === sheetId);

        if (index === -1) {
            return;
        }

        draft.sheets[index].frames.push({
            id: uuidv4(),
            frame
        });
    })),
    insertFrame: (sheetId, frame, pos) => set(produce(draft => {
        const index = draft.sheets.findIndex(sheet => sheet.id === sheetId);

        if (index === -1) {
            return;
        }

        draft.sheets[index].frames.splice(pos, 0, {
            id: uuidv4(),
            frame
        });
    })),
    removeFrame: (sheetId, frameIndex) => set(produce(draft => {
        const index = draft.sheets.findIndex(sheet => sheet.id === sheetId);

        if (index === -1) {
            return;
        }

        draft.sheets[index].frames.splice(frameIndex, 1);
    })),
    updateFrame: (sheetId, frameIndex, frame) => set(produce(draft => {
        const index = draft.sheets.findIndex(sheet => sheet.id === sheetId);

        if (index === -1) {
            return;
        }

        if (draft.sheets[index].frames[frameIndex] === undefined) {
            return;
        }

        draft.sheets[index].frames[frameIndex].frame = frame;
    })),
    setFrameIndex: (frameIndex) => set(produce(draft => {
        draft.frameIndex = frameIndex;
    })),
    clearFrames: (sheetId) => set(produce(draft => {
        const index = draft.sheets.findIndex(sheet => sheet.id === sheetId);

        if (index === -1) {
            return;
        }

        draft.sheets[index].frames = [
            // {id: uuidv4(), frame}
        ];
    })),
})),
// ...
{
    name: 'sheets', // unique name
    version: 2, /** NOTE: make sure migrations length should be version NO. + 1 */
    migrate: (persistedState, previousVersion) => {

        // Migrate store here...
        const migrations = [
            // version 0
            // to make sure we align with logic just add this version as array filler
            (state) => state, // mostly will not use just for filler
            // version 1
            (state) => {
                // if the stored value is in version 0, we rename the field to the new name
                state.sheets.forEach(sheet => {
                    sheet.type = 'soccer';
                });// in version 0 all sheets have a field
                delete state.frameIndex;
            },
            // version 2
            // add sorting field
            (state) => addSortField(state),
        ];

        let state = persistedState;

        for (let i = previousVersion + 1; i < migrations.length; i++) {
            state = migrations[i](state);
        }

        return state;
    }}
));

export const sheetStoreActionSelector = state => ({
    get: state.get,
    add: state.add,
    del: state.del,
    upd: state.upd
});

const addSortField = (state) => {
    // all groups ids
    let allGroupIds = state.sheets.map(sheet => sheet.group);
    allGroupIds = [...new Set(allGroupIds)];

    // apply sort to sheets based on groups
    allGroupIds.forEach((groupId) => {
        let sort = 1;
        state.sheets.forEach(sheet => {
            if(sheet.group === groupId) {
                sheet.sort = sort++;
            }
        });
    });
    return state;
};

const updateSortField = (draft, groupId) => {
    let sort = 1;
    draft.sheets.forEach(sheet => {
        if (sheet.group === groupId) {
            sheet.sort = sort;
            sort++;
        }
    });

    return draft;
};
