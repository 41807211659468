import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 350,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            mobile: 0,
            desktop: 900,
        },
    },
    palette: {
        primary: {
            main: '#00ffff',
        },
        secondary: {
            main: '#fff',
        },
        warning: {
            main: '#d50407',
        },
        success: {
            main: '#5eb202',
        },
        text: {
            primary: '#000',
            secondary: '#ccc',
            disabled: '#888',
        },
        info: {
            main: '#191919',
        },

        black: {
            main: '#0f0f0f',
        },
        white: {
            main: '#f0f0f0',
            dark: '#e0e0e0',
            darker: '#d0d0d0',
            darkest: '#c0c0c0',
        },
        grey: {
            darkest: '#191919',
            darker: '#3f3f3f',
            dark: '#4f4f4f',
            light: '#5f5f5f',
            lighter: '#6f6f6f',
            lightest: '#7f7f7f',
        },
        green: {
            main: '#5eb202',
        },
        red: {
            main: '#f56b6b',
            dark: '#d50407'
        },
        orange: {
            main: '#f1a700',
        },
    },
    shape: {
        borderRadius: 2,
    },
    typography: {
        fontFamily: 'inter',
        fontSmooth: 'never',
        h1: {
            fontWeight: 500,
            fontSize: 24,
            letterSpacing: '-0.24px',
        },
        h2: {
            fontWeight: 500,
            fontSize: 20,
            letterSpacing: '-0.24px',
        },
        h3: {
            fontWeight: 500,
            fontSize: 16,
            letterSpacing: '-0.24px',
        },
        h5: {
            fontWeight: 500,
            fontSize: 16,
            letterSpacing: '-0.24px',
        },
        h6: {
            fontWeight: 500,
            fontSize: 14,
            letterSpacing: '-0.24px',
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                container: {},
                paper: {
                    backgroundColor: '#191919',
                },
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    '& .MuiLinearProgress-barColorPrimary': {
                        backgroundColor: '#2f2f2f',
                    },
                },
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    border: 'none',
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#fff',
                },
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: 20,
                },
                textColorSecondary: {
                    color: '#fff',
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    overflow: 'hidden',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    '.MuiButton-endIcon': {
                        color: '#fff',
                    }
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeLarge: {
                    fontSize: 24,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#ccc',
                    backgroundColor: '#191919',
                    padding: '0 6px',
                    borderRadius: '4px',
                    fontSize: 16,

                    '&.Mui-focused': {
                        color: '#5db100',
                    }
                },
            },
        },

        MuiSelect: {
            styleOverrides: {
                select: {
                    /*backgroundColor: '#494949',*/
                },
                icon: {
                    color: '#fff',
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: '#191919',
                    color: '#fff',
                    border: '1px solid #393939',

                    '&.Mui-focused': {

                        input: {
                            width: '100%',
                        },
                        fieldset: {
                            border: 'none',
                        }
                    }
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#000',
                },
                colorSecondary: {
                    color: '#fff',
                }
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    background: '#292929',
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#42414d',
                },
                rounded: {
                    borderRadius: '6px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    '.MuiMenu-list': {
                        color: '#fff'
                    }
                },
            },
        },
        MuiSlider: {

            styleOverrides: {

                valueLabel: {
                    fontSize: 12,
                    fontWeight: 'bold',
                    top: 26,
                    backgroundColor: 'unset',
                    color: '#000',
                    '&:before': {
                        display: 'none',
                    },
                    '& *': {
                        background: 'transparent',
                        color: '#000'
                    }
                },
                mark: {
                    backgroundColor: '#797979',
                    height: '14px',
                    width: 2,
                },
                track: {
                    border: 'none',
                    height: '14px',
                    borderRadius: 0,
                },
                rail: {
                    opacity: 1,
                    backgroundColor: '#424242',
                    height: '14px',
                    border: 'none',
                    borderRadius: 0,
                },
                thumb: {
                    height: 28,
                    width: 28,
                    backgroundColor: '#fff',
                    boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
                    '&:focus, &:hover, &.Mui-active': {
                        boxShadow:
                            '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                        // Reset on touch devices, it doesn't add specificity
                        '@media (hover: none)': {
                            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
                        },
                    },
                },
            },
        },
    },
});

export default theme;
