import {useEffect} from 'react';

import {animFrame} from 'canvas/canvas-animation';
import {useCanvasStore} from 'canvas/zustand';
import {useGroupsStore} from 'module/group/zustand';
import {useSheetStore} from 'module/sheet/zustand';
import storage from 'store/localStorage';
import {usePrefsStore} from 'store/prefs';

const Migrate = () => {
    // const setInfo = useInfoStore((state) => state.setInfo);
    const get = useGroupsStore((state) => state.get);
    const addSheet = useSheetStore((state) => state.add);
    const addGroup = useGroupsStore((state) => state.add);
    const addMigrationGroup = useGroupsStore((state) => state.addMigrationGroup);
    const migrationGroupId = useGroupsStore((state) => state.migrationGroupId);
    const insertFrame = useSheetStore((state) => state.insertFrame);
    const canvas = useCanvasStore(state => state.canvas);
    const setShowHelp = usePrefsStore((state) => state.setShowHelp);

    const getSheet = useSheetStore((state) => state.get);
    const addFrame = useSheetStore((state) => state.addFrame);
    const updateSheet = useSheetStore((store) => store.upd);

    useEffect(() => {
        setShowHelp(false);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (storage.getItem('version') && storage.getItem('version') >= 1) {
            console.log('MIGRATION_LOG: version already migrated to version 1 or higher');
            return;
        }
             
        if (get(migrationGroupId)) {
            console.log('MIGRATION_LOG: Migration group already created -> abort migration');
            return;
        }

        const animStore = JSON.parse(storage.getItem('animations'));
        const sheetStore = JSON.parse(storage.getItem('sheets'));
        const groupStore = JSON.parse(storage.getItem('groups'));

        // 1a) add migration group for all animations without group to be imported to
        addMigrationGroup();
        console.log('MIGRATION_LOG: Added migration group');

        // 1b) groups import
        if (typeof groupStore === 'object' && groupStore !== null) {
            groupStore.state.groups.forEach(group => {
                addGroup(group);
            });
        }

        // 2) transfer all frames from animations to sheets storage (sheet_xyz are already present if v0 data consistent)
        if (typeof animStore === 'object' && animStore !== null) {
            animStore.state.animations.forEach(animation => {
                if (getSheet(animation.id) === null) {
                    addSheet({id: animation.id, name: animation.name, type: 'soccer', group: animation.group ? animation.group : migrationGroupId});
                    let frameCount = 0;
                    animation.frames.forEach(obj => {
                        if (obj.frame.length > 0) {
                            addFrame(animation.id, obj.frame);
                            frameCount++;
                        }
                    });

                    // add initial frame from canvas if no frames found
                    if (frameCount === 0) {
                        insertFrame(animation.id, animFrame(canvas), 0);
                        frameCount++;
                    }

                    console.log('MIGRATION_LOG:' + ` Animation ${animation.id} added ' + ${frameCount} frames`);
                }
                else {
                    console.log(animation.id, 'MIGRATION_LOG: no overwrite, sheet already found. Abort animation frame import');
                }
            });
            storage.removeItem(`animations`);
        }

        // 3) sheets without group get default migration group name too
        if (typeof sheetStore === 'object' && sheetStore !== null) {
            sheetStore.state.sheets.forEach(sheet => {
                if (sheet.group === null)
                    updateSheet({...sheet, group: migrationGroupId});
                else
                    addSheet(sheet);
            });
        }

        storage.setItem('version', sheetStore.version);
        console.log('MIGRATION_LOG: Migration version 0 to version 1 completed');
        // eslint-disable-next-line
    }, []);

    return null;
};

export default Migrate;
