import React, {useState} from 'react';

import {
    Card,
    CardContent,
    Drawer as MuiDrawer,
    List,
} from '@mui/material';
import {styled} from '@mui/material/styles';

import SwiperCore, {Lazy, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import Previews from 'assets/video/previews';
import {MenuHeader} from 'components';
import {Text} from 'components';
import {MONITOR_URL} from 'config';
import {usePrefsStore} from 'store';

import 'swiper/css/bundle';

SwiperCore.use([Pagination, Lazy]);

const styles = {
    drawer: {
        width: '100%',
        backgroundColor: 'black.main',

    },
    overlay: {
        padding: '0 20px 20px 20px',
        width: '100%',
        zIndex: '999',
        animationName: 'slideToLeft',
        animationDuration: '0.1s',
        animationFillMode: 'forwards',
        backgroundColor: 'black.main',
    },
};

const videos = [
    {
        title: 'board.menu.introduction',
        name: '01.mp4',
        preview: '01',
    },
    {
        title: 'board.menu.basics_tactic',
        name: '02.mp4',
        preview: '02',
    },
    {
        title: 'board.menu.moves',
        name: '03.mp4',
        preview: '03',
    },
    {
        title: 'board.menu.tools1',
        name: '04_1.mp4',
        preview: '04_1',
    },
    {
        title: 'board.menu.tools2',
        name: '04_2.mp4',
        preview: '04_2',
    },
    {
        title: 'board.menu.sync_phone',
        name: '05.mp4',
        preview: '05',
    },
    {
        title: 'board.menu.shopping',
        name: '06.mp4',
        preview: '06',
    },
];

const PREFIX = 'Help';

const classes = {
    swiper: `${PREFIX}-swiper`,
    swiperSlide: `${PREFIX}-swiperSlide`,
    container: `${PREFIX}-container`,
};

const StyledDrawer = styled(MuiDrawer)(({theme}) => ({
    [`& .${classes.swiper}`]: {
        padding: 0,
    },

    [`& .${classes.swiperSlide}`]: {
        height: 'auto',
        maxWidth: 800,
    },

    [`& .${classes.container}`]: {
        margin: theme.spacing(4),
    },

    [`& .MuiGrid-root`]: {
        color: 'white.main',
        padding: theme.spacing(2)
    },

    [`& .MuiIconButton-root`]: {
        color: 'white.main',
    },
}));

const Video = ({title, name, preview}) => {
    const [playing, setPlaying] = useState(false);

    return (
        <Card>
            <CardContent>
                {playing || (
                    <img
                        src={Previews[preview]}
                        title={title}
                        alt={title}
                        onClick={() => setPlaying(true)}
                        style={{width: '100%', height: 'auto', display: 'flex'}}
                    />
                )}
                {playing && (
                    <video
                        src={`${MONITOR_URL}video/${name}`}
                        autoPlay
                        controls
                        onClick={() => setPlaying(false)}
                    />
                )}
            </CardContent>
        </Card>
    );
};

const Drawer = () => {
    const setShowHelp = usePrefsStore((state) => state.setShowHelp);
    const showHelp = usePrefsStore((state) => state.showHelp);

    return (
        <StyledDrawer anchor={'top'} open={showHelp} onClose={() => setShowHelp(false)} PaperProps={{sx: styles.drawer}}>

            <List sx={styles.overlay}>
                <MenuHeader text={<Text>board.menu.instructions</Text>} handleBackButton={() => setShowHelp(false)} handleForwardButton={() => setShowHelp(false)} />

                <Swiper
                    pagination={{clickable: true}}
                    lazy={true}
                    slidesPerView={1.35}
                    spaceBetween={10}
                    className={classes.swiper}
                >
                    {videos.map((v, idx) => (
                        <SwiperSlide className={classes.swiperSlide} key={idx}>
                            <Video title={v.title} name={v.name} preview={v.preview} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </List>
        </StyledDrawer>
    );
};

export default Drawer;
