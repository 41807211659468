import React from 'react';

import {Box, useMediaQuery, useTheme} from '@mui/material';

import {useClockTimer} from 'hooks/index.js';
import {dateFormat} from 'lib/datefns.js';

const styles = {
    clockStyle: {
        fontSize: '20px',
        fontWeight: 200,
        height: '70px',
        color: 'white.darkest',
        backgroundColor: 'grey.darkest',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        whiteSpace: 'nowrap',
        marginLeft: '10px',
    },
    hoursMinutes: {
        display: 'flex',
        marginLeft: '6px',
        color: 'white.main',
        fontWeight: 500,
    },
};

const Clock = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dateTime = useClockTimer(new Date());

    if (isMobile) {
        return null;
    }

    return <Box sx={styles.clockStyle}>{dateFormat(dateTime, 'cccc, dd. MMM yyyy')} <Box sx={styles.hoursMinutes}>{dateFormat(dateTime, 'HH:mm')}</Box></Box>;
};

export default Clock;
