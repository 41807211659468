import {useCallback, useState} from 'react';

const useModal = () => {
    const [visible, setVisible] = useState(false);

    const toggle = useCallback(() => {
        setVisible(!visible);
    }, [visible]);

    const show = useCallback(() => {
        setVisible(true);
    }, []);

    const hide = useCallback(() => {
        setVisible(false);
    }, []);

    return {visible, setVisible, show, hide, toggle};
};

export default useModal;
