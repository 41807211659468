import React, {useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import {CssBaseline, ThemeProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';

import {Snackbar} from 'components';
import {useSettingsStore} from 'module/settings/zustand';

import {getMode} from './lib/tactix';
import {Layout} from './layout';
import Migrate from './Migrate';
import Periodic from './Periodic';

import './App.css';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

import FontFaceObserver from 'fontfaceobserver';
import {Settings} from 'luxon';
import theme from 'theme/Theme';

const ErrorFallback = ({error, resetErrorBoundary}) => {
    window?.logApi?.error(error.message);
    window?.logApi?.error(error.stack);
    console.log(error.message);
    console.log(error.stack);
    return (
        <div role="alert">
            <p>Ein Problem ist aufgetreten:</p>
            <h1>Oops, Foul, Elfmeter, Rote Karte ...</h1>
            <div>
                <a href="" title="Tactix neu laden" onClick={resetErrorBoundary}>
                    Tactix neu laden
                </a>
                <div>{error.message}</div>
                <div>{error.stack}</div>
            </div>
        </div>
    );
};

const App = () => {
    // useDemoData();
    // useLoadSettings();

    Settings.defaultLocale = useSettingsStore(state => state.selectedLanguage);
    const [fontLoading, setFontLoading] = useState(true);

    getMode().then(val => console.log('mode', val));

    useEffect(() => {
        // load the app-font first then render the app
        // so the canvas can render the font with the correct font-family
        const fontLoader = new FontFaceObserver('inter');
        fontLoader.load().then(function () {
            setFontLoading(false);
        }).catch(function (_) {
            console.log(`inter font can't be loaded, so you may see different font on canvas.`);
        });
    }, []);

    // we wait until the font was loaded so the canvas can render the correct font
    if (fontLoading) return null;

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => location.reload()}>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'de'}>
                <ThemeProvider theme={theme}>
                    <Migrate/>
                    <Periodic/>
                    <CssBaseline/>
                    <Snackbar/>
                    <Layout/>
                </ThemeProvider>
            </LocalizationProvider>
        </ErrorBoundary>
    );
};

export default App;
