import {fabric} from 'fabric';

export const migrate4to5 = (data, element) => {
    if (element?.custom?.type === 'player') {
        for (const obj of element.getObjects()) {
            if (obj.type === 'circle') {
                obj.startAngle = fabric.util.radiansToDegrees(obj.startAngle);
                obj.endAngle = fabric.util.radiansToDegrees(obj.endAngle);
                console.log(obj);
            }
        }
    }
};
