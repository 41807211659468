const doLog = (msg) => {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'log',
            data: msg
        }));
    } else {
        console.log(msg);
    }
};

export default doLog;
