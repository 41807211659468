import React from 'react';

import {Grid, Stack} from '@mui/material';

import {NoData} from 'components';
import {PlayerListItem} from 'module/settings';
import {useSettingsStore} from 'module/settings/zustand';

const styles = {
    root: {
        background: 'linear-gradient(180deg, rgba(41,41,41,1), rgba(73,73,73,1));',
        width: '100%',
    },
    blockContainer: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignSelf: 'flex-start',
        zIndex: 910,
    },
    blockContainerRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '70px',
        borderLeft: '10px solid',
        borderLeftColor: 'grey.light',
        marginBottom: '1px',
        color: 'white.main',
        fontSize: '16px',
        padding: '8px 32px 8px 20px',
        backgroundColor: 'grey.darker',
        cursor: 'normal',
    },
};

const Players = () => {
    const players = useSettingsStore((state) => state.players);

    return <Grid sx={styles.root}>
        {(players && players.length > 0) ? players.map((player, i) => (
            <Grid key={`panelgroup-${i}`} item xs={12} sx={styles.blockContainer}>
                <Stack sx={styles.blockContainerRow}>
                    <PlayerListItem player={player}/>
                </Stack>
            </Grid>
        )) : <NoData text="Keine Spieler vorhanden!" />
        }
    </Grid>;
};

export default Players;
