import {ReactComponent as Lineup343} from './3-4-3.svg';
import {ReactComponent as Lineup352} from './3-5-2.svg';
import {ReactComponent as Lineup4231} from './4-2-3-1.svg';
import {ReactComponent as Lineup4321} from './4-3-2-1.svg';
import {ReactComponent as Lineup433} from './4-3-3.svg';
import {ReactComponent as Lineup442} from './4-4-2.svg';
import {ReactComponent as Lineup442diamond} from './4-4-2-diamond.svg';
import {ReactComponent as Lineup451} from './4-5-1.svg';
import {ReactComponent as Lineup451libero} from './4-5-1-libero.svg';
import {ReactComponent as Lineup532} from './5-3-2.svg';
import {ReactComponent as Lineup541} from './5-4-1.svg';

// import {ReactComponent as Lineup325} from './3-2-5.svg';
// import {ReactComponent as Lineup4132} from './4-1-3-2.svg';
// import {ReactComponent as Lineup4141} from './4-1-4-1.svg';
// import {ReactComponent as Lineup4222} from './4-2-2-2.svg';
// import {ReactComponent as Lineup523} from './5-2-3.svg';

const defDefense4 = [{x: 3, y: 29}, {x: 7, y: 28}, {x: 11, y: 28}, {x: 15, y: 29}];
const defMidfield4 = [{x: 3, y: 18}, {x: 7, y: 19}, {x: 11, y: 19}, {x: 16, y: 18}];
const defAttack1 = [{x: 9, y: 6}];
const defAttack2 = [{x: 7, y: 6}, {x: 11, y: 6}];

const lineups = [
    {
        name: '3-4-3',
        Icon: Lineup343,
        positions: [
            {x: 3, y: 30}, {x: 9, y: 28}, {x: 15, y: 30},
            ...defMidfield4,
            {x: 5, y: 7}, {x: 9, y: 6}, {x: 13, y: 7},
        ]
    },
    {
        name: '3-5-2',
        Icon: Lineup352,
        positions: [
            {x: 5, y: 29}, {x: 9, y: 30}, {x: 13, y: 29},
            {x: 1, y: 18}, {x: 5, y: 19}, {x: 9, y: 15}, {x: 13, y: 19}, {x: 17, y: 18},
            ...defAttack2
        ]
    },
    {
        name: '4-2-3-1',
        Icon: Lineup4231,
        positions: [
            ...defDefense4,
            {x: 7, y: 23}, {x: 11, y: 23},
            {x: 4, y: 14}, {x: 9, y: 14}, {x: 14, y: 14},
            ...defAttack1
        ]
    },
    {
        name: '4-3-2-1',
        Icon: Lineup4321,
        positions: [
            ...defDefense4,
            {x: 4, y: 22}, {x: 9, y: 22}, {x: 14, y: 22},
            {x: 7, y: 14}, {x: 11, y: 14},
            ...defAttack1
        ]
    },
    {
        name: '4-3-3',
        Icon: Lineup433,
        positions: [
            ...defDefense4,
            {x: 4, y: 19}, {x: 9, y: 19}, {x: 14, y: 19},
            {x: 4, y: 5}, {x: 9, y: 6}, {x: 14, y: 5}
        ]
    },
    {
        name: '4-4-2',
        Icon: Lineup442,
        positions: [
            ...defDefense4,
            ...defMidfield4,
            ...defAttack2,
        ]
    },
    {
        name: '4-4-2 dia',
        Icon: Lineup442diamond,
        positions: [
            ...defDefense4,
            {x: 4, y: 19}, {x: 9, y: 14}, {x: 9, y: 24}, {x: 14, y: 19},
            ...defAttack2,
        ]
    },
    {
        name: '4-5-1',
        Icon: Lineup451,
        positions: [
            ...defDefense4,
            {x: 1, y: 18}, {x: 5, y: 19}, {x: 9, y: 15}, {x: 13, y: 19}, {x: 17, y: 18},
            ...defAttack1
        ]
    },
    {
        name: '4-5-1 libero',
        Icon: Lineup451libero,
        positions: [
            {x: 3, y: 29}, {x: 9, y: 28}, {x: 9, y: 31}, {x: 15, y: 29},
            {x: 2, y: 18}, {x: 7, y: 19}, {x: 9, y: 15}, {x: 11, y: 19}, {x: 16, y: 18},
            ...defAttack1
        ]
    },
    {
        name: '5-3-2',
        Icon: Lineup532,
        positions: [
            {x: 2, y: 25}, {x: 6, y: 30}, {x: 9, y: 29}, {x: 12, y: 30}, {x: 17, y: 25},
            {x: 5, y: 16}, {x: 9, y: 17}, {x: 13, y: 16},
            ...defAttack2,
        ]
    },
    {
        name: '5-4-1',
        Icon: Lineup541,
        positions: [
            {x: 2, y: 28}, {x: 6, y: 30}, {x: 9, y: 29}, {x: 12, y: 30}, {x: 17, y: 28},
            ...defMidfield4,
            ...defAttack1
        ]
    },
];

export default lineups;
