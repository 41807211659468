import React, {useEffect, useState} from 'react';

import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon, ListItemText, Typography
} from '@mui/material';

import {Dialog, Text} from 'components';
import {isOk} from 'fetcher/useFetcher';
import {useTranslation} from 'hooks';
import {useGroupsStore} from 'module/group/zustand';
import {useSheetStore} from 'module/sheet/zustand';
import {useSnackbarStore} from 'store';
import storage from 'store/localStorage';

import {useSyncInfo, useSyncPushMeta, useSyncPushSheet} from './Api';

const styles = {
    dialogContainer: {
        padding: '0 16px 16px 16px',
    },
    listItemButton: {
        padding: '0 16px',
    },
    listItemIcon: {
        minWidth: 0,
    },
    listTitle: {
        padding: '0 0 16px 0',
    }
};

const PushDialog = ({open, handleClose}) => {
    const translate = useTranslation();

    const [checked, setChecked] = useState([]);

    const sheets = useSheetStore(store => store.sheets);
    const groups = useGroupsStore(store => store.groups);

    const showInfo = useSnackbarStore(state => state.show);

    const {push: pushSheet} = useSyncPushSheet();
    const {push: pushMeta} = useSyncPushMeta();

    const {load, info, loading, hasError} = useSyncInfo();

    const filteredSheets = sheets.filter(sheet => groups.length === 0 || checked.indexOf(sheet.group) !== -1);
    const filteredGroups = groups.filter(group => checked.indexOf(group.id) !== -1);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const doPush = async () => {
        const sendSheets = [];

        for (const sheet of filteredSheets) {
            const canvas = storage.getItem(`sheet_${sheet.id}`);
            const res = await pushSheet(sheet, canvas);

            if (isOk(res)) {
                sendSheets.push(sheet.id);
            }
        }

        const sendGroups = [];

        for (const group of filteredGroups) {
            if (checked.length > 0) {
                if (checked.indexOf(group.id) === -1) {
                    continue;
                }
            }

            sendGroups.push(group);
        }

        const res = pushMeta({
            info: {
                email: window.appUser?.email,
                firstname: window.appUser?.firstname,
                lastname: window.appUser?.lastname
            },
            sheets: sendSheets,
            groups: sendGroups,
        });

        if (isOk(res)) {
            showInfo(translate('board.sync.action.confirm_transfer'));
        }
    };

    useEffect(() => {
        load();
        pushMeta({
            info: {
                email: window.appUser?.email || '',
                firstname: window.appUser?.firstname || '',
                lastname: window.appUser?.lastname || ''
            },
            sheets: [],
            groups: []
        });
    }, [load, pushMeta]);

    const isConnected = !loading && !hasError;
    const canPush = (filteredSheets.length > 0) && isConnected;

    return <Dialog open={open} handleClose={handleClose} title={translate('board.sync.transfer_scenario')}>
        <DialogContent sx={styles.dialogContainer}>
            {loading && <Typography color="secondary"><Text>board.sync.action.test_connection</Text></Typography>}
            {!loading && hasError && <><Typography color="secondary"><Text>board.sync.action.connection_failed</Text></Typography><Button
                variant={'outlined'} color={'error'} onClick={load}><Text>board.sync.action.retry</Text></Button></>}
            {isConnected && <Typography color="secondary"><Text>board.sync.action.connected</Text> {info.title}</Typography>}
        </DialogContent>
        <DialogContent sx={styles.dialogContainer}>
            <Typography color="secondary"><Text>board.sync.count_groups</Text> {`${filteredGroups.length} / ${groups.length}`}</Typography>
            <Typography color="secondary"><Text>board.sync.count_sheets</Text> {`${filteredSheets.length} / ${sheets.length}`}</Typography>
        </DialogContent>
        {groups.length > 0 && <DialogContent sx={styles.listItemButton}>
            <Typography sx={styles.listTitle} color="secondary"><Text>board.sync.action.select_groups</Text></Typography>
            <List dense disablePadding>
                <ListItem disablePadding>
                    <ListItemButton sx={styles.listItemButton} onClick={handleToggle(null)} dense>
                        <ListItemIcon sx={styles.listItemIcon}>
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(null) !== -1}
                                tabIndex={-1}
                                disableRipple
                                color="secondary"
                                sx={{
                                    color: 'secondary.main',
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText id="checkbox-list-label-null" primaryTypographyProps={{color: 'secondary'}} primary={translate('board.sync.not_assigned')}/>
                    </ListItemButton>
                </ListItem>
                {groups.map(group => {
                    const labelId = `checkbox-list-label-${group.id}`;
                    return <ListItem key={group.id} disablePadding>
                        <ListItemButton sx={styles.listItemButton} onClick={handleToggle(group.id)} dense>
                            <ListItemIcon sx={styles.listItemIcon}>
                                <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(group.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                    color="secondary"
                                    sx={{
                                        color: 'white.main',
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primaryTypographyProps={{color: 'secondary'}} primary={group.name}/>
                        </ListItemButton>
                    </ListItem>;
                })}
            </List>
        </DialogContent>}
        <DialogActions>
            <Button
                variant={'contained'} color={'error'} onClick={doPush}
                disabled={!canPush}><Text>ui.actions.send</Text></Button>
        </DialogActions>
    </Dialog>;
};

export default PushDialog;
