import {useCallback, useEffect, useRef, useState} from 'react';

import axios from 'axios';

import useAbortSignal from './useAbortSignal';
import {buildErrorState, buildSuccessState, formatUrl} from './utils';

export const isCancel = (res) => res !== undefined && res.abortSignal !== undefined && res.abortSignal.aborted === true;
export const isOk = (res) => res !== undefined && !res.hasError;

export const useFetcher = (url, args = {}, defaultData = undefined) => {
    const {newAbortSignal} = useAbortSignal();
    const [state, setState] = useState({
        loading: false,
        error: {},
        data: defaultData ? defaultData : {},
        headers: {},
        status: 0,
        hasError: undefined,
    });

    const loadingRef = useRef(state.loading);
    const abortSignalRef = useRef(undefined);
    const argsStr = JSON.stringify(args);

    const setData = useCallback((data) => {
        setState((oldState) => ({...oldState, data}));
    }, []);

    useEffect(() => {
        loadingRef.current = state.loading;
    }, [state.loading]);

    const fetch = useCallback(
        async (args2) => {
            if (loadingRef.current) {
                return;
            }
            
            const abortSignal = newAbortSignal();
            abortSignalRef.current = abortSignal;

            try {
                const interimState = {error: {}, loading: true, status: 0, hasError: undefined};
                let finalUrl = url;

                if (typeof args2 === 'object') {
                    if (typeof args2.url === 'string') {
                        finalUrl = args2.url;
                        delete args2.url;
                    }

                    if (typeof args2.urlParams === 'object') {
                        finalUrl = formatUrl(finalUrl, args2.urlParams);
                        delete args2.urlParams;
                    }
                }

                setState((oldState) => {
                    let newData = oldState.data;

                    if (args2?.data) {
                        newData = {...newData, ...args2.data};
                    }

                    return {...oldState, ...interimState, data: newData};
                });

                const res = await axios({
                    url: finalUrl,
                    signal: abortSignal,
                    ...args,
                    ...args2,
                });

                const newState = buildSuccessState(res);
                setState((oldState) => ({...oldState, ...newState}));

                loadingRef.current = false;

                return newState;
            } catch (e) {
                if (abortSignal.aborted) {
                    return;
                }

                const newState = buildErrorState(e);
                setState((oldState) => ({...oldState, ...newState}));

                loadingRef.current = false;

                return newState;
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [url, argsStr, newAbortSignal]);

    return {...state, fetch, abortSignal: abortSignalRef.current, setData};
};

export default useFetcher;
