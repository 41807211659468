import React, {useEffect, useRef, useState} from 'react';

import {
    Add as AddIcon,
    KeyboardArrowDown as DropDownIcon,
    Pause as PauseIcon,
    PlayArrow as PlayIcon,
    Remove as RemoveIcon,
} from '@mui/icons-material';
import {ButtonGroup, Grid, Menu, MenuItem, Slider} from '@mui/material';

import {animFrame, animPlayFrame} from 'canvas/canvas-animation';
import {useCanvasStore} from 'canvas/zustand';
import {ActionButton} from 'components';
import {MAX_ANIMATION_FRAMES} from 'config';
import {useTranslation} from 'hooks';
import sleep from 'lib/sleep';
import {usePrefsStore, useSnackbarStore} from 'store';

import {useSheetStore} from './zustand';

const styles = {
    sliderContainerOverall: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        py: 0,
    },
    sliderContainer: {
        display: 'flex',
        alignItems: 'center',
        px: 3,
        py: 0,
    },
    slider: {
        padding: 0,
        // by default extra padding is added on coarse pointers e.g. touch
        '@media (pointer: coarse)': {
            padding: 0
        },
    },
    buttonContainer: {
        padding: 0,
        margin: 0,
        display: 'flex',

        '& button': {
            marginLeft: {
                mobile: 0,
                desktop: 0,
            },
        },
    },
    active: {
        color: 'white.main',
        border: 'none',
        '&:hover': {
            border: 'none',
        }
    },
    inActive: {
        color: 'grey.lightest',
        border: 'none',
        '&:hover': {
            border: 'none',
        }
    },
    activePlay: {
        color: 'white.main',
        border: 'none',
        backgroundColor: 'grey.darker',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderRadius: 0,
        '&:hover': {
            border: 'none',
        }
    },
    inActivePlay: {
        color: 'white.main',
        border: 'none',
        backgroundColor: 'grey.darker',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderRadius: 0,
        '&:hover': {
            border: 'none',
        }
    },
    speedButton: {
        backgroundColor: 'grey.darker',
        borderRadius: 0,
    },
    speedSelectButton: {
        borderRight: '1px solid',
        borderRightColor: 'grey.dark',
        borderRadius: 0,
        color: 'white.main',
        fontSize: '16px',
    },
    speedSelectButtonDropDown: {
        marginRight: '5px',
    },
    actionButton: {
        borderRight: '1px solid',
        borderRightColor: 'grey.dark',
        borderRadius: 0,
        color: 'white.main',
        fontSize: '16px',
    },
};

const INITIAL_SPEED = 1000;
const DEFAULT_SPEED = INITIAL_SPEED;

// lower means fast
// its values [1000, 900, 800]
// 1000 -> 1 second between frames, 900 -> 900ms between frames, 800 -> 800ms between frames
const speedOptions = [
    {
        label: '1x',
        value: INITIAL_SPEED + 1000,
    },
    {
        label: '2x',
        value: INITIAL_SPEED + 500,
    },
    {
        label: '3x',
        value: INITIAL_SPEED,
    }
];

const AnimationFrames = ({isMobile}) => {
    const translate = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [frame, setFrame] = useState({index: 0, duration: 500});
    const [playing, setPlaying] = useState(false);
    const [playSpeed, setPlaySpeed] = useState(DEFAULT_SPEED);

    const isCancelled = useRef(false);

    const setShowTimeline = usePrefsStore((state) => state.setShowTimeline);

    const showInfo = useSnackbarStore((state) => state.show);

    const canvas = useCanvasStore((state) => state.canvas);
    const setDirty = useCanvasStore((state) => state.setDirty);

    const selected = useSheetStore((state) => state.selected);
    const getSheet = useSheetStore((state) => state.get);
    const setFrameIndex = useSheetStore((state) => state.setFrameIndex);
    const sheet = getSheet(selected);

    const numFrames = sheet?.frames?.length || 0;
    const hasFrames = numFrames > 1;

    const insertFrame = useSheetStore((state) => state.insertFrame);
    const removeFrame = useSheetStore((state) => state.removeFrame);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSpeed = (e) => {
        setPlaySpeed(e.target.value);
        handleClose();
    };

    const getSpeedLabel = () => {
        return speedOptions.find(s => s.value === playSpeed)?.label;
    };

    const addFrame = () => {
        if (sheet?.frames?.length >= MAX_ANIMATION_FRAMES) {
            showInfo(translate('board.sheet.validation.max_moves'), {severity: 'warning'});
            return;
        }

        const newIndex = frame.index + 1;
        insertFrame(selected, animFrame(canvas), newIndex);
        setFrame({index: newIndex, duration: 100});
        setDirty(false);
    };

    const cutFrame = () => {
        // do not delete first key frame, if there are other frames available
        if (frame.index === 0 && numFrames > 1) {
            return;
        }

        removeFrame(selected, frame.index);

        let jumpTo = frame.index >= numFrames - 1 ? frame.index - 1 : frame.index;

        if (jumpTo < 0) {
            jumpTo = 0;
        }

        setDirty(false);

        if (frame.index === 0) {
            setShowTimeline(false);
        } else {
            setFrame({...frame, index: jumpTo});
        }
    };

    const play = async () => {
        const wait = playSpeed;
        setPlaying(true);
        isCancelled.current = false;

        const start = frame.index >= numFrames - 1 ? 0 : frame.index;

        for (let idx = start; idx <= numFrames - 1; idx++) {
            setFrame({duration: wait, index: idx});
            // make it more responsive
            await sleep(wait / 2);
            if (isCancelled.current) break;
            await sleep(wait / 2);
            if (isCancelled.current) break;
            await sleep(wait / 2);
            if (isCancelled.current) break;
            await sleep(wait / 2);
            if (isCancelled.current) break;
        }

        setPlaying(false);
    };

    const pause = () => {
        isCancelled.current = true;
    };

    const goto = (idx) => {
        if (!hasFrames) {
            return;
        }

        setFrame({index: idx, duration: 100});
    };

    const playButtonHandler = playing ? pause : play;
    const PlayButtonIcon = playing ? PauseIcon : PlayIcon;

    useEffect(() => {
        // insert key frame
        if (numFrames === 0 && sheet?.type) {
            insertFrame(selected, animFrame(canvas), 0);
        }

        setFrame({...frame, index: 0});

        // must run if selected sheet changes only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, insertFrame]);

    useEffect(() => {
        setFrameIndex(frame.index);

        if (canvas) {
            animPlayFrame(canvas, sheet?.frames, frame.index, frame.duration);
        }

        return () => {
            setFrameIndex(0);
        };
    }, [frame, setFrameIndex, sheet, canvas]);

    return <Grid container sx={styles.sliderContainerOverall}>
        <Grid item xs sx={styles.sliderContainer} zIndex={13}>
            <Slider
                size={isMobile ? 'small' : undefined}
                sx={styles.slider}
                color="secondary"
                step={1}
                onChange={(_e, v) => goto(v)}
                value={frame.index}
                valueLabelFormat={value => value + 1}
                marks={true}
                min={0}
                max={hasFrames ? numFrames - 1 : 1}
                valueLabelDisplay={isMobile ? 'off' : 'on'}
            />
        </Grid>

        <Grid item sx={styles.buttonContainer}>
            <ButtonGroup sx={styles.speedButton} variant={'outlined'}>
                <ActionButton sx={styles.actionButton} onClick={addFrame} disabled={playing}>
                    <AddIcon sx={playing ? styles.inActive : styles.active}/>
                </ActionButton>
                
                {hasFrames &&
                    <ActionButton sx={styles.actionButton} onClick={cutFrame} disabled={playing || !hasFrames}>
                        <RemoveIcon sx={!playing && hasFrames ? styles.active : styles.inActive} />
                    </ActionButton>}
                
                {hasFrames &&
                    <ActionButton
                        sx={styles.speedSelectButton}
                        onClick={handleClick}>
                        <DropDownIcon sx={styles.speedSelectButtonDropDown} />
                        {getSpeedLabel()}
                    </ActionButton>}
                
                {hasFrames &&
                    <ActionButton
                        sx={hasFrames ? styles.activePlay : styles.inActivePlay}
                        disabled={!sheet?.frames}
                        onClick={playButtonHandler}>
                        <PlayButtonIcon />
                    </ActionButton>}

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    sx={styles.menu}
                    onClose={handleClose}
                    elevation={0}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    {speedOptions.map((option, index) => (
                        <MenuItem
                            key={`speedIndex-${index}`}
                            value={option.value}
                            onClick={handleSpeed}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Menu>

            </ButtonGroup>
        </Grid>
    </Grid>;
};

export default AnimationFrames;
