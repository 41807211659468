import React from 'react';

import {ArrowForwardIos as ArrowForwardIosIcon} from '@mui/icons-material';
import {
    ClickAwayListener,
    Drawer as MuiDrawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText} from '@mui/material';

import {MenuHeader} from 'components';
import {useTranslation} from 'hooks';
import {usePrefsStore} from 'store';

import {Actions} from '.';

const styles = {
    drawer: {
        width: '100%',
        backgroundColor: 'black.main',
    },
    overlay: {
        padding: '0 20px 20px 20px',
        width: '100%',
        zIndex: '999',
        animationName: 'slideToLeft',
        animationDuration: '0.1s',
        animationFillMode: 'forwards',
    },
    menuButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '70px',
        borderBottom: '1px solid',
        borderLeft: '10px solid',
        borderBottomColor: 'grey.dark',
        borderLeftColor: 'grey.dark',
        color: 'white.main',
        fontSize: '16px',
        cursor: 'pointer',
        padding: '8px 0 8px 20px',
        backgroundColor: 'grey.darkest',
    }
};

const Drawer = () => {
    const translate = useTranslation();

    const showDrawer = usePrefsStore((state) => state.showDrawer);
    const setShowDrawer = usePrefsStore((state) => state.setShowDrawer);
    const setShowSettings = usePrefsStore((state) => state.setShowSettings);
    // const setShowHelp = usePrefsStore((state) => state.setShowHelp);
    const setShowInformationDialog = usePrefsStore((state) => state.setShowInformationDialog);
    const setShowMenu = usePrefsStore((state) => state.setShowMenu);

    function handleClose() {
        setShowMenu(false);
        setShowDrawer(false);
    }

    return <MuiDrawer
        PaperProps={{sx: styles.drawer}}
        anchor={'left'}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
    >
        <ClickAwayListener onClickAway={() => setShowDrawer(false)}>
            <List sx={styles.overlay}>

                <MenuHeader text={translate('board.menu.main')} handleBackButton={handleClose} handleForwardButton={handleClose} />

                <ListItem sx={styles.menuButton} key={'Themen'}>
                    <ListItemButton
                        onClick={() => {
                            setShowMenu(true);
                            setShowDrawer(false);
                        }}
                    >
                        {/*<ListItemIcon>{<InboxIcon />}</ListItemIcon>*/}
                        <ListItemText primary={translate('board.menu.my_groups_sheets')}/>
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                    </ListItemButton>
                </ListItem>
                <ListItem sx={styles.menuButton} key={'Einstellungen'}>
                    <ListItemButton
                        onClick={() => {
                            setShowSettings(true);
                            setShowDrawer(false);
                        }}
                    >
                        <ListItemText primary={translate('board.menu.settings')}/>
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                    </ListItemButton>
                </ListItem>

                {/*<ListItem sx={styles.menuButton} key={'Tutorials'}>
                        <ListItemButton onClick={() => setShowHelp(true)}>
                            <ListItemIcon>{<InboxIcon />}</ListItemIcon>
                            <ListItemText primary={translate('board.menu.tutorials')} />
                            <ArrowForwardIosIcon></ArrowForwardIosIcon>
                        </ListItemButton>
                    </ListItem>*/}
                <ListItem sx={styles.menuButton} key={'Informationen'}>
                    <ListItemButton onClick={() => {
                        setShowInformationDialog(true);
                        setShowDrawer(false);
                    }}>
                        {/*<ListItemIcon>{<InboxIcon/>}</ListItemIcon>*/}
                        <ListItemText primary={translate('board.menu.infos')}/>
                        <ArrowForwardIosIcon></ArrowForwardIosIcon>
                    </ListItemButton>
                </ListItem>
                {/*<ListItem sx={styles.menuButton} key={'Selbsttest'}>*/}
                {/*    <ListItemButton onClick={() => setShowHelp(true)}>*/}
                {/*        /!*<ListItemIcon>{<InboxIcon/>}</ListItemIcon>*!/*/}
                {/*        <ListItemText primary={translate('board.menu.selftest')}/>*/}
                {/*        <ArrowForwardIosIcon></ArrowForwardIosIcon>*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}
                <ListItem sx={styles.menuButton} key={'Synchronisieren'}>
                    <ListItemButton>
                        {/*<ListItemIcon>{<InboxIcon />}</ListItemIcon>*/}
                        <ListItemText primary={translate('board.menu.sync_board')}/>
                        <Actions/>
                    </ListItemButton>
                </ListItem>
            </List>
        </ClickAwayListener>
    </MuiDrawer>;
};

export default Drawer;
