import React, {useState} from 'react';

import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel, FormLabel,
    LinearProgress,
    Typography
} from '@mui/material';

import {Dialog, Text} from 'components';
import {isOk} from 'fetcher/useFetcher';
import {useInterval} from 'hooks';
import {useTranslation} from 'hooks';
import {useGroupsStore} from 'module/group/zustand';
import {useSheetStore} from 'module/sheet/zustand';
import {useSnackbarStore} from 'store';
import storage from 'store/localStorage';

import {loadSyncMeta, loadSyncSheet} from './Api';

const PullDialog = ({open, handleClose}) => {
    const translate = useTranslation();

    const [synced, setSynced] = useState(0);
    const [overwrite, setOverwrite] = useState(false);
    const [data, setData] = useState({
        info: {},
        sheets: [],
        groups: [],
    });

    const showInfo = useSnackbarStore(state => state.show);

    const upsertGroup = useGroupsStore(store => store.upsert);
    const delAllGroups = useGroupsStore(state => state.delAll);
    const setSelectedGroup = useGroupsStore(state => state.setSelected);

    const addSheet = useSheetStore(store => store.add);
    const updateSheet = useSheetStore(store => store.upd);
    const getSheet = useSheetStore(store => store.get);
    const setSelectedSheet = useSheetStore(state => state.setSelected);
    const delAllSheets = useSheetStore(state => state.delAll);

    const pctSynced = data.sheets.length === 0 ? 0 : (synced / data.sheets.length * 100);

    const [clearTimer] = useInterval(async () => {
        setSynced(0);
        const data = await loadSyncMeta();

        if (typeof data === 'object' && data !== null) {
            const st = {
                info: data?.info || {},
                sheets: Array.isArray(data.sheets) ? data.sheets : [],
                groups: Array.isArray(data.groups) ? data.groups : [],
            };
            setData(st);
        }

    }, 1000);

    const doPull = async () => {
        clearTimer();
        setSynced(0);

        let numSynced = 0;

        if (overwrite) {
            delAllSheets();
            delAllGroups();
        }

        for (const group of data.groups) {
            upsertGroup(group);
        }

        setSelectedSheet(null);

        for (const sheetId of data.sheets) {
            const res = await loadSyncSheet(sheetId);

            if (isOk(res)) {
                // see #160
                if (!res.canvas) {
                    console.log(`warning sheet ${sheetId} has null canvas, skipping`);

                    continue;
                }

                if (getSheet(sheetId) === null) {
                    addSheet(res.sheet);
                } else {
                    updateSheet(res.sheet);
                }

                storage.setItem(`sheet_${sheetId}`, res.canvas);

                setSynced(++numSynced);
            } else {
                return;
            }
        }

        const sheet = data.sheets[0] || null;

        if (sheet) {
            setSelectedSheet(sheet);
            setSelectedGroup(sheet.group);
        }

        handleClose();
        showInfo(translate('board.sync.action.sync_success'));
    };

    const canPull = data.sheets.length > 0;

    return <Dialog open={open} handleClose={handleClose} title={translate('board.sync.receive_scenario')}>
        <DialogContent dividers>
            <Typography color="secondary"><Text>board.sync.from</Text>: {data.info.firstname}</Typography>
        </DialogContent>
        <DialogContent dividers>
            <Typography color="secondary"><Text>board.sync.count_groups</Text>: {data.groups.length || 0}</Typography>
            <Typography color="secondary"><Text>board.sync.count_sheets</Text>: {data.sheets.length || 0}</Typography>
        </DialogContent>
        <DialogContent>
            <LinearProgress value={pctSynced} variant={'determinate'}/>
        </DialogContent>
        <Divider/>
        <DialogContent>
            <FormControlLabel
                disableTypography
                control={<Checkbox
                    color="secondary" sx={{color: 'white.main'}} checked={overwrite}
                    onChange={e => setOverwrite(e.target.checked)}/>}
                label={<FormLabel>{translate('board.sync.action.delete_data')}</FormLabel>}/>
        </DialogContent>
        <Divider/>
        <DialogActions>
            <Button
                variant={'contained'} color={'error'} onClick={doPull}
                disabled={!canPull}><Text>board.sync.action.import</Text></Button>
        </DialogActions>
    </Dialog>;
};

export default PullDialog;
