import React from 'react';

import {styled} from '@mui/material/styles';

const PREFIX = 'IframeBanner';

const classes = {
    root: `${PREFIX}-root`
};

const Root = styled('iframe')(({theme: _theme}) => ({
    [`&.${classes.root}`]: {
        height: '100%',
        width: '100%',
        border: 'none',
        overflowY: 'hidden',
        overflowX: 'hidden',
    }
}));

const IframeBanner = ({banner}) => {
    return <Root scrolling={null} src={banner.url} className={classes.root} title={banner.title}/>;
};

export default IframeBanner;
