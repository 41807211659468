import PropTypes from 'prop-types';

import {fabric} from 'fabric';

import {limitReached} from '../canvas-helper';

const Text = ({canvas, stroke}) => {
    canvas.on('mouse:down', (o) => {
        if (limitReached(canvas)) {
            return;
        }

        // don't draw if an object is selected on the canvas
        if (canvas.getActiveObject()) {
            return;
        }

        let pointer = canvas.getPointer(o.e);

        const txt = new fabric.Textbox('', {
            custom: {
                type: 'text'
            },
            left: pointer.x,
            top: pointer.y,
            strokeWidth: 1,
            stroke,
            fill: stroke,
            fontSize: 40,
            width: canvas.width - pointer.x - 50,
            fontFamily: 'inter',
            hasBorders: true,
            hasControls: true
        });

        canvas.add(txt);
    });
};

Text.propTypes = {
    canvas: PropTypes.object.isRequired,
    stroke: PropTypes.string
};

export default Text;
