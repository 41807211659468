export const buildSuccessState = (res) => {
    const state = {
        loading: false,
        error: {},
        response: res.response,
        status: res.status,
        headers: res.headers,
        hasError: false,
    };

    if (typeof res.data === 'object') {
        state.data = res.data;
    }

    return state;
};

export const buildErrorState = (e) => {
    const state = {
        loading: false,
        error: {},
        status: 500,
        hasError: true,
    };

    if (e.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log("status", e.response.status);
    // console.log("data", e.response.data);
    // console.log("headers", e.response.headers);
    // error.message = e.response.data.message

        state.status = e.response.status;
        state.headers = e.response.headers;

        if (e.response.data) {
            // non json responses
            if (typeof e.response.data === 'object') {
                state.error = e.response.data;
            }
        }
    } else if (e.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
        console.log('request', e.request);
    } else {
    // Something happened in setting up the request that triggered an Error
        console.log('setup error', e.message);
    }

    // console.log("buildStateFromError", state);

    return state;
};

export const formatUrl = (formatString, replacements = {}) => {
    return formatString.replace(
        /{(\w+)}/g,
        (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
            Object.prototype.hasOwnProperty.call(replacements, placeholderWithoutDelimiters) ?
                replacements[placeholderWithoutDelimiters] : placeholderWithDelimiters,
    );
};
