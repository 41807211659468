import {useCallback} from 'react';

import {MONITOR_URL} from 'config';
import {useFetcher} from 'fetcher';

// from app to monitor

export const useSyncInfo = () => {
    const {loading, fetch, data, hasError} = useFetcher(MONITOR_URL + 'sync/info', {
        method: 'GET',
    });

    const load = useCallback(async () => {
        return fetch({
            params: {
                XDEBUG_SESSION_START: 'PHPSTORM'
            }
        });
    }, [fetch]);

    return {load, loading, info: data, hasError};
};

export const useSyncPushSheet = () => {
    const {loading, fetch} = useFetcher(MONITOR_URL + 'sync/sheet/', {
        method: 'POST',
    });

    const push = useCallback(async (sheet, canvas) => {
        return fetch({
            data: {
                sheet,
                canvas: canvas,
            },
            params: {
                XDEBUG_SESSION_START: 'PHPSTORM'
            }
        });
    }, [fetch]);

    return {push, loading};
};

export const useSyncPushMeta = () => {
    const {loading, fetch} = useFetcher(MONITOR_URL + 'sync/meta/', {
        method: 'POST',
    });

    const push = useCallback(async (meta) => {
        return fetch({
            data: {
                meta: {
                    ...meta
                }
            },
            params: {
                XDEBUG_SESSION_START: 'PHPSTORM'
            }
        });
    }, [fetch]);

    return {push, loading};
};

// electron sync api

export const loadSyncMeta = async () => {
    if (window?.syncApi?.meta === undefined) {
        return Promise.resolve();
    }

    return window.syncApi.meta();
};

export const loadSyncSheet = async (id) => {
    if (window?.syncApi?.sheet === undefined) {
        return Promise.resolve();
    }

    return window.syncApi.sheet(id);
};
