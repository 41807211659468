import React, {useState} from 'react';

import {Grid, Typography} from '@mui/material';

import {Text} from 'components';
import {useGroupsStore} from 'module/group/zustand';
import {Sheet, SheetListItem} from 'module/sheet';
import {useSheetStore} from 'module/sheet/zustand';
import {usePrefsStore} from 'store/prefs';

const styles = {
    listTitleSheet: {
        color: 'white.main',
        fontSize: '15px',
        margin: '0',
        padding: '20px 0 10px 0',
        display: 'flex',
    },
    formSheet: {
        margin: '0 0 20px 0',
    },
    sheetBlock: {
        width: 'auto',
        display: 'flex',
        backgroundColor: 'grey.darkest',
        padding: '0 20px',
        margin: '20px',
        borderRadius: '0',
    },
    accordionListContentContainer: {
        display: 'flex',
        width: '100%',
        padding: '0',
        margin: '0',
        borderRadius: '0',
        boxSizing: 'border-box',

    },
    accordionListContentContainerSelected: {
        display: 'flex',
        width: '100%',
        padding: '0',
        borderRadius: '0',
        boxSizing: 'border-box',
    },
};

const SheetMenu = ({groupId, onCutSheet}) => {
    const setShowDrawer = usePrefsStore((state) => state.setShowDrawer);
    const setShowMenu = usePrefsStore((state) => state.setShowMenu);

    const selected = useSheetStore((store) => store.selected);
    const setSelected = useSheetStore((store) => store.setSelected);
    const setSelectedGroup = useGroupsStore((store) => store.setSelected);

    const sheetsByGroup = useSheetStore((store) => store.getByGroup);

    const [editSheet, setEditSheet] = useState(null);

    const jumpToCanvas = (sheet) => {
        setShowDrawer(false);
        setShowMenu(false);
        setSelected(sheet.id);
        setSelectedGroup(sheet.group);
    };

    const sheets = sheetsByGroup(groupId);

    return (
        <Grid item xs={12}>

            {/* newSheet */}
            <Grid sx={styles.sheetBlock} container>
                <Grid item xs={12}>
                    <Typography sx={styles.listTitleSheet} variant="text">
                        <Text>board.sheet.action.create</Text>
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={styles.formSheet}>
                    <Sheet sheet={null} groupId={groupId} onSave={jumpToCanvas}/>
                </Grid>
            </Grid>

            {/* allSheets */}
            {sheets.slice().sort((a, b) => b.sort - a.sort).map((sheet, i) => (
                <Grid sx={styles.sheetBlock} container key={`sheetgroup-${sheet.id}`}>
                    <Grid
                        item xs={12}
                        sx={
                            selected === sheet.id
                                ? styles.accordionListContentContainerSelected
                                : styles.accordionListContentContainer
                        }>
                        <SheetListItem
                            sheet={sheet}
                            isEditing={editSheet?.id === sheet.id}
                            onEdit={setEditSheet} onCut={onCutSheet}
                            isFirst={i === 0}
                            isLast={sheets.length === i+1}
                        />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default SheetMenu;
