export const Modes = {
    APP: 'app',
    DESKTOP: 'desktop',
    MONITOR: 'monitor',
};

export const getMode = async () => {
    if (window?.tactix?.mode === undefined) {
        return Promise.resolve(Modes.APP);
    }

    const ret = window?.tactix?.mode();

    // keep bc until all monitors have desktop app version >= 1.2.0
    if (typeof ret === 'string') {
        return Promise.resolve(ret);
    }

    return ret;
};
