import {ReactComponent as AmericanFootball} from '../backgrounds/americanfootball.svg';
import {ReactComponent as Basketball} from '../backgrounds/basketball.svg';
import {ReactComponent as Handball} from '../backgrounds/handball.svg';
import {ReactComponent as Icehockey} from '../backgrounds/icehockey.svg';
import {ReactComponent as Soccer} from '../backgrounds/soccer.svg';
import {ReactComponent as SoccerBW} from '../backgrounds/soccer_bw.svg';
import {ReactComponent as SoccerLandscape} from '../backgrounds/soccer_landscape.svg';
import {ReactComponent as SoccerLandscapeBW} from '../backgrounds/soccer_landscape_bw.svg';
import {ReactComponent as SoccerQuarterBW} from '../backgrounds/soccer_quarter_bw.svg';
import {ReactComponent as SoccerSquaresBW} from '../backgrounds/soccer_squares_bw.svg';
import {ReactComponent as SoccerThird} from '../backgrounds/soccer_third.svg';
import {ReactComponent as SoccerThirdBW} from '../backgrounds/soccer_third_bw.svg';
import {ReactComponent as SoccerVerticalZones} from '../backgrounds/soccer_vertical_zones.svg';
import {ReactComponent as SoccerVerticalZonesBW} from '../backgrounds/soccer_vertical_zones_bw.svg';
import {ReactComponent as FieldWhite} from '../backgrounds/white.svg';

const BackgroundComponents = {
    AmericanFootball,
    Basketball,
    Handball,
    Icehockey,
    Soccer,
    SoccerBW,
    SoccerLandscape,
    SoccerLandscapeBW,
    SoccerQuarterBW,
    SoccerSquaresBW,
    SoccerThird,
    SoccerThirdBW,
    SoccerVerticalZones,
    SoccerVerticalZonesBW,
    FieldWhite
};

export default BackgroundComponents;
