import React from 'react';

import {Box, Grid, useMediaQuery, useTheme} from '@mui/material';

import {useInfoStore} from 'store';

const styles = {
    logoContainer: {
        width: 70,
        height: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '10px',
    },
    logo: {
        objectFit: 'contain',
        width: 50,
        height: 50,
    }
};

const Logo = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    const logo = useInfoStore((store) => store.logo);
    const logo2 = useInfoStore((store) => store.logo2);
    const useLogo = !isMobile && (logo2 ? `${logo2}?h=80&m=thumb` : logo);

    if (!useLogo) {
        return null;
    }

    return <Grid item>
        <Box style={styles.logoContainer}>
            <img src={useLogo} alt="Logo" style={styles.logo}/>
        </Box>
    </Grid>;
};

export default Logo;
