import React from 'react';
import PropTypes from 'prop-types';

import {Box, useMediaQuery, useTheme} from '@mui/material';

import {usePrefsStore} from 'store';

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        WebkitUserSelect: 'none !important',
        width: {
            mobile: 66,
            desktop: 80,
        },
        height: {
            mobile: 66,
            desktop: 80,
        },
        opacity: 0.95,
        margin: 0,
        position: 'relative',
        padding: 0,
        transition: 'all 0.25s ease-in-out',
        backgroundColor: 'grey.darkest'
    },
    disabled: {
        opacity: 0.3,
    },
};

const Button = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
    const tool = usePrefsStore((state) => state.tool);

    const iconColor = props.iconColor ? props.iconColor : 'white.main';
    const onClick = props.onClick ? props.onClick : null;
    const onSelected = props.onSelected ? props.onSelected : null;
    const iconStyle = props.iconStyle ? props.iconStyle : null;
    const SvgIcon = props.svg ? props.svg : null;
    const selectedEffect = props.selectedEffect ? props.selectedEffect : false;

    const labelStyles = {
        color: iconColor,
        cursor: 'default',
        textAlign: 'center',
        fontSize: isMobile ? 10 : 16,
        ...(props.labelStyle || {}),
    };

    let passedProps = {...props};
    delete passedProps.iconColor;
    delete passedProps.label;
    delete passedProps.labelStyle;
    // delete passedProps.selected;
    delete passedProps.onClick;
    delete passedProps.onSelected;
    delete passedProps.className;
    delete passedProps.svg;
    delete passedProps.iconStyle;
    delete passedProps.colorBg;
    delete passedProps.selectedEffect;

    return <Box
        sx={[
            styles.root,
            tool?.color === props.name && styles.selectedColor,

            ((tool === props.name || props.selected === true) && (selectedEffect !== false)) && styles.selected,

            props.disabled && styles.disabled,
            {
                backgroundColor: props.colorBg ? props.value : ''
            },
        ]}
        onClick={() => {
            onClick && onClick(props.name);
            onSelected && onSelected(props.name);
        }}
        {...passedProps}
    >
        {SvgIcon && <SvgIcon
            style={{
                fill: iconColor,
                display: 'block',
                width: isMobile ? 30 : 40,
                height: isMobile ? 30 : 40,
                ...iconStyle
            }}
        />}
        {props.label && <span style={{color: iconColor, ...labelStyles}}>{props.label}</span>}
    </Box>;
};

Button.propTypes = {
    name: PropTypes.string.isRequired,
    iconColor: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.object,
    labelStyle: PropTypes.object,
    iconStyle: PropTypes.object,
    onClick: PropTypes.func,
    selectedEffect: PropTypes.bool,
};

Button.defaultProps = {
    selected: false,
    selectedEffect: false,
};

export default Button;
