import React, {useState} from 'react';

import {
    Menu as MenuIcon,
    Timeline as TimelineIcon,
} from '@mui/icons-material';
import {
    MoreHoriz as MoreHorizIcon,
    Redo as RedoIcon,
    Save as SaveIcon,
    Undo as UndoIcon} from '@mui/icons-material';
import {Box, ClickAwayListener,Grid, Popper, Stack} from '@mui/material';

import {useAction} from 'canvas/canvas-actions';
import {useCanvasStore} from 'canvas/zustand';
import {ActionButton} from 'components';
import {useTranslation} from 'hooks';
import {useGroupsStore} from 'module/group/zustand';
import {useSheetStore} from 'module/sheet/zustand';
import {usePrefsStore, useSnackbarStore} from 'store';

import {InfobarClock, InfobarInfo, InfobarLogo, InfobarOptions} from './index';

const styles = {
    mainNavContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: {
            desktop: '50px',
            mobile: '40px'
        },
        height: {
            desktop: '50px',
            mobile: '40px'
        },
        margin: {
            desktop: '0 1px 0 10px',
            mobile: '0 1px 0 5px'
        },
        backgroundColor: 'grey.darker',
    },
    colorWhite: {
        color: 'white.main',
    },
    actionButtonContainer: {
        marginRight: {
            desktop: 0,
            mobile: '5px'
        },
    },
    actionButtonTopRightMobile: {
        display: {
            sm: 'flex',
            mobile: 'none'
        },
    },
    actionButtonTopRight: {
        backgroundColor: 'grey.darker',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginLeft: '1px',
        borderRadius: 0,
        width: {
            desktop: '50px',
            mobile: '40px'
        },
        height: {
            desktop: '50px',
            mobile: '40px'
        },
    }
};

const Infobar = () => {
    const translate = useTranslation();
    const {dispatch} = useAction();

    const [anchorEl, setAnchorEl] = useState(null);

    const setShowDrawer = usePrefsStore((state) => state.setShowDrawer);

    const selectedGroup = useGroupsStore((store) => store.selected);
    const getGroup = useGroupsStore((store) => store.get);
    const group = getGroup(selectedGroup);

    const selectedSheet = useSheetStore((store) => store.selected);
    const getSheet = useSheetStore((store) => store.get);
    const sheetsByGroup = useSheetStore((store) => store.getByGroup(selectedGroup));
    const sheet = getSheet(selectedSheet);

    const dirty = useCanvasStore((state) => state.dirty);
    const canvasStatesIndex = useCanvasStore((state) => state.canvasStatesIndex);
    const canvasStates = useCanvasStore((state) => state.canvasStates);

    const showInfo = useSnackbarStore((state) => state.show);

    const showTimeline = usePrefsStore((state) => state.showTimeline);
    const setShowTimeline = usePrefsStore((state) => state.setShowTimeline);

    const toggleTimeline = () => {
        setShowTimeline(!showTimeline);
    };

    const save = () => {
        dispatch('save', selectedSheet);
        showInfo(translate('board.sheet.action.save_success'));
    };

    const showInfoOptions = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return (
        <Grid container justifyContent={'center'} alignItems={'center'} flexWrap={'nowrap'}>
            <Grid item>
                <InfobarLogo/>
            </Grid>
            <Grid
                item
                spacing={0}
                container
                direction="row"
                flexWrap={'nowrap'}
                justifyContent={'flex-start'}
            >
                <Grid
                    spacing={0}
                    container
                    direction="row"
                    flexWrap={'nowrap'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >

                    {/* mainNavButton */}
                    <Grid item sx={styles.mainNavContainer}>
                        <ActionButton onClick={() => setShowDrawer(true)}>
                            <MenuIcon sx={styles.colorWhite} size={'lg'}/>
                        </ActionButton>
                    </Grid>

                    <Grid item>

                        {/* themaFolieHeaderBox */}
                        <InfobarInfo group={group} sheet={sheet} sheets={sheetsByGroup}/>

                        {/* themaFolieHeaderBoxArrowRight */}
                        {/* <Icons.OpenTheme
                            style={{
                                fill: dirty ? dirtyIconColor : iconColor,
                                // stroke: 'white',
                                display: 'inline-block',
                                width: isMobile ? 45 : 60,
                                height: isMobile ? 45 : 60,
                            }}
                        /> */}

                    </Grid>
                </Grid>
            </Grid>

            <Grid item>

                <Stack direction="row" alignItems="center" sx={styles.actionButtonContainer}>

                    {/* button */}
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Stack direction={'row'}>
                            <ActionButton onClick={showInfoOptions} sx={styles.actionButtonTopRight}>
                                <MoreHorizIcon sx={{color: 'white.main'}} />
                            </ActionButton>

                            <Popper sx={{zIndex: 13}} open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'bottom-end'}>
                                <InfobarOptions onComplete={() => setAnchorEl(null)}/>
                            </Popper>
                        </Stack>
                    </ClickAwayListener>

                    {/* button */}
                    <ActionButton onClick={() => dispatch('previous')} sx={[styles.actionButtonTopRight,styles.actionButtonTopRightMobile]}>
                        <UndoIcon sx={{color: canvasStatesIndex > 0 ? 'white.main' : 'grey.light'}} />
                    </ActionButton>

                    {/* button */}
                    <ActionButton onClick={() => dispatch('redo')} sx={[styles.actionButtonTopRight,styles.actionButtonTopRightMobile]}>
                        <RedoIcon sx={{color: (canvasStatesIndex + 1 < canvasStates.length) ? 'white.main' : 'grey.light'}} />
                    </ActionButton>

                    {/* button */}
                    <ActionButton onClick={toggleTimeline} sx={[styles.actionButtonTopRight, styles.actionButtonTopRightMobile]}>
                        <TimelineIcon sx={{color: showTimeline ? 'grey.light' : 'white.main'}} />
                    </ActionButton>

                    {/* button */}
                    <ActionButton onClick={save} sx={styles.actionButtonTopRight}>
                        <SaveIcon sx={{color: dirty ? 'red.dark' : 'white.main'}} />
                    </ActionButton>

                    {/* clock // board */}
                    <Box>
                        <InfobarClock/>
                    </Box>

                </Stack>

            </Grid>

        </Grid>
    );
};

export default Infobar;
