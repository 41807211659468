import React from 'react';

import {Grid} from '@mui/material';

import GroupMenu from './components/GroupMenu';
import {Drawer, Help, Infobar, Settings} from './components';

const Header = () => {
    return (
        <Grid container direction={'row'} alignItems={'space-around'}>
            <Grid item flexGrow={1}><>
                <Drawer/>
                <Help/>
                <Settings/>
                <GroupMenu/>
                <Infobar/>
            </>
            </Grid>
        </Grid>
    );
};

export default Header;
