import React from 'react';

import {Box, Grid, useMediaQuery, useTheme} from '@mui/material';

import {Canvas} from 'canvas';
import {InformationDialog, Keyboard} from 'components';
import {BannersDialog, useBannersStore} from 'module/banner';
import {SheetAnimationFrames, SheetFieldSelector, SheetSwitcher} from 'module/sheet';
import {useSheetStore} from 'module/sheet/zustand';
import {useCampaignStore} from 'store';
import {usePrefsStore} from 'store';

import {Toolbar} from './components/toolbar';
import Footer from './Footer';
import Header from './Header';

const styles = {
    root: (theme) => ({
        paddingRight: theme.breakpoints.down('md') ? 0 : 2,
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        backgroundColor: 'black.main',
    }),
};

const Layout = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const selected = useSheetStore((store) => store.selected);

    // this is workaround, do not use get from the store, because after adding a new sheet which has field set to false
    // no re-rendering will be triggered, because selected id does not change
    const sheets = useSheetStore((store) => store.sheets);
    const index = sheets.findIndex(sht => sht.id === selected);
    const sheet = index !== -1 ? sheets[index] : null;

    const bannersVisible = useBannersStore((store) => store.visible);
    const bannersHide = useBannersStore((store) => store.hide);
    const banners = useCampaignStore((state) => state.banners);

    const showDrawer = usePrefsStore((state) => state.showDrawer);
    const showMenu = usePrefsStore((state) => state.showMenu);
    const showTimeline = usePrefsStore((state) => state.showTimeline);

    return (
        <Box>
            <Keyboard/>
            <Box sx={styles.root}>
                <BannersDialog open={bannersVisible && banners.length > 0} handleClose={bannersHide}/>
                <InformationDialog/>
                <Header/>

                <Toolbar/>
                {showTimeline ? <SheetAnimationFrames/> : null}

                <Grid
                    container
                    direction="column"
                    height={'100vh'}
                    wrap={'nowrap'}
                    alignItems={'center'}
                >
                    <Grid item flexGrow={1}>
                        <Grid container direction={'row'} justifyContent={'flex-start'} wrap={'nowrap'}>
                            <Grid item></Grid>
                            <Grid item flexGrow={1}>
                                <Canvas isMobile={isMobile}/>
                                {(!showDrawer && !showMenu && !sheet?.type) ? <SheetFieldSelector sheet={sheet}/> : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isMobile && (
                        <Grid item>
                            <Footer/>
                        </Grid>
                    )}
                </Grid>
                <SheetSwitcher/>
            </Box>
        </Box>
    );
};

export default Layout;
