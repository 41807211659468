import {ReactComponent as Lineup343} from './3-4-3.svg';
import {ReactComponent as Lineup352} from './3-5-2.svg';
import {ReactComponent as Lineup4231} from './4-2-3-1.svg';
import {ReactComponent as Lineup4321} from './4-3-2-1.svg';
import {ReactComponent as Lineup433} from './4-3-3.svg';
import {ReactComponent as Lineup442} from './4-4-2.svg';
import {ReactComponent as Lineup442diamond} from './4-4-2-diamond.svg';
import {ReactComponent as Lineup451} from './4-5-1.svg';
import {ReactComponent as Lineup451libero} from './4-5-1-libero.svg';
import {ReactComponent as Lineup532} from './5-3-2.svg';
import {ReactComponent as Lineup541} from './5-4-1.svg';

// import {ReactComponent as Lineup325} from './3-2-5.svg';
// import {ReactComponent as Lineup4132} from '/4-1-3-2.svg';
// import {ReactComponent as Lineup4141} from '/4-1-4-1.svg';
// import {ReactComponent as Lineup4222} from '/4-2-2-2.svg';
// import {ReactComponent as Lineup523} from '/5-2-3.svg';

const defDefense4 = [{x: 3, y: 6}, {x: 7, y: 4}, {x: 11, y: 4}, {x: 15, y: 6}];
const defMidfield4 = [{x: 3, y: 16}, {x: 7, y: 15}, {x: 11, y: 15}, {x: 16, y: 16}];
const defAttack1 = [{x: 9, y: 26}];
const defAttack2 = [{x: 7, y: 28}, {x: 11, y: 28}];

const lineups = [
    {
        name: '3-4-3',
        Icon: Lineup343,
        positions: [
            {x: 3, y: 5}, {x: 9, y: 4}, {x: 15, y: 5},
            ...defMidfield4,
            {x: 5, y: 28}, {x: 9, y: 25}, {x: 13, y: 28},
        ]
    },
    {
        name: '3-5-2',
        Icon: Lineup352,
        positions: [
            {x: 5, y: 5}, {x: 9, y: 4}, {x: 13, y: 5},
            {x: 1, y: 16}, {x: 5, y: 15}, {x: 9, y: 13}, {x: 13, y: 15}, {x: 17, y: 16},
            ...defAttack2
        ]
    },
    {
        name: '4-2-3-1',
        Icon: Lineup4231,
        positions: [
            ...defDefense4,
            {x: 7, y: 10}, {x: 11, y: 10},
            {x: 4, y: 17}, {x: 9, y: 17}, {x: 14, y: 17},
            ...defAttack1
        ]
    },
    {
        name: '4-3-2-1',
        Icon: Lineup4321,
        positions: [
            ...defDefense4,
            {x: 4, y: 14}, {x: 9, y: 12}, {x: 14, y: 14},
            {x: 7, y: 22}, {x: 11, y: 22},
            ...defAttack1
        ]
    },
    {
        name: '4-3-3',
        Icon: Lineup433,
        positions: [
            ...defDefense4,
            {x: 4, y: 15}, {x: 9, y: 17}, {x: 14, y: 15},
            {x: 4, y: 25}, {x: 9, y: 25}, {x: 14, y: 25}
        ]
    },
    {
        name: '4-4-2',
        Icon: Lineup442,
        positions: [
            ...defDefense4,
            ...defMidfield4,
            ...defAttack2,
        ]
    },
    {
        name: '4-4-2 dia',
        Icon: Lineup442diamond,
        positions: [
            ...defDefense4,
            {x: 4, y: 17}, {x: 9, y: 12}, {x: 9, y: 22}, {x: 14, y: 17},
            ...defAttack2,
        ]
    },
    {
        name: '4-5-1',
        Icon: Lineup451,
        positions: [
            ...defDefense4,
            {x: 1, y: 15}, {x: 5, y: 14}, {x: 9, y: 20}, {x: 13, y: 14}, {x: 17, y: 15},
            ...defAttack1
        ]
    },
    {
        name: '4-5-1 libero',
        Icon: Lineup451libero,
        positions: [
            {x: 3, y: 5}, {x: 9, y: 7}, {x: 9, y: 4}, {x: 15, y: 5},
            {x: 1, y: 15}, {x: 5, y: 14}, {x: 9, y: 20}, {x: 13, y: 14}, {x: 17, y: 15},
            ...defAttack1
        ]
    },
    {
        name: '5-3-2',
        Icon: Lineup532,
        positions: [
            {x: 2, y: 5}, {x: 6, y: 9}, {x: 9, y: 8}, {x: 12, y: 9}, {x: 16, y: 5},
            {x: 5, y: 17}, {x: 9, y: 18}, {x: 13, y: 17},
            ...defAttack2,
        ]
    },
    {
        name: '5-4-1',
        Icon: Lineup541,
        positions: [
            {x: 2, y: 5}, {x: 6, y: 9}, {x: 9, y: 8}, {x: 12, y: 9}, {x: 16, y: 5},
            ...defMidfield4,
            ...defAttack1
        ]
    },
];

export default lineups;
