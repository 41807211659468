import React, {useMemo} from 'react';
import {useLayoutEffect, useRef, useState} from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import {keyframes} from '@mui/system';

import {Text} from 'components';
import {useTranslation} from 'hooks';
import {useSheetStore} from 'module/sheet/zustand';
import {usePrefsStore} from 'store';

const ticker = keyframes`
  0% {
    left: 0;
    visibility: visible;
    opacity: 0;
  }

  25% {
    left: 0;
    visibility: visible;
    opacity: 1;
  }

  100% {
    left: -100%;
  }
`;
const styles = {
    textContainer: {
        display: 'flex',
        alignItems: 'center',
        height: {
            desktop: '70px',
            mobile: '50px'
        },
    },
    textWrapper: {
        backgroundColor: 'grey.darkest',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0',
        width: 'auto',
        maxWidth: {
            desktop: '300px',
            mobile: '100px'
        },
        height: {
            desktop: '50px',
            mobile: '40px'
        },
        overflow: 'hidden',
        position: 'relative',
    },
    textTitleWrapper: {
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    headerTitleContainer: {
        zIndex: 88,
    },
    headerTitle: {
        fontSize: 12,
        textAlign: 'left',
        padding: '0 10px',
        color: 'white.darkest',
        whiteSpace: 'nowrap',
        backgroundColor: 'grey.darkest',
        display: 'block',
    },
    headerText: {
        fontSize: 12,
        textAlign: 'left',
        color: 'white.main',
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap',
        padding: '0',
        boxSizing: 'border-box',
        fontWeight: '500',
        position: 'relative',
    },
    headerTextSlide: {
        animation: `${ticker} 18s infinite linear`,
    },
    textArrowWrapper: {
        paddingLeft: '10px',
        display: 'flex',
        backgroundColor: 'grey.darkest',
    },
    textArrowContainer: {
        padding: '0 5px',
        backgroundColor: 'grey.darker',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: {
            desktop: '50px',
            mobile: '40px'
        },
        height: {
            desktop: '50px',
            mobile: '40px'
        },
        borderRadius: 0,
        marginLeft: '1px',
    },
    textArrowContainerOw1: {
        padding: '0 1px 0 8px',
    },
    textArrow: {
        width: '14px',
        height: '14px',
        color: 'white.main',
    },
    disabled: {
        opacity: 0.4
    }
};

/* themaFolieHeaderBoxInline */
const Info = ({group, sheet, sheets}) => {
    const translate = useTranslation();

    const setShowMenu = usePrefsStore((state) => state.setShowMenu);

    const setShowTimeline = usePrefsStore((state) => state.setShowTimeline);
    const setSelected = useSheetStore((store) => store.setSelected);

    const refTheme = useRef(null);
    const [widthTheme, setWidthTheme] = useState(0);

    const refSlide = useRef(null);
    const [widthSlide, setWidthSlide] = useState(0);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useLayoutEffect(() => {
        setWidthTheme(refTheme.current.offsetWidth);
        setWidthSlide(refSlide.current.offsetWidth);
    }, []);

    const doSheetSelect = (sheet) => {
        if (!sheet) return;
        setShowTimeline(sheet?.frames?.length > 0);
        setSelected(sheet.id);
    };

    const nextPrevSheet = useMemo(() => {
        const sortedSheet = sheets.slice().sort((a, b) => b.sort - a.sort);
        const sheetIndex = sortedSheet.findIndex(item => item.id === sheet?.id);
        return {
            previousSheet: sortedSheet[sheetIndex - 1] !== undefined ? sortedSheet[sheetIndex - 1] : undefined,
            nextSheet: sortedSheet[sheetIndex + 1] !== undefined ? sortedSheet[sheetIndex + 1] : undefined,
        };
    }, [sheet, sheets]);

    return <Grid sx={styles.textContainer}>

        <Grid
            sx={styles.textWrapper}
            onClick={() => {
                setShowMenu(true);
            }}
        >
            <Grid sx={styles.textTitleWrapper} container>
                <Grid item sx={styles.headerTitleContainer}>
                    <Typography sx={styles.headerTitle}>
                        <Text>board.group.group</Text>:{' '}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        sx={[styles.headerText, ((isMobile && widthTheme > 39) && styles.headerTextSlide), ((!isMobile && widthTheme > 239) && styles.headerTextSlide)]}
                        ref={refTheme}>
                        {group ? group.name : translate('board.group.no_group')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid sx={styles.textTitleWrapper} container>
                <Grid item sx={styles.headerTitleContainer}>
                    <Typography sx={styles.headerTitle}>
                        <Text>board.sheet.sheet</Text>:{' '}
                    </Typography>
                </Grid>
                <Grid>
                    <Typography
                        sx={[styles.headerText, ((isMobile && widthSlide > 51) && styles.headerTextSlide), ((!isMobile && widthSlide > 251) && styles.headerTextSlide)]}
                        ref={refSlide}>
                        {sheet ? sheet.name : translate('board.sheet.no_sheet')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>

        <Grid sx={styles.textArrowWrapper}>
            <Grid item sx={[styles.textArrowContainer, styles.textArrowContainerOw1]} onClick={() => doSheetSelect(nextPrevSheet.previousSheet)}>
                <ArrowBackIosIcon
                    sx={[styles.textArrow, !nextPrevSheet.previousSheet && styles.disabled]}></ArrowBackIosIcon>
            </Grid>
            <Grid item sx={styles.textArrowContainer} onClick={() => doSheetSelect(nextPrevSheet.nextSheet)}>
                <ArrowForwardIosIcon
                    sx={[styles.textArrow, !nextPrevSheet.nextSheet && styles.disabled]}></ArrowForwardIosIcon>
            </Grid>
        </Grid>
    </Grid>;
};

export default Info;
