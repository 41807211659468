import {create} from 'zustand';

import {fabric} from 'fabric';
import storage from 'store/localStorage';
import {middleware} from 'store/zustand';

import {canvasReviver, canvasToJson, setBackground} from './canvas-helper';

export const useCanvasStore = create(middleware((set, get) => ({
    canvas: null,
    dirty: false,
    canvasStates: [],
    canvasStatesIndex: -1, 
    clearCanvasStates: () => 
        set(() => (
            {canvasStates: [], canvasStatesIndex: -1}
        )),
    resetCanvasStates: (canvasState) => 
        set(() => ({
            dirty: false,
            canvasStates: [canvasState],
            canvasStatesIndex: 0
        })),
    getCanvasStates: () => get().canvasStates,
    pushCanvas: (newCanvasJson) => {
        let canvasStates = get().canvasStates;
        let canvasStatesIndex = get().canvasStatesIndex;
    
        // need stringify check to prevent double pushing on erratic events like onAdd() firing twice with no reason for same action
        if (canvasStates.length === 0 || JSON.stringify(newCanvasJson) !== JSON.stringify(canvasStates.slice(-1)[0])) {
            // remove states higher than canvasStatesIndex (avoid alternate realities after undo)
            let removeHigherStates = (canvasStatesIndex + 1 < canvasStates.length);
    
            if (removeHigherStates) {
                set((state) => ({
                    canvasStates: [
                        ...state.canvasStates.slice(0, (canvasStatesIndex + 1) - canvasStates.length),
                        newCanvasJson
                    ],
                    canvasStatesIndex: canvasStatesIndex + 1
                }));
            }
            else
            {
                set((state) => ({
                    canvasStates: [
                        ...state.canvasStates,
                        newCanvasJson
                    ],
                    canvasStatesIndex: canvasStatesIndex + 1
                }));
            }
        }
    },
    nextCanvasState: ({isMobile}) => {
        let index = get().canvasStatesIndex + 1;

        if (index >= get().canvasStates.length) {
            return;
        }

        set(() => ({
            canvasStatesIndex: index
        }));

        let nextCanvas = get().canvasStates[index];
        const canvas = get().canvas;

        canvas.isLoading = true;

        // background different in next canvas -> load all objects and background
        if (nextCanvas && !nextCanvas.backgroundImage || (nextCanvas?.backgroundImage?.src && nextCanvas.backgroundImage.src !== canvasToJson(canvas)?.backgroundImage?.src)) {
            canvas.loadFromJSON(nextCanvas, () => {
                canvas.renderAll.bind(canvas);
                canvas.isLoading = false;
                set(() => ({
                    dirty: true,
                }));
            }, (json, element) => {
                canvasReviver(json, element, isMobile);
            });
        }
        // only load objects
        else {
            canvas.remove(...canvas.getObjects());
            fabric.util.enlivenObjects(nextCanvas.objects, (objs) => {
                objs.forEach((item) => {
                    canvas.add(item);
                });
                canvas.requestRenderAll(); // Make sure to call once we're ready!
                canvas.isLoading = false;
                set(() => ({
                    dirty: true,
                }));
            },'', (json, element) => {
                canvasReviver(json, element, isMobile);
            });
        }
    },
    previousCanvasState: ({isMobile}) => {
        let index = get().canvasStatesIndex - 1;

        if (index < 0) {
            return;
        }

        set(() => ({
            canvasStatesIndex: index
        }));

        let previousCanvas = get().canvasStates[index];
        const canvas = get().canvas;

        canvas.isLoading = true;

        // background different in previous canvas -> load all objects and background
        if (previousCanvas && !previousCanvas.backgroundImage || (previousCanvas?.backgroundImage?.src && previousCanvas.backgroundImage.src !== canvasToJson(canvas)?.backgroundImage?.src)) {
            canvas.loadFromJSON(previousCanvas, () => {
                canvas.renderAll.bind(canvas);
                canvas.isLoading = false;
                set(() => ({
                    dirty: true,
                }));
            }, (json, element) => {
                canvasReviver(json, element, isMobile);
            });
        }
        // only load objects
        else {
            canvas.remove(...canvas.getObjects());
            fabric.util.enlivenObjects(previousCanvas.objects, (objs) => {
                objs.forEach((item) => {
                    canvas.add(item);
                });
                canvas.requestRenderAll(); // Make sure to call once we're ready!
                canvas.isLoading = false;
                set(() => ({
                    dirty: true,
                }));
            },'', (json, element) => {
                canvasReviver(json, element, isMobile);
            });
        }
    },
    set: canvas => set({canvas}),
    save: (id, custom, prefix = 'sheet') => {
        const canvas = get().canvas;

        if (!canvas) {
            return;
        }

        const canvasJson = canvasToJson(canvas);
        delete (canvasJson['backgroundImage']); // do not save backgroundImage
        const data = {
            ...custom,
            width: canvas.width,
            height: canvas.height,
            canvas: canvasJson
        };
        storage.setItem(`${prefix}_${id}`, JSON.stringify(data));
        set(() => ({
            dirty: false,
        }));
    },
    load: (id, {prefix = 'sheet', isMobile = false}) => {
        try {
            const str = storage.getItem(`${prefix}_${id}`);

            if (!str) {
                return null;
            }

            const data = JSON.parse(str);

            if (typeof data !== 'object') {
                return null;
            }

            const canvas = get().canvas;

            if (!canvas) {
                return null;
            }

            if (data) {
                canvas.isLoading = true;
                canvas.remove(...canvas.getObjects());
                fabric.util.enlivenObjects(data.canvas.objects, (objs) => {
                    objs.forEach((item) => {
                        canvas.add(item);
                    });
                    setBackground(canvas, data.background, true);
                    canvas.requestRenderAll(); // Make sure to call once we're ready!
                    canvas.isLoading = false;
                },'', (json, element) => {
                    canvasReviver(json, element, isMobile);
                });
            }

            return {canvas, background: data.background};
        }
        catch(e) {
            console.log(`Failed to load sheet ${prefix}_${id}`);
            return null;
        }
    },
    setDirty: dirty => set({dirty})
})));
