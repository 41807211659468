import React, {useEffect, useState} from 'react';

import {
    Check as CheckIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    List,
    SwipeableDrawer as MuiDrawer,
    Typography
} from '@mui/material';

import {useAction} from 'canvas/canvas-actions';
import {MenuHeader} from 'components';
import {ConfirmDialog, Text, useConfirmDialog} from 'components';
import {useTranslation} from 'hooks';
import {hasSelftest, runSelftest, SelftestDialog} from 'module/selftest';
import {useInfoStore, usePrefsStore} from 'store';

const styles = {
    drawer: {
        width: '100%',
        backgroundColor: 'black.main',

    },
    overlay: {
        padding: '0 20px 20px 20px',
        width: '100%',
        zIndex: '999',
        animationName: 'slideToLeft',
        animationDuration: '0.1s',
        animationFillMode: 'forwards',
    },
    container: {
        width: '100%',
    },
    card: {
        width: '100%',
        padding: '0',
        borderLeft: '1px solid',
        borderRight: '1px solid',
        borderTop: '1px solid',
        borderLeftColor: 'grey.lighter',
        borderRightColor: 'grey.lighter',
        borderTopColor: 'grey.lighter',
        borderRadius: 0,
    },
    cardLast: {
        borderBottom: '1px solid',
        borderBottomColor: 'grey.lighter',
    },
    title: {
        width: '100%',
        padding: '16px 20px 0 20px',
    },
    content: {
        padding: '16px 20px',
    },
};

const InformationDialog = () => {
    const translate = useTranslation();

    const [enableSelftest, setEnableSelftest] = useState(false);
    const [selftestResults, setSelftestResults] = useState(false);
    const setShowInformationDialog = usePrefsStore(state => state.setShowInformationDialog);
    const showInformationDialog = usePrefsStore(state => state.showInformationDialog);
    const setShowTimeline = usePrefsStore((state) => state.setShowTimeline);
    const setShowDrawer = usePrefsStore((state) => state.setShowDrawer);
    const setShowHelp = usePrefsStore((state) => state.setShowHelp);

    const deviceId = useInfoStore(state => state.deviceId);
    const title = useInfoStore(state => state.title);

    const {dispatch: onAction} = useAction();

    const execSelftest = async () => {
        setSelftestResults(await runSelftest());
    };

    useEffect(() => {
        const checkTest = async () => {
            setEnableSelftest(await hasSelftest());
        };

        checkTest();

    }, []);

    const closeDialog = () => {
        setShowDrawer(true);
        setShowInformationDialog(false);
    };

    const closeAll = () => {
        setShowDrawer(false);
        setShowInformationDialog(false);
    };

    const deleteConfirm = useConfirmDialog(() => {
        setShowTimeline(false);
        onAction('deleteAll');
        setShowInformationDialog(false);
    }, undefined, translate('board.menu.action.confirm_delete_all'));

    return <MuiDrawer
        disableSwipeToOpen
        PaperProps={{sx: styles.drawer}}
        anchor={'left'}
        open={showInformationDialog}
        onOpen={() => null}
        onClose={closeAll}
    >
        <List sx={styles.overlay}>
            <ConfirmDialog {...deleteConfirm}/>
            <SelftestDialog handleClose={() => setSelftestResults(false)} results={selftestResults}/>

            <MenuHeader text={<Text>board.menu.infos</Text>} handleBackButton={closeDialog} handleForwardButton={closeAll} />

            <Box sx={styles.container}>
                <Card sx={styles.card}>
                    <CardHeader
                        sx={styles.title} title={translate('board.menu.contact_club_order')}
                        titleTypographyProps={{variant: 'h2'}}/>
                    <CardContent sx={styles.content}>
                        <Typography>vereine@geomix.at</Typography>
                        {/*<Typography>Telefonnummer</Typography>*/}
                        <Typography>www.geomix.de/teamsport</Typography>
                    </CardContent>
                </Card>

                <Card sx={styles.card}>
                    <CardHeader
                        sx={styles.title} title={translate('board.menu.contact_tactix')}
                        titleTypographyProps={{variant: 'h2'}}/>
                    <CardContent sx={styles.content}>
                        <Typography>ts@geomix.at</Typography>
                        {/*<Typography>Telefonnummer</Typography>*/}
                        <Typography>www.geomix.at/tactix</Typography>
                    </CardContent>
                </Card>

                {deviceId && <Card sx={styles.card}>
                    <CardHeader
                        sx={styles.title}
                        title={translate('board.menu.tactix')}
                        titleTypographyProps={{variant: 'h2'}}/>
                    <CardContent sx={styles.content}>
                        <Typography>{deviceId}</Typography>
                        <Typography>{title}</Typography>
                    </CardContent>
                </Card>}

                {enableSelftest && <Card sx={styles.card}>
                    <CardHeader
                        sx={styles.title} title={translate('board.menu.selftest')}
                        titleTypographyProps={{variant: 'h2'}}/>
                    <CardActions sx={styles.content}>
                        <Button
                            color={'black'}
                            data-testid="dialog-execute-btn"
                            variant={'contained'} onClick={execSelftest}
                            startIcon={<CheckIcon/>}>ausführen</Button>
                        <Button
                            color={'black'}
                            data-testid="dialog-help-btn"
                            variant={'contained'} onClick={() => setShowHelp(true)}
                            startIcon={<CheckIcon/>}><Text>board.menu.tutorials</Text></Button>
                    </CardActions>
                </Card>}

                <Card sx={[styles.card, styles.cardLast]}>
                    <CardHeader
                        sx={styles.title} title={translate('board.menu.system')}
                        titleTypographyProps={{variant: 'h2'}}/>
                    <CardActions sx={styles.content}>
                        <Button
                            data-testid="action-deleteAll-btn"
                            color={'error'} variant={'contained'} onClick={deleteConfirm.show}
                            startIcon={<DeleteIcon/>}><Text>ui.actions.delete_all</Text></Button>
                    </CardActions>
                </Card>
            </Box>
        </List>
    </MuiDrawer>;
};

export default InformationDialog;
