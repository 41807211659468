import React from 'react';

import {Stack} from '@mui/material';
import Radio from '@mui/material/Radio';

import {usePrefsStore} from 'store';

import {useActivateTeam} from '../useSettings';
import {useSettingsStore} from '../zustand';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '70px',
        marginBottom: '1px',
        color: 'white.main',
        fontSize: '16px',
        cursor: 'pointer',
        padding: '8px 0 8px 20px',
        backgroundColor: 'grey.darkest',
    },
    radioButtonContainer: {
        width: '70px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioButton: {
        color: 'white.main',
        '&.Mui-checked': {
            color: 'green.main',
        },
    }
};

const ListItem = ({team, onSelect, selected}) => {
    const selectedTeam = useSettingsStore((store) => store.selectedTeam);
    const setSelectedTeam = useSettingsStore((store) => store.setSelectedTeam);
    const activateTeam = useActivateTeam();
    const setShowSettings = usePrefsStore((state) => state.setShowSettings);

    const doSelect = (selectOnly) => {
        if(selectedTeam === team._id) {
            setSelectedTeam(null);
            return;
        }

        activateTeam(team);

        if (!selectOnly) {
            onSelect(team);
        }

        if(selectOnly) {
            setShowSettings(false);
        }
    };

    return <Stack sx={styles.root} onClick={() => doSelect(false)}>
        <Stack>{team.name}</Stack>
        <Stack
            sx={styles.radioButtonContainer}
            onClick={(e) => {
                e.stopPropagation();
                doSelect(true);
            }}
        >
            <Radio
                checked={selected}
                value={team._id}
                name="teams"
                sx={styles.radioButton}
            />
        </Stack>
    </Stack>;
};

export default ListItem;
