import React, {useEffect, useState} from 'react';

import {ExpandMore as ExpandMoreIcon} from '@mui/icons-material';
import {Grid, LinearProgress, Stack} from '@mui/material';

import {accordionStyles, CustomAccordion, CustomAccordionDetails, CustomAccordionSummary, NoData} from 'components';
import {useLoadPlayers, useLoadTeams} from 'module/settings/useSettings';
import {useApplyChangesToToolbar} from 'module/settings/utils';
import {useSettingsStore} from 'module/settings/zustand';

import Teams from './Teams';

const styles = {
    progressStack: {
        backgroundColor: 'green.main',
    },
};

const Clients = ({loadingClients}) => {
    const [expanded, setExpanded] = useState(false);
    const clients = useSettingsStore((store) => store.clients);

    const setSelectedTeam = useSettingsStore((store) => store.setSelectedTeam);
    const selectedTeam = useSettingsStore((store) => store.selectedTeam);
    const selectedClient = useSettingsStore((store) => store.selectedClient);
    const quicklink = useSettingsStore((store) => store.quicklink);
    const setQuicklink = useSettingsStore((store) => store.setQuicklink);

    const applyChangesToToolbar = useApplyChangesToToolbar();

    const {loadTeams, loadingTeams} = useLoadTeams();
    const loadPlayers = useLoadPlayers();

    useEffect(() => {
        if (selectedTeam && quicklink) {
            setExpanded(selectedClient);
        }
       
    }, [quicklink, selectedClient, selectedTeam, setQuicklink]);

    const handleChange = async (clientId, isExpanded) => {
        setExpanded(isExpanded ? clientId : false);

        const teams = await loadTeams(clientId);

        if (teams.length === 0) {
            setSelectedTeam(null);
            return;
        }

        let useTeam = selectedTeam;
        if (!useTeam) useTeam = teams[0]._id;

        const team = teams.find(c => c._id === useTeam);

        if (!team) {
            setSelectedTeam(null);
            return;
        }

        setSelectedTeam(useTeam);
        applyChangesToToolbar(team);

        await loadPlayers(useTeam);
    };

    return <>
        {loadingClients && <Stack>
            <LinearProgress sx={styles.progressStack} />
        </Stack>}
        {clients && !loadingClients && clients.map(client => (
            <Grid key={client._id} item xs={12}>
                <CustomAccordion
                    TransitionProps={{unmountOnExit: true}}
                    disableGutters
                    expanded={expanded === client._id}
                    onChange={(_, isExpanded) => handleChange(client._id, isExpanded)}
                >
                    <CustomAccordionSummary
                        expandIcon={<ExpandMoreIcon sx={accordionStyles.accordionExpandButton}/>}
                    >
                        <Stack>
                            <Stack>{client.title}</Stack>
                        </Stack>
                    </CustomAccordionSummary>

                    <CustomAccordionDetails>
                        <Teams loadingTeams={loadingTeams}/>
                    </CustomAccordionDetails>

                </CustomAccordion>
            </Grid>
        ))}
        {clients.length === 0 && !loadingClients && <NoData text="Keine Vereine vorhanden!"/>}
    </>;
};

export default Clients;
