import React, {useState} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Drawer as MuiDrawer, Grid, List, Stack} from '@mui/material';

import {accordionStyles, CustomAccordion, CustomAccordionDetails, CustomAccordionSummary, MenuHeader, Text} from 'components';
import {useTranslation} from 'hooks';
import {useLoadClients} from 'module/settings/useSettings';
import {useSettingsStore} from 'module/settings/zustand';
import {usePrefsStore} from 'store';

import Clients from './Clients';
import Language from './Language';

const styles = {
    drawer: {
        width: '100%',
        backgroundColor: 'black.main',
    },
    overlay: {
        padding: '0 20px 20px 20px',
        width: '100%',
        zIndex: '999',
        animationName: 'slideToLeft',
        animationDuration: '0.1s',
        animationFillMode: 'forwards',
    },
};

const Settings = () => {
    const translate = useTranslation();

    const showSettings = usePrefsStore((state) => state.showSettings);
    const setShowSettings = usePrefsStore((state) => state.setShowSettings);
    const setShowDrawer = usePrefsStore((state) => state.setShowDrawer);

    const [expanded, setExpanded] = useState(false);

    const setSelectedClient = useSettingsStore((store) => store.setSelectedClient);
    const setSelectedTeam = useSettingsStore((store) => store.setSelectedTeam);
    const selectedClient = useSettingsStore((store) => store.selectedClient);

    const {loadClients, loadingClients} = useLoadClients();

    // useEffect(() => {
    //     if (selectedClient) {
    //         setExpanded(true);
    //     }
       
    // }, [selectedClient]);

    const handleChange = async (panel, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        const clients = await loadClients();

        if (clients.length === 0) {
            setSelectedClient(null);
            setSelectedTeam(null);
            return;
        }

        let useClient = selectedClient;
        if (!useClient) useClient = clients[0]._id;

        const client = clients.find(c => c._id === useClient);

        if (!client) {
            setSelectedClient(null);
            setSelectedTeam(null);
            return;
        }

        setSelectedClient(useClient);
    };

    function handleBackButton() {
        setShowSettings(false);
        setShowDrawer(true);
    }

    function handleForwardButton() {
        setShowSettings(false);
        setShowDrawer(false);
    }

    return <MuiDrawer
        PaperProps={{sx: styles.drawer}}
        anchor={'left'}
        open={showSettings}
        onClose={() => setShowSettings(false)}
        id="mainDrawer"
    >
        <List sx={styles.overlay}>

            {/* Menü-Header */}
            <MenuHeader text={translate('global.settings')} handleBackButton={handleBackButton} handleForwardButton={handleForwardButton} />

            {/* Meine Vereine */}
            {!!window.dataApi && <Grid key="myClubs" item xs={12}>
                <CustomAccordion
                    onChange={(_, isExpanded) => handleChange('myClubs', isExpanded)}
                    expandedStyle={expanded === 'myClubs'}
                    expanded={expanded === 'myClubs'}
                >
                    <CustomAccordionSummary
                        expandIcon={<ExpandMoreIcon sx={accordionStyles.accordionExpandButton} />}
                    >
                        <Stack>Meine Vereine</Stack>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <Clients loadingClients={loadingClients}/>
                    </CustomAccordionDetails>

                </CustomAccordion>
            </Grid>}

            {/* Sprache */}
            <Grid key="language" item xs={12}>
                <CustomAccordion
                    expandedStyle={expanded === 'language'}
                    expanded={expanded === 'language'}
                    onChange={(_, isExpanded) => handleChange('language', isExpanded)}
                >
                    <CustomAccordionSummary
                        expandIcon={<ExpandMoreIcon sx={accordionStyles.accordionExpandButton} />}
                    >
                        <Stack><Text>board.menu.language.language</Text></Stack>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <Language />
                    </CustomAccordionDetails>
                </CustomAccordion>
            </Grid>

        </List>
    </MuiDrawer>;
};

export default Settings;
