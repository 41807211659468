import React from 'react';

import {Grid, Typography} from '@mui/material';

import {useCampaignStore} from 'store';

const styles = {
    title: {
        color: 'white.main',
        fontSize: 44,
        fontFamily: 'inter',
        letterSpacing: 8,
        '@media (max-width: 800px)': {
            fontSize: 14
        }
    }
};

const Footer = () => {
    const line = useCampaignStore(state => state.header?.line1 || '');

    return <Grid container direction="row" justifyContent={'space-evenly'} sx={{minHeight: 80}}>
        <Grid item>
            <Typography sx={styles.title}>{line}</Typography>
        </Grid>
    </Grid>;
};

export default Footer;
