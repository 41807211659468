import React from 'react';

import {
    Close as CloseIcon,
} from '@mui/icons-material';
import {Backdrop, Box,Button} from '@mui/material';

import {useCampaignStore} from 'store';

import Banner from './Banner';
import Banners from './Banners';

const styles = {
    backdrop: {
        zIndex: theme => theme.zIndex.drawer + 1
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        minWidth: 0,
        pX: 1,
        backgroundColor: 'white.main',

        '&:hover': {
            backgroundColor: 'white.main',
        }
    },
    closeButtonBg: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '60px',
        width: '80px',
        zIndex: theme => theme.zIndex.drawer + 2,
        cursor: 'pointer',
    }
};

const BannersDialog = ({open, handleClose}) => {
    const banners = useCampaignStore(state => state.banners);

    const full = banners.find(b => b.position === 'full');

    return <Backdrop open={open} sx={styles.backdrop}>
        <Box sx={styles.closeButtonBg} onClick={handleClose}>
            <Button variant="contained" sx={styles.closeButton} size={'small'}>
                <CloseIcon size="2x"/>
            </Button>
        </Box>
        {full === undefined && <Banners banners={banners}/>}
        {full !== undefined && <Banner banner={full}/>}
    </Backdrop>;
};

export default BannersDialog;
