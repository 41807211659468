import React from 'react';

import ArticleBanner from './Article';
import IframeBanner from './Iframe';
import ImageBanner from './Image';

const Banner = ({banner}) => {

    return <>
        {banner.type === 'article' && <ArticleBanner banner={banner}/>}
        {(banner.type === 'image' || banner.type === 'image_upload') && <ImageBanner banner={banner}/>}
        {banner.type === 'html' && <IframeBanner banner={banner}/>}
    </>;
};

export default Banner;
