import React from 'react';

import {Paper, useMediaQuery, useTheme} from '@mui/material';

import {useAction} from 'canvas/canvas-actions';
import {useTranslation} from 'hooks';
import {useSheetStore} from 'module/sheet/zustand';
import {usePrefsStore, useSnackbarStore} from 'store';

import {useCanvasStore} from '../../../canvas/zustand';

import InfobarIcon from './Icon';

const styles = {
    infoContainer: {
        padding: '10px 15px 5px 15px',
        backgroundColor: 'black.main',
        borderRadius: 0,
    }
};

const Options = ({onComplete}) => {
    const translate = useTranslation();

    const {dispatch} = useAction();

    const setShowTimeline = usePrefsStore((state) => state.setShowTimeline);

    const setSelectedSheet = useSheetStore((store) => store.setSelected);
    const frameIndex = useSheetStore(state => state.frameIndex);
    const selectedSheet = useSheetStore(state => state.selected);
    const getSheet = useSheetStore((state) => state.get);
    const clearFrames = useSheetStore(state => state.clearFrames);

    const showInfo = useSnackbarStore((state) => state.show);

    const sheet = getSheet(selectedSheet);

    console.log(sheet);

    const copy = () => {
        const newSheet = dispatch('copy');

        if (newSheet) {
            setSelectedSheet(newSheet.id);
            showInfo(translate('board.sheet.sheet') + ' ' + newSheet.name + ' ' + translate('global.created'));
            onComplete();
        }
    };

    const onAction = (action) => {
        dispatch(action);
        onComplete();
    };

    const toggleTimeline = (disable) => {
        if (disable) {
            clearFrames(selectedSheet);
        }

        setShowTimeline(!disable);
        onComplete();
    };

    const undoFunction = () => {
        dispatch('previous');
        onComplete();
    };

    const redoFunction = () => {
        dispatch('redo');
        onComplete();
    };

    const canvasStatesIndex = useCanvasStore((state) => state.canvasStatesIndex);
    const canvasStates = useCanvasStore((state) => state.canvasStates);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    return <Paper sx={styles.infoContainer}>
        {isMobile && canvasStatesIndex > 0 ? <InfobarIcon
            text="Schritt zurück" icon={'Undo'}
            onClick={undoFunction}
        /> : null}
        {isMobile && (canvasStatesIndex + 1 < canvasStates.length) ? <InfobarIcon
            text="Schritt vorwärts" icon={'Redo'}
            onClick={redoFunction}
        /> : null}
        <InfobarIcon
            text={translate('board.sheet.copy')} icon={'FileCopy'}
            onClick={copy}/>
        <InfobarIcon
            disabled={frameIndex > 0}
            text={translate('board.sheet.clear')} icon={'ClearField'}
            onClick={() => onAction('clear')}
        />
        <InfobarIcon
            disabled={frameIndex > 0}
            text={translate('board.sheet.reset')} icon={'FirstPage'}
            onClick={() => onAction('undo')}/>
        <InfobarIcon
            disabled={frameIndex > 0}
            text={translate('board.sheet.select_background')} icon={'Image'}
            onClick={() => onAction('background')}/>
        <InfobarIcon
            text={sheet?.frames?.length > 0 ? translate('board.sheet.delete_moves') : translate('board.sheet.activate_moves')}
            icon={'Timeline'}
            onClick={() => toggleTimeline(sheet?.frames?.length > 0)}/>
    </Paper>;
};

export default Options;
