import React from 'react';

import {Close as CloseIcon} from '@mui/icons-material';
import {Dialog, DialogTitle, Grid, IconButton, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

const PREFIX = 'MuiDialog';

const classes = {
    title: `${PREFIX}-title`,
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(({_theme}) => ({
    [`& .${classes.paper}`]: {
        position: 'fixed',
        top: 40,
    },
    [`& .${classes.title}`]: {
        padding: '10px 16px 5px 16px',
    },
}));

const BaseDialog = ({open, handleClose, title, children, Icon, ...rest}) => {

    return <StyledDialog open={open} onClose={handleClose} fullWidth={true} {...rest}>
        <DialogTitle className={classes.title}>
            <Grid container direction="row" justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                    <Typography variant={'h6'} fontWeight={'400'} color="white.main" fontSize={16}>{title}</Typography>
                </Grid>
                <Grid item>
                    <IconButton data-testid="dialog-close-btn" className={classes.closeButton} onClick={handleClose} color="secondary">
                        {Icon || <CloseIcon />}
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        {children}
    </StyledDialog>;
};

export default BaseDialog;
