import {useEffect,useState} from 'react';

const hasWindow = typeof window !== 'undefined';

const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {width, height};
};

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function debounce(fn, ms) {
        let timer;

        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                fn.apply(this, arguments);
            }, ms);
        };
    }

    useEffect(() => {
        if (hasWindow) {
            const debouncedHandleResize = debounce(function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }, 50);

            window.addEventListener('resize', debouncedHandleResize);
            return () => window.removeEventListener('resize', debouncedHandleResize);
        }
    }, []);

    return windowDimensions;
};

export default useWindowDimensions;
